import jwt_decode, { JwtPayload } from 'jwt-decode';

export const USER_TOKEN = 'userToken';
export const EMPTY_TOKEN = '';

export const validateToken = (token: string | null): string => {
  if (!token) {
    return EMPTY_TOKEN;
  }

  try {
    const tokenData: JwtPayload = jwt_decode(token);
    const currentTimestamp = new Date().getTime() / 1000;

    if (!tokenData.exp || tokenData.exp < currentTimestamp) {
      localStorage.removeItem(USER_TOKEN);
      return EMPTY_TOKEN;
    }

    return token;
  } catch (e) {
    console.log(e);
    return EMPTY_TOKEN;
  }
};

export const getToken = (): string => {
  const tokenFromStorage = localStorage.getItem(USER_TOKEN);
  return validateToken(tokenFromStorage);
};
