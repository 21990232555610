import React from 'react';
import { Container, Grid } from '@mui/material';
import { Tile } from '../components/overview/Tile';
import {
  AffiliatePartnersService,
  BudgetsService,
  CommunicationChannelsService,
  LeadConstraintsService,
} from '../_generatedApi';
import GroupIcon from '@mui/icons-material/Group';
import PaidIcon from '@mui/icons-material/Paid';
import {
  AFFILIATE_PARTNERS_ROUTE,
  BUDGETS_ROUTE,
  COMMUNICATION_CHANNELS_ROUTE,
  LEAD_CONSTRAINTS_ROUTE,
} from '../router/routes';
import { SettingsSuggest } from '@mui/icons-material';
import SmsIcon from '@mui/icons-material/Sms';

const Overview = () => {
  return (
    <Container maxWidth="lg" sx={{ mt: 4, mb: 4 }}>
      <Grid container spacing={3}>
        <Tile
          title={'routes.affiliatePartnersRoute'}
          link={AFFILIATE_PARTNERS_ROUTE}
          hasActiveFlag={true}
          getRecordsFunction={AffiliatePartnersService.findAll}
          icon={<GroupIcon sx={{ color: 'primary.main', fontSize: '30px' }} />}
          dataCy="affiliatePartners"
        />
        <Tile
          title={'routes.budgetsRoute'}
          link={BUDGETS_ROUTE}
          hasActiveFlag={false}
          getRecordsFunction={BudgetsService.findAll}
          icon={<PaidIcon sx={{ color: 'primary.main', fontSize: '30px' }} />}
          dataCy="budgets"
        />
        <Tile
          title={'routes.communicationChannelsRoute'}
          link={COMMUNICATION_CHANNELS_ROUTE}
          hasActiveFlag={true}
          getRecordsFunction={CommunicationChannelsService.findAll}
          icon={<SmsIcon sx={{ color: 'primary.main', fontSize: '30px' }} />}
          dataCy="communicationChannels"
        />
        <Tile
          title={'routes.leadStrategiesRoute'}
          link={LEAD_CONSTRAINTS_ROUTE}
          hasActiveFlag={false}
          getRecordsFunction={LeadConstraintsService.findAll}
          icon={
            <SettingsSuggest sx={{ color: 'primary.main', fontSize: '30px' }} />
          }
          dataCy="leadStrategies"
        />
      </Grid>
    </Container>
  );
};

export default Overview;
