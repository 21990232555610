import React, { useDeferredValue, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Alert, Box, Button, Stack, Tooltip, Typography } from '@mui/material';
import { LeadConstraintsConditionsTable } from '../components/leadConstraints/LeadConstraintsConditionsTable';
import { FormikProvider, useFormik } from 'formik';
import {
  ApiError,
  ConditionGroupDto,
  LeadConstraintDetailDto,
  LeadConstraintsService,
} from '../_generatedApi';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { useLeadConstraint } from '../hooks/useLeadConstraint';
import { Loading } from '../components/common';
import { setLeadConstraints } from '../store/lead-constraints/leadConstraintSlice';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { setSnackbarOpen } from '../store/common/snackbarSlice';
import { setPageSubTitle } from '../store/user/userSlice';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import { LEAD_CONSTRAINTS_ROUTE } from '../router/routes';
import { Link } from 'react-router-dom';
import SaveIcon from '@mui/icons-material/Save';
import relation = ConditionGroupDto.relation;
import { GroupRelationWithLine } from '../components/leadConstraints/GroupRelationWithLine';
import AlertDialog from '../components/AlertDialog';

const TRANSLATION_PREFIX = 'leadConstraints';

const LeadConstraintsConditions = () => {
  const { id } = useParams();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const { data, loading } = useLeadConstraint(id);
  const navigate = useNavigate();
  const [openEmptyConditionDialog, setOpenEmptyConditionDialog] =
    useState(false);

  const deferredDetailLoaded = useDeferredValue(loading);

  useEffect(() => {
    dispatch(
      setPageSubTitle(data?.displayName ? ` - ${data?.displayName}` : ''),
    );
  }, [data?.displayName]);

  const onBeforeSubmit = (values: LeadConstraintDetailDto) => {
    const anyConditionGroupEmpty = values.conditionGroups?.some(
      (cg) => cg?.conditions?.length === 0,
    );

    if (anyConditionGroupEmpty) {
      setOpenEmptyConditionDialog(true);
      return;
    }

    void onSubmit(values);
  };

  const onSubmit = async (values: LeadConstraintDetailDto) => {
    if (values.mainConditionGroup) {
      values.mainConditionGroup.relation = ConditionGroupDto.relation.AND;
    }
    values.conditionGroups = values.conditionGroups.filter(
      (cg) => cg.conditions?.length > 0,
    );
    await updateLeadConstraint(values);
  };

  const leadConstraintForm = useFormik({
    initialValues: {} as LeadConstraintDetailDto,
    enableReinitialize: false,
    onSubmit: onBeforeSubmit,
  });

  useEffect(() => {
    if (data) {
      void leadConstraintForm.setValues(data);
    }
  }, [data]);

  const updateLeadConstraint = async (values: LeadConstraintDetailDto) => {
    try {
      const { id, ...requestBody } = values;
      await LeadConstraintsService.update({ id, requestBody });
      const result = await LeadConstraintsService.findAll();
      dispatch(setLeadConstraints(result.data));
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.update.Ok`,
          severity: 'success',
        }),
      );
      navigate(LEAD_CONSTRAINTS_ROUTE);
    } catch (error) {
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.update.${
            (error as ApiError).status
          }`,
          severity: 'error',
        }),
      );
    }
  };

  if (deferredDetailLoaded) {
    return <Loading />;
  }
  if (!data) {
    return <Alert severity="error">{t(`${TRANSLATION_PREFIX}.noData`)}</Alert>;
  }
  const getConditionGroups = (conditionGroups: ConditionGroupDto[]) => {
    return conditionGroups.map((cg, index) => {
      return (
        <Box key={index} sx={{ border: '1px solid silver', p: 1 }}>
          <Stack direction="row" spacing={2}>
            <GroupRelationWithLine
              name={`conditionGroups[${index}].relation`}
              relation={
                leadConstraintForm.values.conditionGroups[index].relation
              }
            />
            <LeadConstraintsConditionsTable
              onGroupDelete={onGroupDelete}
              isMain={false}
              conditionGroupIndex={index}
            />
          </Stack>
        </Box>
      );
    });
  };

  const onGroupDelete = (index: number) => {
    const conditionGroups = leadConstraintForm.values.conditionGroups.filter(
      (g, idx) => idx !== index,
    );
    void leadConstraintForm.setFieldValue('conditionGroups', [
      ...conditionGroups,
    ]);
  };

  const onGroupAdd = () => {
    const newConditionGroup: ConditionGroupDto = {
      relation: relation.AND,
      conditions: [],
    };

    void leadConstraintForm.setFieldValue('conditionGroups', [
      ...leadConstraintForm.values.conditionGroups,
      newConditionGroup,
    ]);
  };

  return (
    <FormikProvider value={leadConstraintForm}>
      <AlertDialog
        state={openEmptyConditionDialog}
        stateCallback={setOpenEmptyConditionDialog}
        buttonAction={() => onSubmit(leadConstraintForm.values)}
        title={t(`${TRANSLATION_PREFIX}.emptyConditionsDialog.title`)}
        message={t(`${TRANSLATION_PREFIX}.emptyConditionsDialog.message`)}
        buttonText={t(`${TRANSLATION_PREFIX}.emptyConditionsDialog.button`)}
      />
      <Stack spacing={2} sx={{ pb: 2 }}>
        <Typography variant="h6">
          <Tooltip title={t('back')}>
            <Link
              to={LEAD_CONSTRAINTS_ROUTE}
              data-cy="leadConstraintConditionsBack"
            >
              <ArrowBackIcon />
            </Link>
          </Tooltip>
        </Typography>
        <Stack
          direction="row"
          spacing={2}
          data-cy="leadConstraintConditionsOverview"
        >
          <GroupRelationWithLine
            relation={LeadConstraintDetailDto.groupRelation.AND}
          />
          <Stack
            sx={{
              width: '100%',
            }}
            spacing={2}
          >
            <Box>
              <LeadConstraintsConditionsTable isMain={true} />
            </Box>
            <Stack direction="row" spacing={2}>
              {leadConstraintForm.values.conditionGroups.length > 0 && (
                <GroupRelationWithLine
                  name="groupRelation"
                  relation={leadConstraintForm.values.groupRelation}
                />
              )}
              <Stack
                sx={{
                  width: '100%',
                }}
                spacing={2}
              >
                <Stack
                  direction="row"
                  spacing={2}
                  sx={{
                    justifyContent: 'flex-end',
                    alignItems: 'top',
                    display: 'flex',
                  }}
                >
                  <Box>
                    <Button
                      variant="contained"
                      onClick={onGroupAdd}
                      color="success"
                    >
                      <AddCircleIcon sx={{ mr: 1 }} />
                      {t(`${TRANSLATION_PREFIX}.addConditionGroup`)}
                    </Button>
                  </Box>
                </Stack>
                <Stack spacing={2}>
                  {getConditionGroups(
                    leadConstraintForm.values.conditionGroups,
                  )}
                </Stack>
                <Stack
                  spacing={2}
                  direction="row"
                  sx={{ alignSelf: 'self-end' }}
                >
                  <Link to={LEAD_CONSTRAINTS_ROUTE}>
                    <Button variant="outlined" color="secondary">
                      {t('modal.cancelModal')}
                    </Button>
                  </Link>
                  <Button
                    variant="contained"
                    color={'info'}
                    onClick={leadConstraintForm.submitForm}
                    data-cy="saveButton"
                  >
                    <SaveIcon sx={{ mr: 1 }} />
                    {t('modal.confirmModal')}
                  </Button>
                </Stack>
              </Stack>
            </Stack>
          </Stack>
        </Stack>
      </Stack>
    </FormikProvider>
  );
};

export default LeadConstraintsConditions;
