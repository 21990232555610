import { ICellRendererParams } from '@ag-grid-community/core';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SelectRendererProps = Pick<ICellRendererParams, 'value'> & {
  type: string;
};

export const ForTimeRenderer = ({ type, value }: SelectRendererProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {t(`leadConstraints.${type}.labelRenderer`)}: <b>{value?.for}</b>
    </Box>
  );
};
