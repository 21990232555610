import React from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from '../store/store';
import { setSnackbarClose } from '../store/common/snackbarSlice';
import { Toast } from '@flexifincz/ui';
import type { ToastProps } from '@flexifincz/ui';

export type ISnackbarData = Omit<ToastProps, 'message'> & {
  message: string;
};

const Snackbar = () => {
  const { severity, message, open } = useSelector(
    (state: RootState) => state.snackbar.snackbar,
  );

  const { t } = useTranslation();

  const dispatch = useDispatch();
  const handleClose = (
    _event?: React.SyntheticEvent | Event,
    reason?: string,
  ) => {
    if (reason === 'clickaway') {
      return;
    }
    dispatch(setSnackbarClose());
  };

  return (
    <Toast
      open={open}
      onClose={handleClose}
      severity={severity}
      message={t(message)}
    />
  );
};

export default Snackbar;
