import { InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from './types';
import * as Yup from 'yup';
import { validateEditorInput } from './validate-editor-input';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

export const ForTimeEditor = ({
  type,
  params,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
  onlyIntegerValue = false,
  onlyPositiveValue = false,
}: Editor) => {
  const { t } = useTranslation();

  const [value, setValue] = useState<number>(params?.for ?? '');

  useEffect(() => {
    setFieldsTouched({
      value: false,
    });
  }, []);

  const validationSchema = Yup.object({
    value: Yup.number()
      .required(t('editors.valueRequired') as string)
      .test('isPositive', t('editors.valuePositive') as string, (value) => {
        return onlyPositiveValue ? value > 0 : true;
      })
      .test('isInteger', t('editors.mustBeInteger') as string, (value) => {
        return onlyIntegerValue ? Number.isInteger(value) : true;
      }),
  });

  useEffect(() => {
    validateInput({
      value,
    });
    onChangeHandler({ for: value });
  }, [value]);

  const validateInput = (validationObject: object): void => {
    void validateEditorInput(validationSchema, validationObject).then(
      ([, errors]) => {
        setValidationResult(errors);
      },
    );
  };

  const getShowError = (): boolean => {
    return !!validationResult['value'] && fieldsTouched['value'];
  };

  return (
    <Stack direction={'row'} spacing={2}>
      <Tooltip title={getShowError() && validationResult['value']}>
        <TextField
          fullWidth={true}
          label={t(`leadConstraints.${type}.label`)}
          error={getShowError()}
          inputProps={{
            min: onlyPositiveValue ? 1 : 0,
            step: '1',
            type: 'number',
            'data-cy': 'timeValue',
          }}
          value={value.toString()}
          onChange={(event: ChangeEvent<HTMLInputElement>) => {
            const numericValue = Number(event.target.value);
            if (!Number.isNaN(numericValue)) {
              setValue(numericValue);
            }
          }}
          onBlur={() => {
            setFieldsTouched({ value: true });
          }}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getShowError() && <AnnouncementSharpIcon color="error" />}
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
    </Stack>
  );
};
