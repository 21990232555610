import React from 'react';
import MuiAppBar, { AppBarProps as MuiAppBarProps } from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { useTranslation } from 'react-i18next';
import { RootState } from '../store/store';
import { useSelector } from 'react-redux';
import { Container, Stack, styled } from '@mui/material';
import { UserMenu } from './UserMenu';
import { INavSideBarProps } from '../App';
import { DRAWER_WIDTH } from '../consts';
import { LOGIN_ROUTE } from '../router/routes';

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

export const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: DRAWER_WIDTH,
    width: `calc(100% - ${DRAWER_WIDTH}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Navbar = ({ open, onToggleDrawer }: INavSideBarProps) => {
  const pageTitle = useSelector((state: RootState) => state.user.pageTitle);
  const pageSubTitle = useSelector(
    (state: RootState) => state.user.pageSubTitle,
  );
  const { t } = useTranslation();

  return (
    <AppBar
      position="absolute"
      open={open}
      data-cy="app_navbar"
      color="secondary"
    >
      <Toolbar>
        <IconButton
          size="large"
          edge="start"
          aria-label="menu"
          color="inherit"
          sx={{
            pl: '4px',
            marginRight: '36px',
            ...(open && { display: 'none' }),
          }}
          onClick={onToggleDrawer}
          data-cy="drawerToggleButton"
        >
          <MenuIcon />
        </IconButton>
        <Container
          sx={location.pathname !== LOGIN_ROUTE ? { mt: 0, p: 0 } : {}}
          maxWidth={false}
        >
          <Stack
            direction="row"
            alignItems="center"
            sx={{ justifyContent: 'space-between' }}
          >
            <Typography
              variant="h6"
              component="div"
              color="inherit"
              sx={{ flexGrow: 1 }}
              data-cy="sectionTitle"
            >
              {t(pageTitle)}
              {pageSubTitle}
            </Typography>
            <UserMenu />
          </Stack>
        </Container>
      </Toolbar>
    </AppBar>
  );
};

export default Navbar;
