import { AddTaskRounded, SettingsSuggest } from '@mui/icons-material';
import BarChartIcon from '@mui/icons-material/BarChart';
import GroupIcon from '@mui/icons-material/Group';
import LoginIcon from '@mui/icons-material/Login';
import LoopIcon from '@mui/icons-material/Loop';
import PaidIcon from '@mui/icons-material/Paid';
import PersonIcon from '@mui/icons-material/Person';
import SmsIcon from '@mui/icons-material/Sms';
import React from 'react';
import AffiliatePartners from '../pages/AffiliatePartners';
import Budgets from '../pages/Budgets';
import CommunicationChannels from '../pages/CommunicationChannels';
import LeadConstraints from '../pages/LeadConstraints';
import Login from '../pages/Login';
import Overview from '../pages/Overview';
import LeadConstraintsConditions from '../pages/LeadConstraintsConditions';

/* user routes */
export const OVERVIEW_ROUTE = '/';
export const COMMUNICATION_CHANNELS_ROUTE = '/communication-channels';
export const BUDGETS_ROUTE = '/budgets';

/* admin routes */
export const AFFILIATE_PARTNERS_ROUTE = '/affiliate-partners';
export const LEAD_CONSTRAINTS_ROUTE = '/lead-constraints';
export const LEAD_CONSTRAINTS_ROUTE_CONDITIONS =
  '/lead-constraints/:id/condition/';
export const USERS_ROUTE = '/users';
export const SYNCHRONIZE_ROUTE = '/synchronize';

/* anonymous routes */
export const LOGIN_ROUTE = '/login';

export interface IRoute {
  path: string;
  showInMenu?: boolean;
  routeName: string;
  element: React.JSX.Element;
  icon: React.JSX.Element;
}

export const userRoutes: IRoute[] = [
  {
    path: OVERVIEW_ROUTE,
    showInMenu: true,
    routeName: 'routes.overviewRoute',
    element: <Overview />,
    icon: <BarChartIcon sx={{ color: 'primary.main' }} />,
  },
  {
    path: COMMUNICATION_CHANNELS_ROUTE,
    showInMenu: true,
    routeName: 'routes.communicationChannelsRoute',
    element: <CommunicationChannels />,
    icon: <SmsIcon sx={{ color: 'primary.main' }} />,
  },
  {
    path: BUDGETS_ROUTE,
    showInMenu: true,
    routeName: 'routes.budgetsRoute',
    element: <Budgets />,
    icon: <PaidIcon sx={{ color: 'primary.main' }} />,
  },
];

export const adminRoutes: IRoute[] = [
  {
    path: AFFILIATE_PARTNERS_ROUTE,
    showInMenu: true,
    routeName: 'routes.affiliatePartnersRoute',
    element: <AffiliatePartners />,
    icon: <GroupIcon sx={{ color: 'primary.main' }} />,
  },
  {
    path: LEAD_CONSTRAINTS_ROUTE,
    showInMenu: true,
    routeName: 'routes.leadStrategiesRoute',
    element: <LeadConstraints />,
    icon: <SettingsSuggest sx={{ color: 'primary.main' }} />,
  },
  {
    path: LEAD_CONSTRAINTS_ROUTE_CONDITIONS,
    showInMenu: false,
    routeName: 'routes.leadStrategiesConditionRoute',
    element: <LeadConstraintsConditions />,
    icon: <AddTaskRounded sx={{ color: 'primary.main' }} />,
  },
  {
    path: USERS_ROUTE,
    showInMenu: false,
    routeName: 'routes.usersRoute',
    element: <></>,
    icon: <PersonIcon sx={{ color: 'primary.main' }} />,
  },
  {
    path: SYNCHRONIZE_ROUTE,
    showInMenu: false,
    routeName: 'routes.synchronizeRoute',
    element: <></>,
    icon: <LoopIcon sx={{ color: 'primary.main' }} />,
  },
];
export const anonymousRoutes: IRoute[] = [
  {
    path: LOGIN_ROUTE,
    routeName: 'routes.loginRoute',
    element: <Login />,
    icon: <LoginIcon />,
  },
];

export const privateRoutes: IRoute[] = [...userRoutes, ...adminRoutes];
