import { ObjectSchema, ValidationError } from 'yup';

export const validateEditorInput = async (
  validationSchema: ObjectSchema<object>,
  validationObject: object,
): Promise<[boolean, Record<string, string>]> => {
  try {
    await validationSchema.validate(validationObject, { abortEarly: false });
    return [true, {}];
  } catch (e) {
    if (!(e instanceof ValidationError)) {
      return [true, {}];
    }

    const errors = e.inner.reduce(
      (errors, item) => {
        if (item.path) {
          errors[item.path] = item.errors[0];
        }
        return errors;
      },
      {} as Record<string, string>,
    );

    return [false, errors];
  }
};
