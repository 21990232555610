import { ApiError } from '../_generatedApi';

const parseBusinessApiError = (error: ApiError): string => {
  if (error.status !== 400) {
    return error.status.toString();
  }

  const { body } = error;

  if (body.error) {
    const businessErrorString = body.error as string;
    return businessErrorString.replace(/\s/g, '');
  }

  return '400';
};

export default parseBusinessApiError;
