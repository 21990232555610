import { InputAdornment, Stack, TextField, Tooltip } from '@mui/material';
import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from './types';
import * as Yup from 'yup';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import { validateEditorInput } from './validate-editor-input';

export const FromToEditor = ({
  params,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
  onlyIntegerValue = false,
}: Editor) => {
  const { t } = useTranslation();
  const [valueFrom, setValueFrom] = useState<number | undefined>(params?.from);
  const [valueTo, setValueTo] = useState<number | undefined>(params?.to);

  useEffect(() => {
    setFieldsTouched({
      valueFrom: false,
      valueTo: false,
    });
  }, []);

  const validationSchema = Yup.object({
    valueFrom: Yup.number()
      .required(t('editors.valueRequired') as string)
      .lessThan(Yup.ref('valueTo'), t('editors.valueToIsSmaller') as string)
      .test(
        'isPositiveOrZero',
        t('editors.valuePositiveOrZero') as string,
        (value) => {
          return value >= 0;
        },
      )
      .test('isInteger', t('editors.mustBeInteger') as string, (value) => {
        return onlyIntegerValue ? Number.isInteger(value) : true;
      }),
    valueTo: Yup.number()
      .required(t('editors.valueRequired') as string)
      .positive(t('editors.valuePositive') as string)
      .moreThan(Yup.ref('valueFrom'), t('editors.valueFromIsHigher') as string)
      .test('isInteger', t('editors.mustBeInteger') as string, (value) => {
        return onlyIntegerValue ? Number.isInteger(value) : true;
      }),
  });

  useEffect(() => {
    validateInput({
      valueFrom,
      valueTo,
    });
    onChangeHandler({ from: valueFrom, to: valueTo });
  }, [valueFrom, valueTo]);

  const validateInput = (validationObject: object): void => {
    void validateEditorInput(validationSchema, validationObject).then(
      ([, errors]) => {
        setValidationResult(errors);
      },
    );
  };

  const handleChange = (
    event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    field: string,
    stateCallback: (value?: number) => void,
  ) => {
    const defaultValues = { valueFrom, valueTo };
    validateInput({
      ...defaultValues,
      [field]: event.target.value,
    });

    stateCallback(
      event.target.value.length > 0 ? Number(event.target.value) : undefined,
    );
  };

  const getShowError = (fieldName: string): boolean => {
    return !!validationResult[fieldName] && fieldsTouched[fieldName];
  };

  return (
    <Stack direction="row" spacing={2} alignContent="stretch">
      <Tooltip
        title={getShowError('valueFrom') && validationResult['valueFrom']}
      >
        <TextField
          error={getShowError('valueFrom')}
          sx={{ width: 1 / 2 }}
          label={t('leadConstraints.fromToEditor.from')}
          type="number"
          value={valueFrom}
          inputProps={{ min: 0, step: '1', 'data-cy': 'valueFrom' }}
          onChange={(event) => handleChange(event, 'valueFrom', setValueFrom)}
          onBlur={() =>
            setFieldsTouched({
              ...fieldsTouched,
              valueFrom: true,
            })
          }
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                {getShowError('valueFrom') && (
                  <AnnouncementSharpIcon color="error" />
                )}
              </InputAdornment>
            ),
          }}
        />
      </Tooltip>
      <TextField
        error={getShowError('valueTo')}
        sx={{ width: 1 / 2 }}
        label={t('leadConstraints.fromToEditor.to')}
        type="number"
        value={valueTo}
        inputProps={{ min: 1, step: '1', 'data-cy': 'valueTo' }}
        onChange={(event) => handleChange(event, 'valueTo', setValueTo)}
        onBlur={() =>
          setFieldsTouched({
            ...fieldsTouched,
            valueTo: true,
          })
        }
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              {getShowError('valueTo') && (
                <AnnouncementSharpIcon color="error" />
              )}
            </InputAdornment>
          ),
        }}
      />
    </Stack>
  );
};
