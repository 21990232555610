import React from 'react';
import { PropsWithChildren } from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { OVERVIEW_ROUTE } from './routes';

interface IAnonymousRoute {
  token: string;
}
const AnonymousRoute = (props: PropsWithChildren<IAnonymousRoute>) => {
  const { token, children } = props;
  const { state } = useLocation();

  if (token) {
    if (state?.backUrl) {
      return <Navigate to={state.backUrl} replace />;
    }
    return <Navigate to={OVERVIEW_ROUTE} replace />;
  }

  return <>{children}</>;
};

export default AnonymousRoute;
