/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type ConditionDto = {
  type: ConditionDto.type;
  conditionValues: Record<string, any>;
};

export namespace ConditionDto {
  export enum type {
    AGE = 'AGE',
    ESTABLISHED_CASE = 'ESTABLISHED_CASE',
    APPLICATION_IN_PROGRESS = 'APPLICATION_IN_PROGRESS',
    TIME_WINDOW = 'TIME_WINDOW',
    REQUESTED_AMOUNT = 'REQUESTED_AMOUNT',
    PERIOD = 'PERIOD',
    MARITAL_STATUS = 'MARITAL_STATUS',
    DEPENDENT_CHILDREN = 'DEPENDENT_CHILDREN',
    INCOME_TYPE = 'INCOME_TYPE',
    EMPLOYED_TIME = 'EMPLOYED_TIME',
  }
}
