/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCollectionDataResponseOfKeyValueDto } from '../models/ApiCollectionDataResponseOfKeyValueDto';
import type { ApiCollectionDataResponseOfLeadConstraintDto } from '../models/ApiCollectionDataResponseOfLeadConstraintDto';
import type { ApiDataResponseOfLeadConstraintDetailDto } from '../models/ApiDataResponseOfLeadConstraintDetailDto';
import type { ApiResponseDto } from '../models/ApiResponseDto';
import type { CreateLeadConstraintDto } from '../models/CreateLeadConstraintDto';
import type { UpdateLeadConstraintDto } from '../models/UpdateLeadConstraintDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class LeadConstraintsService {
  /**
   * @returns ApiCollectionDataResponseOfLeadConstraintDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findAll(): CancelablePromise<ApiCollectionDataResponseOfLeadConstraintDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/lead-constraints',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfLeadConstraintDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: CreateLeadConstraintDto;
  }): CancelablePromise<ApiDataResponseOfLeadConstraintDetailDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/lead-constraints',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiCollectionDataResponseOfKeyValueDto Data successfully retrieved.
   * @throws ApiError
   */
  public static getLookups(): CancelablePromise<ApiCollectionDataResponseOfKeyValueDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/lead-constraints/lookups',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfLeadConstraintDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiDataResponseOfLeadConstraintDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/lead-constraints/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfLeadConstraintDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static update({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UpdateLeadConstraintDto;
  }): CancelablePromise<ApiDataResponseOfLeadConstraintDetailDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/lead-constraints/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Conflict** - Resource cannot be deleted due to existing relations.
   * @returns ApiResponseDto Action was successful.
   * @throws ApiError
   */
  public static remove({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponseDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/lead-constraints/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
