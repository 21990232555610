/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CreateCommunicationChannelDto = {
  displayName: string;
  code: string;
  affiliatePartnerId: string;
  type: CreateCommunicationChannelDto.type;
  active: boolean;
  leadConstraintId: string;
  note?: string;
  token1: string;
  token2: string;
  postbackEnabled: boolean;
};

export namespace CreateCommunicationChannelDto {
  export enum type {
    CPL = 'CPL',
    CPS = 'CPS',
  }
}
