import { BudgetOperationDto, DateInterval } from '../../_generatedApi';
import { useTranslation } from 'react-i18next';
import Typography from '@mui/material/Typography';
import { GridTable, ColumnDefitions } from '../gridTable';

interface IBudgetOperations {
  budgetOperations: BudgetOperationDto[];
}

const BudgetOperations = ({ budgetOperations }: IBudgetOperations) => {
  const { t } = useTranslation();

  const formatInterval = ({ validFrom, validTo }: DateInterval) => {
    if (validTo) {
      return `${validFrom} - ${validTo}`;
    }
    return validFrom;
  };

  const operations = budgetOperations.map(
    ({ existingBudget, action, budgetName }) => {
      if (existingBudget) {
        const validFrom = formatInterval(
          existingBudget.oldDateInterval as DateInterval,
        );
        const operationChanges =
          action === BudgetOperationDto.action.DELETE
            ? t('budgets.willBeDeleted')
            : formatInterval(existingBudget.newDateInterval as DateInterval) ||
              t('budgets.undefinedDate');

        return { displayName: budgetName, validFrom, operationChanges };
      }
    },
  );

  const budgetOperationsColumns: ColumnDefitions = {
    displayName: {
      sortable: false,
      filter: false,
    },
    validFrom: {
      sortable: false,
      filter: false,
      headerName: t('budgets.validActual') as string,
    },
    operationChanges: {
      sortable: false,
      filter: false,
    },
  };

  return (
    <>
      <Typography component="p" variant="body1" marginBottom={3}>
        {t('budgets.budgetOperationsWarning')}
      </Typography>
      <GridTable
        colDefs={budgetOperationsColumns}
        data={operations}
        translationPrefix={'budgets'}
        hideResetFilter={true}
        enablePagination={false}
      />
    </>
  );
};

export default BudgetOperations;
