import { Warning } from '@mui/icons-material';
import { Tooltip } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { SyntheticEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import {
  AffiliatePartnersService,
  ApiError,
  CommunicationChannelsService,
  CreateAffiliatePartnerDto,
  KeyValueDto,
  UpdateAffiliatePartnerDto,
} from '../../_generatedApi';
import { AffilatePartnerValues } from '../../pages/AffiliatePartners';
import { setAffiliates } from '../../store/affiliate/affiliateSlice';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import AffiliatePartnerDeactivationModal from './AffiliatePartnerDeactivationModal';
import { AffiliatePartnersForm } from './AffiliatePartnersForm';
import { setSnackbarOpen } from '../../store/common/snackbarSlice';

interface IAffilatePartnesModal {
  open: boolean;
  setOpen: (value: boolean) => void;
  values: AffilatePartnerValues;
  setValues: (values: AffilatePartnerValues) => void;
}

export const AffiliatePartnersModal = (props: IAffilatePartnesModal) => {
  const { values, setValues, open, setOpen } = props;
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [activePartnerChannels, setActivePartnerChannels] = useState<
    KeyValueDto[]
  >([]);
  const [partnerDeactivated, setPartnerDeactivated] = useState<boolean>(false);
  const [deactivationWarningOpened, setDeactivationWarningOpened] =
    useState<boolean>(false);

  const handleActiveChange = async (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ) => {
    setPartnerDeactivated(!checked);
    const values: AffilatePartnerValues = {
      ...partnersForm.values,
      active: checked,
    };
    await partnersForm.setValues(values);
  };

  const isNewPartner = !values?.id;
  const onSubmit = async (values: AffilatePartnerValues) => {
    if (!isNewPartner) {
      if (partnerDeactivated) {
        await updateDeactivatedPartner();
        return;
      }

      await updateAffiliatePartner(values);
      return;
    }

    if (values?.displayName) {
      const affiliatePartner: CreateAffiliatePartnerDto = {
        displayName: values.displayName,
        active: values.active as boolean,
        note: values.note,
      };
      void AffiliatePartnersService.create({
        requestBody: affiliatePartner,
      })
        .then(() => {
          void AffiliatePartnersService.findAll().then((result) => {
            dispatch(setAffiliates(result.data));
            dispatch(
              setSnackbarOpen({
                message: 'affiliatePartners.response.create.Ok',
                severity: 'success',
              }),
            );
            void onClose();
          });
        })
        .catch((error) => {
          dispatch(
            setSnackbarOpen({
              message: `affiliatePartners.response.create.${error.status}`,
              severity: 'error',
            }),
          );
        });
    }
  };

  const updateDeactivatedPartner = async () => {
    try {
      const activeChannels = await CommunicationChannelsService.getLookups({
        partnerId: values.id,
        active: true,
      });

      if (!activeChannels.data.length) {
        await updateAffiliatePartner(partnersForm.values);
        return;
      }

      setActivePartnerChannels(activeChannels.data);
      setDeactivationWarningOpened(true);
    } catch (ex: unknown) {
      const error = ex as ApiError;
      dispatch(
        setSnackbarOpen({
          message: `responseCode.${error.status}`,
          severity: 'error',
        }),
      );
    }
  };

  const onDeactivationModalClose = async () => {
    setDeactivationWarningOpened(false);
    setPartnerDeactivated(false);
    const values: AffilatePartnerValues = {
      ...partnersForm.values,
      active: true,
    };
    await partnersForm.setValues(values);
  };

  const onDeactivationModalSubmit = async () => {
    setDeactivationWarningOpened(false);
    setPartnerDeactivated(false);
    await updateAffiliatePartner(partnersForm.values);
    void onClose();
  };

  const updateAffiliatePartner = async (values: AffilatePartnerValues) => {
    const affiliatePartner: UpdateAffiliatePartnerDto = {
      displayName: values.displayName as string,
      active: values.active as boolean,
      note: values.note,
    };
    void AffiliatePartnersService.update({
      id: values.id as string,
      requestBody: affiliatePartner,
    })
      .then(() => {
        void AffiliatePartnersService.findAll().then((result) => {
          dispatch(setAffiliates(result.data));
          dispatch(
            setSnackbarOpen({
              message: 'affiliatePartners.response.update.Ok',
              severity: 'success',
            }),
          );
          void onClose();
        });
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `affiliatePartners.response.update.${error.status}`,
            severity: 'error',
          }),
        );
      });
  };

  const onClose = async () => {
    await partnersForm.handleReset({});
    await partnersForm.setValues(initialValues);
    setValues({});
    setOpen(false);
  };

  const initialValues: AffilatePartnerValues = {
    displayName: '',
    active: false,
    note: '',
  };

  const validationSchema = object({
    displayName: string()
      .max(
        50,
        t('affiliatePartners.nameTooLong', { maxLength: '50' }) as string,
      )
      .required(t('affiliatePartners.nameRequired') as string),
    note: string()
      .optional()
      .max(
        255,
        t('affiliatePartners.noteTooLong', { maxLength: '255' }) as string,
      )
      .nullable(),
  });

  const partnersForm = useFormik({
    initialValues: { ...initialValues, ...values },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  return (
    <FormikProvider value={partnersForm}>
      <AffiliatePartnerDeactivationModal
        open={deactivationWarningOpened}
        channels={activePartnerChannels}
        onClose={onDeactivationModalClose}
        onSubmit={onDeactivationModalSubmit}
      />
      <CommonModal
        dataCy={'createEditDialog'}
        open={open}
        onClose={onClose}
        title={
          isNewPartner
            ? t('affiliatePartners.newAffiliatePartners')
            : t('affiliatePartners.editAffiliatePartners')
        }
        titleIcon={
          !isNewPartner && values.activeChannelCount === 0 ? (
            <Tooltip title={t('affiliatePartners.noActiveChannel')}>
              <Warning color="warning" />
            </Tooltip>
          ) : undefined
        }
        content={<AffiliatePartnersForm onActiveChange={handleActiveChange} />}
        isNew={isNewPartner}
        footer={
          <ModalFooter
            onSubmit={partnersForm.submitForm}
            onClose={onClose}
            isNew={isNewPartner}
          />
        }
        modalWidth={'400px'}
      />
    </FormikProvider>
  );
};
