/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCollectionDataResponseOfAffiliatePartnerDto } from '../models/ApiCollectionDataResponseOfAffiliatePartnerDto';
import type { ApiCollectionDataResponseOfKeyValueDto } from '../models/ApiCollectionDataResponseOfKeyValueDto';
import type { ApiDataResponseOfAffiliatePartnerDto } from '../models/ApiDataResponseOfAffiliatePartnerDto';
import type { ApiResponseDto } from '../models/ApiResponseDto';
import type { CreateAffiliatePartnerDto } from '../models/CreateAffiliatePartnerDto';
import type { UpdateAffiliatePartnerDto } from '../models/UpdateAffiliatePartnerDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AffiliatePartnersService {
  /**
   * @returns ApiCollectionDataResponseOfAffiliatePartnerDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findAll(): CancelablePromise<ApiCollectionDataResponseOfAffiliatePartnerDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/affiliate-partners',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfAffiliatePartnerDto Data successfully retrieved.
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: CreateAffiliatePartnerDto;
  }): CancelablePromise<ApiDataResponseOfAffiliatePartnerDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/affiliate-partners',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiCollectionDataResponseOfKeyValueDto Data successfully retrieved.
   * @throws ApiError
   */
  public static getLookups(): CancelablePromise<ApiCollectionDataResponseOfKeyValueDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/affiliate-partners/lookups',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfAffiliatePartnerDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiDataResponseOfAffiliatePartnerDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/affiliate-partners/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.
   * @returns ApiDataResponseOfAffiliatePartnerDto Data successfully retrieved.
   * @throws ApiError
   */
  public static update({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UpdateAffiliatePartnerDto;
  }): CancelablePromise<ApiDataResponseOfAffiliatePartnerDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/affiliate-partners/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Conflict** - Resource cannot be deleted due to existing relations.
   * @returns ApiResponseDto Action was successful.
   * @throws ApiError
   */
  public static remove({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponseDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/affiliate-partners/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
