import {
  FormControl,
  InputLabel,
  List,
  Select,
  SelectChangeEvent,
  styled,
} from '@mui/material';
import MuiDrawer from '@mui/material/Drawer';
import MenuItem from '@mui/material/MenuItem';
import React from 'react';
import Divider from '@mui/material/Divider';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import IconButton from '@mui/material/IconButton';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { IRoute, userRoutes, adminRoutes } from '../router/routes';
import { RootState } from '../store/store';
import { Languages } from '../store/user/languages';
import { setLanguage } from '../store/user/userSlice';
import Toolbar from '@mui/material/Toolbar';
import { DRAWER_WIDTH } from '../consts';
import { INavSideBarProps } from '../App';
import { Stack } from '@flexifincz/ui';
import Logo from '@flexifincz/ui/assets/logo.svg';

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  '& .MuiDrawer-paper': {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: DRAWER_WIDTH,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    boxSizing: 'border-box',
    ...(!open && {
      overflowX: 'hidden',
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      width: theme.spacing(5),
      [theme.breakpoints.up('sm')]: {
        width: theme.spacing(7),
      },
    }),
  },
}));

const Sidebar = ({ open, onToggleDrawer }: INavSideBarProps) => {
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const toggleDrawer = () => {
    onToggleDrawer();
  };
  const language = useSelector((state: RootState) => state.user.language);
  const dispatch = useDispatch();

  const renderRoutes = (routes: IRoute[]) => {
    return routes.map((route: IRoute) => {
      if (!route?.showInMenu) {
        return;
      }
      return (
        <ListItemButton
          key={route.path}
          onClick={() => onRedirect(route.path)}
          title={t(route.routeName) as string}
        >
          <ListItemIcon>{route.icon}</ListItemIcon>
          <ListItemText primary={t(route.routeName)} />
        </ListItemButton>
      );
    });
  };

  const onChangeLanguage = async (e: SelectChangeEvent) => {
    dispatch(setLanguage(e.target.value));
    await i18n.changeLanguage(e.target.value);
  };

  const onRedirect = (path: string) => {
    navigate(path);
  };

  return (
    <Drawer variant="permanent" open={open} onClose={onToggleDrawer}>
      <Toolbar
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'flex-end',
          px: [1],
          padding: '8px 0',
        }}
      >
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="end"
          width="100%"
          sx={{ ml: 1 }}
        >
          <Stack minWidth={'160px'}>
            <img src={Logo} alt="logo" />
          </Stack>
          <IconButton onClick={toggleDrawer}>
            <ChevronLeftIcon />
          </IconButton>
        </Stack>
      </Toolbar>
      <Stack direction="column" justifyContent="space-between" flexGrow={1}>
        <List component="nav">
          {renderRoutes(userRoutes)}
          <Divider sx={{ mb: 2 }} />
          {renderRoutes(adminRoutes)}
        </List>

        {open && (
          <FormControl sx={{ width: '100%' }} size="small">
            <InputLabel id="language-label">{t('language')}</InputLabel>
            <Select
              labelId="language-label"
              id="language-label"
              value={language}
              label={t('language')}
              onChange={onChangeLanguage}
              data-cy="languageSwitcher"
            >
              <MenuItem value={Languages.CS} data-cy="languageSwitcherItem">
                {t('languages.cs')}
              </MenuItem>
              <MenuItem value={Languages.EN} data-cy="languageSwitcherItem">
                {t('languages.en')}
              </MenuItem>
            </Select>
          </FormControl>
        )}
      </Stack>
    </Drawer>
  );
};

export default Sidebar;
