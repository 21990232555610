import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { ISnackbarData } from '../../components/Snackbar';

type SnackbarState = Omit<ISnackbarData, 'open'> & {
  open?: boolean;
};

const initialState: {
  snackbar: ISnackbarData;
} = {
  snackbar: { severity: 'info', message: '', open: false },
};

export const snackbarSlice = createSlice({
  name: 'snackbar',
  initialState,
  reducers: {
    setSnackbarOpen: (state, action: PayloadAction<SnackbarState>) => {
      state.snackbar = { ...action.payload, open: true };
    },
    setSnackbarClose: (state) => {
      state.snackbar = { ...state.snackbar, open: false };
    },
  },
});

export const { setSnackbarOpen, setSnackbarClose } = snackbarSlice.actions;

export default snackbarSlice.reducer;
