/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { KeyValueDto } from './KeyValueDto';

export type CommunicationChannelDto = {
  id: string;
  displayName: string;
  code: string;
  leadConstraint: KeyValueDto;
  affiliatePartner: KeyValueDto;
  type: CommunicationChannelDto.type;
  active: boolean;
  postbackActive: boolean;
  note?: string;
  token1: string;
  token2: string;
  deletable: boolean;
  activable: boolean;
};

export namespace CommunicationChannelDto {
  export enum type {
    CPL = 'CPL',
    CPS = 'CPS',
  }
}
