import { useEffect, useState } from 'react';
import {
  CommunicationChannelDto,
  CommunicationChannelsService,
} from '../_generatedApi';

export const useCommunicationsChannel = (communicationChannelId?: string) => {
  const [data, setData] = useState<CommunicationChannelDto | undefined>(
    undefined,
  );
  const [error, setError] = useState<string | null>(null);
  const [loading, setLoading] = useState<boolean>(true);
  const [isNew, setIsNew] = useState<boolean>(true);

  useEffect(() => {
    if (!communicationChannelId) {
      return;
    }
    setIsNew(false);
    void CommunicationChannelsService.findOne({
      id: communicationChannelId,
    })
      .then(({ data }) => {
        setLoading(false);
        setData(data);
      })
      .catch((error) => {
        setError(error.message);
        setLoading(false);
      });
  }, [communicationChannelId]);

  return { data, error, loading, isNew };
};
