import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { useTranslation } from 'react-i18next';
import { Stack } from '@mui/material';

interface IDialogAlert {
  state: boolean;
  stateCallback: (value: boolean) => void;
  buttonAction: () => void;
  title: string;
  message: string | React.ReactNode;
  buttonText: string;
  dataCy?: string;
}
const DialogAlert = (props: IDialogAlert) => {
  const {
    state,
    stateCallback,
    buttonAction,
    title,
    message,
    buttonText,
    dataCy,
  } = props;
  const { t } = useTranslation();
  const handleClose = () => {
    stateCallback(false);
  };

  return (
    <Dialog open={state} onClose={handleClose} data-cy={dataCy}>
      <DialogTitle>{title}</DialogTitle>
      <DialogContent>
        <DialogContentText color={'primary.main'}>{message}</DialogContentText>
      </DialogContent>
      <DialogActions>
        <Stack direction={'row'} spacing={2} justifyContent="flex-end">
          <Button
            onClick={handleClose}
            variant="outlined"
            color="secondary"
            data-cy="cancelButton"
          >
            {t('cancel')}
          </Button>
          <Button
            onClick={buttonAction}
            variant="contained"
            color="error"
            autoFocus
            data-cy="confirmButton"
          >
            {buttonText}
          </Button>
        </Stack>
      </DialogActions>
    </Dialog>
  );
};

export default DialogAlert;
