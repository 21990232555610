import { Box, Button, Chip, Stack } from '@mui/material';
import { Field, FormikProvider, useFormik, useFormikContext } from 'formik';
import { useTranslation } from 'react-i18next';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import { TextField } from '../form/FormikTextField';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import React, { useEffect, useState } from 'react';
import { number, object, string } from 'yup';
import { MAX_32_BIT_INTEGER_VALUE, EMAIL_REGEX } from '../../consts';
import CancelIcon from '@mui/icons-material/Cancel';
import { CreateBudgetDto, NotificationDto } from '../../_generatedApi';
import { v4 } from 'uuid';

export enum NotificationModalModes {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
}

export type NotificationsWithId = NotificationDto & {
  id: string;
};

interface INotificationModal {
  open: boolean;
  closeModal: () => void;
  mode: NotificationModalModes;
  notification?: NotificationsWithId;
}

type NotificationForm = {
  id: string;
  percentage: number;
  newEmail: string;
  users: Array<string>;
};

enum EmailState {
  NEW = 'NEW',
  DELETED = 'DELETED',
  SAVED = 'SAVED',
}

type EmailChips = {
  email: string;
  state: EmailState;
};

const getColorForEmailState = (state: EmailState) => {
  switch (state) {
    case EmailState.DELETED:
      return 'error';
    case EmailState.NEW:
      return 'success';
    case EmailState.SAVED:
      return 'info';
    default:
      return 'success';
  }
};

export const NotificationModal = ({
  open,
  closeModal,
  notification,
  mode,
}: INotificationModal) => {
  const { t } = useTranslation();
  const modeTitleMap = {
    [NotificationModalModes.CREATE]: t('notifications.newNotification'),
    [NotificationModalModes.UPDATE]: t('notifications.editNotification'),
  };
  const isNew = notification?.id === undefined;
  const [savedEmails, setSavedEmails] = useState<Array<EmailChips>>([]);
  const budgetForm = useFormikContext<CreateBudgetDto>();

  const percentage = isNew ? '' : notification?.percentage || '';

  useEffect(() => {
    setSavedEmails(
      notification?.users?.map((email) => ({
        email,
        state: EmailState.SAVED,
      })) || [],
    );
  }, [notification]);

  const resetForm = (isNewType = false) => {
    if (isNewType) {
      setSavedEmails([]);
    }

    notificationForm.resetForm();
  };

  const onSubmit = async (values: NotificationsWithId) => {
    const validEmails = savedEmails?.filter(
      ({ state }) => state !== EmailState.DELETED,
    );

    if (validEmails && validEmails.length < 1) {
      notificationForm.setFieldError(
        'newEmail',
        t('notifications.atleastOneEmail'),
      );
      return;
    }

    let notificationsValue = budgetForm.values
      .notifications as Array<NotificationsWithId>;
    const newNotification: NotificationsWithId = {
      id: isNew ? v4() : notification.id,
      users:
        savedEmails
          ?.filter(({ state }) => state !== EmailState.DELETED)
          ?.map(({ email }) => email) || [],
      percentage: Number(values.percentage),
    };
    if (isNew) {
      notificationsValue.push(newNotification);
    } else {
      notificationsValue = notificationsValue.map((notificationValue) => {
        if (notificationValue?.id === notification?.id) {
          return newNotification;
        }
        return notificationValue;
      });
    }
    await budgetForm.setFieldValue(
      'notifications',
      [...notificationsValue].sort(
        (a, b) => Number(a.percentage) - Number(b.percentage),
      ),
    );
    closeModal();
    resetForm(isNew);
  };

  const validationSchema = object({
    percentage: number()
      .min(1, t('budgets.onlyPositiveNumber') as string)
      .max(MAX_32_BIT_INTEGER_VALUE, t('budgets.maxNumber') as string)
      .integer(t('budgets.onlyIntegerAllowed') as string)
      .required(t('budgets.costPerLeadRequired') as string),
    newEmail: string().matches(
      EMAIL_REGEX,
      t('notifications.invalidEmailFormat') as string,
    ),
  });

  const validationSchemaEmail = string()
    .matches(EMAIL_REGEX, t('notifications.invalidEmailFormat') as string)
    .required();

  const notificationForm = useFormik<NotificationForm>({
    initialValues: {
      percentage: percentage as number,
      newEmail: '',
      users: [],
      id: '',
    },
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const handleClose = async () => {
    closeModal();
    resetForm(isNew);
  };

  const handleDelete = (index: number) => {
    if (!savedEmails) {
      return;
    }
    const { state } = savedEmails[index];
    const newSavedEmails = [...savedEmails];
    switch (state) {
      case EmailState.DELETED:
        newSavedEmails[index].state = EmailState.SAVED;
        break;
      case EmailState.NEW:
        newSavedEmails.splice(index, 1);
        break;
      case EmailState.SAVED:
        newSavedEmails[index].state = EmailState.DELETED;
        break;
    }
    setSavedEmails(newSavedEmails);
  };

  const addNewEmail = () => {
    if (validationSchemaEmail.isValidSync(notificationForm.values.newEmail)) {
      setSavedEmails([
        ...savedEmails,
        { email: notificationForm.values.newEmail, state: EmailState.NEW },
      ]);
      return notificationForm.setValues({
        ...notificationForm.values,
        newEmail: '',
      });
    }
  };

  return (
    <>
      <CommonModal
        dataCy="createEditDialog-notify"
        open={open}
        onClose={handleClose}
        title={modeTitleMap[mode]}
        isNew={isNew}
        content={
          <FormikProvider value={notificationForm}>
            <Stack spacing={2}>
              <Field
                component={TextField}
                name="percentage"
                label={t('notifications.percentage')}
                required={true}
                inputProps={{
                  'data-cy': `budgetNotifyPercentage`,
                }}
              />
              <Stack direction="row" spacing={2}>
                <Field
                  sx={{ width: '50%' }}
                  component={TextField}
                  name="newEmail"
                  label={t('notifications.email')}
                  required={true}
                  inputProps={{
                    'data-cy': `budgetNotifyEmail`,
                  }}
                  onKeyDown={(event: React.KeyboardEvent<HTMLDivElement>) => {
                    if (event.key === 'Enter') {
                      void addNewEmail();
                    }
                  }}
                />
                <Box sx={{ width: '50%' }}>
                  <Button
                    sx={{ width: '100%' }}
                    variant="contained"
                    color="success"
                    onClick={addNewEmail}
                    data-cy="budgetNotifyEmailAddButton"
                  >
                    <AddCircleIcon sx={{ mr: 1 }} />
                    {t('notifications.confirmNewEmail')}
                  </Button>
                </Box>
              </Stack>
              <div data-cy="chipsWrapper">
                {savedEmails?.map(({ email, state }: EmailChips, index) => (
                  <Chip
                    label={email}
                    key={email}
                    color={getColorForEmailState(state)}
                    sx={{ m: 1 / 2, p: 0 }}
                    onDelete={() => handleDelete(index)}
                    clickable
                    data-cy="chipItem"
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                        data-cy="chipDeleteCheckbox"
                      />
                    }
                  />
                ))}
              </div>
            </Stack>
          </FormikProvider>
        }
        footer={
          <ModalFooter
            modalMode={mode as string}
            isNew={isNew}
            onSubmit={notificationForm.submitForm}
            onClose={handleClose}
          />
        }
        modalWidth={'650px'}
      />
    </>
  );
};
