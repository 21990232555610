import {
  ICellRendererParams,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { RemoveCircle } from '@mui/icons-material';
import { Box, Button, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionDto, LeadConstraintDetailDto } from '../../_generatedApi';
import AlertDialog from '../AlertDialog';
import {
  ColumnDefitions,
  GridColumnType,
  GridTable,
  IGridRowAction,
} from '../gridTable';
import { getRenderer } from './functions';
import { LeadConstrainsConditionsModal } from './LeadConstrainsConditionsModal';
import { GridColDef } from '../gridTable/types';

export interface ILeadConstraintsConditionProps {
  isMain: boolean;
  conditionGroupIndex?: number;
  onGroupDelete?: (index: number) => void;
}

export interface IModalConditionsValues {
  params: ConditionDto;
  rowIndex: number;
}

interface ConditionsValue {
  rowIndex: number;
  conditionGroupIndex?: number;
  values: LeadConstraintDetailDto;
}

export const LeadConstraintsConditionsTable: FC<
  ILeadConstraintsConditionProps
> = ({ isMain, conditionGroupIndex, onGroupDelete }) => {
  const { values, setFieldValue } = useFormikContext<LeadConstraintDetailDto>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState<boolean>(false);
  const [openConditionDeleteDialog, setOpenConditionDeleteDialog] =
    useState(false);
  const [deleteConditionsValue, setDeleteConditionsValue] =
    useState<ConditionsValue>();
  const [deleteConditionName, setDeleteConditionName] = useState<string>('');
  const { t } = useTranslation();
  const [openModal, setOpenModal] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState<IModalConditionsValues>();

  const conditionColumns: ColumnDefitions = {
    type: {
      flex: 0.55,

      valueFormatter: (params: ValueFormatterParams) =>
        t(`leadConstraints.conditionTypes.${params.value}`),
    },
    conditionValues: {
      fieldType: GridColumnType.String,
      cellRendererSelector: (params: ICellRendererParams) => {
        const conditionType = params.data.type as ConditionDto.type;
        if (!conditionType) {
          return undefined;
        }

        return getRenderer(conditionType, params.data.conditionValues);
      },
    },
  };

  const defaultColDef: GridColDef = {
    sortable: true,
    resizable: true,
    filter: true,
    valueFormatter: (params: ValueFormatterParams) => params.value,
  };

  const getConditionGroup = (): Array<ConditionDto> => {
    if (isMain) {
      return values.mainConditionGroup?.conditions ?? [];
    }

    if (conditionGroupIndex === undefined) {
      return [];
    }

    return values.conditionGroups?.[conditionGroupIndex]?.conditions;
  };

  const setConditionsValue = ({
    rowIndex,
    conditionGroupIndex,
    values,
  }: ConditionsValue) => {
    if (typeof conditionGroupIndex === 'undefined') {
      const conditionsMain = values.mainConditionGroup?.conditions.filter(
        (condition, index) => index !== rowIndex,
      );
      void setFieldValue('mainConditionGroup.conditions', conditionsMain);
    } else {
      const conditions = values.conditionGroups?.[
        conditionGroupIndex
      ]?.conditions.filter((condition, index) => index !== rowIndex);
      void setFieldValue(
        `conditionGroups[${conditionGroupIndex}].conditions`,
        conditions,
      );
    }
  };

  const handleGroupDelete = () => {
    if (conditionGroupIndex === undefined || !onGroupDelete) {
      return;
    }
    onGroupDelete(conditionGroupIndex);
    setOpenDeleteDialog(false);
  };

  const handleConditionDelete = () => {
    deleteConditionsValue && setConditionsValue(deleteConditionsValue);
    setOpenConditionDeleteDialog(false);
  };

  const conditionActions: IGridRowAction = {
    onEdit: (params: unknown, rowIndex?: number) => {
      setOpenModal(true);
      setModalValues({
        params: params as ConditionDto,
        rowIndex: rowIndex as number,
      });
    },
    onDelete: (params: unknown, rowIndex?: number) => {
      if (rowIndex === undefined) {
        return;
      }

      setDeleteConditionsValue({
        rowIndex,
        conditionGroupIndex,
        values,
      });

      const condition = params as ConditionDto;
      setDeleteConditionName(
        t(`leadConstraints.conditionTypes.${condition.type}`) as string,
      );
      setOpenConditionDeleteDialog(true);
    },
  };

  return (
    <Stack width={'100%'} spacing={2}>
      <AlertDialog
        state={openDeleteDialog}
        stateCallback={setOpenDeleteDialog}
        buttonAction={handleGroupDelete}
        title={t(`leadConstraints.deleteConditionGroup`)}
        message={t(`leadConstraints.deleteConditionGroupMessage`) as string}
        buttonText={t(`leadConstraints.dialogAlert.button`)}
        dataCy={'deleteDialog'}
      />
      <AlertDialog
        state={openConditionDeleteDialog}
        stateCallback={setOpenConditionDeleteDialog}
        buttonAction={handleConditionDelete}
        title={t('leadConstraints.deleteConditionDialog.title')}
        message={t('leadConstraints.deleteConditionDialog.message', {
          conditionName: deleteConditionName,
        })}
        buttonText={t('leadConstraints.dialogAlert.button')}
        dataCy={'deleteDialog'}
      />
      {openModal && (
        <LeadConstrainsConditionsModal
          isNewCondition={!modalValues}
          open={openModal}
          setOpenModal={setOpenModal}
          value={modalValues}
          conditionGroupIndex={conditionGroupIndex}
        />
      )}
      <Stack direction={'column'} spacing={2}>
        <Stack
          direction={'row'}
          spacing={2}
          sx={{
            justifyContent: 'flex-end',
            alignItems: 'top',
          }}
        >
          {!isMain && (
            <>
              <Box>
                <Button
                  disabled={isMain}
                  color={'error'}
                  variant="contained"
                  onClick={() => setOpenDeleteDialog(true)}
                >
                  <RemoveCircle sx={{ mr: 1 }} />
                  {t(`leadConstraints.deleteConditionGroup`)}
                </Button>
              </Box>
            </>
          )}
        </Stack>

        <GridTable
          onCreate={() => {
            setOpenModal(true);
            setModalValues(undefined);
          }}
          colDefs={conditionColumns}
          data={getConditionGroup() || []}
          translationPrefix={'leadConstraints'}
          actions={conditionActions}
          hideResetFilter={true}
          enablePagination={false}
          gridOptionsOverride={{
            suppressNoRowsOverlay: true,
            stopEditingWhenCellsLoseFocus: false,
            defaultColDef,
          }}
        />
      </Stack>
    </Stack>
  );
};
