import {
  Box,
  Icon,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  TextField,
  Tooltip,
} from '@mui/material';
import { MobileTimePicker } from '@mui/x-date-pickers/MobileTimePicker';
import dayjs, { Dayjs } from 'dayjs';
import React, { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from './types';
import { ConditionDto } from '../../../_generatedApi';
import * as Yup from 'yup';
import { validateEditorInput } from './validate-editor-input';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

export const TimeWindowEditor = ({
  params,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
}: Editor) => {
  const weekDayNumbers = [1, 2, 3, 4, 5, 6, 7];

  const [weekDays, setWeekDays] = useState<string[]>(params?.weekDays ?? []);
  const timeFromParsed = params?.timeFrom.split(':');
  const [timeFrom, setTimeFrom] = useState<Dayjs>(
    dayjs()
      .startOf('day')
      .hour(timeFromParsed?.[0] ?? 0)
      .minute(timeFromParsed?.[1] ?? 0),
  );
  const timeToParsed = params?.timeTo.split(':');
  const [timeTo, setTimeTo] = useState<Dayjs>(
    dayjs()
      .startOf('day')
      .hour(timeToParsed?.[0] ?? 0)
      .minute(timeToParsed?.[1] ?? 0),
  );
  const [daysFrom, setDaysFrom] = useState<number | undefined>(
    params?.daysInMonthFrom,
  );
  const [daysTo, setDaysTo] = useState<number | undefined>(
    params?.daysInMonthTo,
  );

  useEffect(() => {
    setFieldsTouched({
      weekDays: false,
      timeFrom: false,
      timeTo: false,
      daysInMonthFrom: false,
      daysInMonthTo: false,
    });
  }, []);

  const { t } = useTranslation();

  const getAllValues = (newValues: ConditionDto['conditionValues']) => {
    return {
      weekDays: newValues?.weekdays ? newValues?.weekdays : weekDays,
      timeFrom: dayjs(
        newValues?.timeFrom ? newValues.timeFrom : timeFrom,
      ).format('HH:mm'),
      timeTo: dayjs(newValues?.timeTo ? newValues.timeTo : timeTo).format(
        'HH:mm',
      ),
      daysInMonthFrom: newValues?.daysFrom ? newValues?.daysFrom : daysFrom,
      daysInMonthTo: newValues?.daysTo ? newValues?.daysTo : daysTo,
    };
  };

  const getHoursFromHoursMinuteString = (timeString: string): number => {
    return parseInt(timeString.split(':')[0]);
  };

  const validationSchema = Yup.object({
    timeFrom: Yup.number()
      .transform((value, originalValue) =>
        getHoursFromHoursMinuteString(originalValue),
      )
      .required(t('editors.valueRequired') as string)
      .lessThan(Yup.ref('timeTo'), t('editors.valueToIsSmaller') as string),
    timeTo: Yup.number()
      .transform((value, originalValue) =>
        getHoursFromHoursMinuteString(originalValue),
      )
      .required(t('editors.valueRequired') as string)
      .moreThan(Yup.ref('timeFrom'), t('editors.valueFromIsHigher') as string),
    weekDays: Yup.array().of(Yup.number().oneOf(weekDayNumbers)).optional(),
    daysInMonthFrom: Yup.number()
      .when(['daysInMonthTo'], {
        is: (val: number | undefined) => !!val,
        then: (schema) => schema.required(t('editors.valueRequired') as string),
        otherwise: (schema) => schema.optional(),
      })
      .min(1, t('editors.invalidDay') as string)
      .max(
        Yup.ref('daysInMonthTo'),
        t('editors.valueFromToIsSmaller') as string,
      ),

    daysInMonthTo: Yup.number()
      .min(
        Yup.ref('daysInMonthFrom'),
        t('editors.valueFromToIsSmaller') as string,
      )
      .max(31, t('editors.invalidDay') as string),
  });

  useEffect(() => {
    const values = getAllValues({});
    validateInput(values);
    onChangeHandler(values);
  }, [timeFrom, timeTo, weekDays, daysFrom, daysTo]);

  const validateInput = (validationObject: object): void => {
    void validateEditorInput(validationSchema, validationObject).then(
      ([, errors]) => {
        setValidationResult(errors);
      },
    );
  };

  const handleTimeFromChange = (value: Dayjs | null) => {
    if (!value) {
      return;
    }
    setTimeFrom(value);
  };

  const handleTimeToChange = (value: Dayjs | null) => {
    if (!value) {
      return;
    }
    setTimeTo(value);
  };

  const handleWeekDaySelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value },
    } = event;
    const days = typeof value === 'string' ? value.split(',') : value;
    setWeekDays(days);
  };

  const handleDayFromChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue =
      event.target.value === '' ? undefined : parseInt(event.target.value);
    setDaysFrom(parsedValue);
  };

  const handleDayToChange = (event: ChangeEvent<HTMLInputElement>) => {
    const parsedValue =
      event.target.value === '' ? undefined : parseInt(event.target.value);
    setDaysTo(parsedValue);
  };

  const showError = (fieldName: string): boolean =>
    !!validationResult[fieldName] && fieldsTouched[fieldName];

  const getError = (fieldName: string): string => {
    return showError(fieldName) ? validationResult[fieldName] : '';
  };

  return (
    <Stack direction={'column'} spacing={2} sx={{ width: 1 }}>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          width: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: 1 / 2 }}>
          {t('leadConstraints.timeWindowEditor.timeFrom')}
        </Box>
        <Tooltip title={getError('timeTo')}>
          <Stack sx={{ width: 1 / 2 }}>
            <MobileTimePicker
              value={timeFrom}
              ampm={false}
              closeOnSelect={true}
              onAccept={handleTimeFromChange}
              format="HH:mm"
              views={['hours']}
              slotProps={{
                textField: {
                  error: showError('timeFrom'),
                  inputProps: {
                    'data-cy': 'timeFrom',
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {showError('timeFrom') ? (
                          <AnnouncementSharpIcon color="error" />
                        ) : (
                          <Icon />
                        )}
                      </InputAdornment>
                    ),
                  },
                },
              }}
              onOpen={() =>
                setFieldsTouched({
                  ...fieldsTouched,
                  timeFrom: true,
                })
              }
            />
          </Stack>
        </Tooltip>
      </Stack>
      <Stack
        direction={'row'}
        spacing={2}
        sx={{
          width: 1,
          alignItems: 'center',
        }}
      >
        <Box sx={{ width: 1 / 2 }}>
          {t('leadConstraints.timeWindowEditor.timeTo')}
        </Box>

        <Tooltip title={getError('timeTo')}>
          <Stack sx={{ width: 1 / 2 }}>
            <MobileTimePicker
              ampm={false}
              value={timeTo}
              closeOnSelect={true}
              views={['hours']}
              format="HH:mm"
              onAccept={handleTimeToChange}
              slotProps={{
                textField: {
                  error: showError('timeTo'),
                  inputProps: {
                    'data-cy': 'timeTo',
                  },
                  InputProps: {
                    endAdornment: (
                      <InputAdornment position="end">
                        {showError('timeTo') ? (
                          <AnnouncementSharpIcon color="error" />
                        ) : (
                          <Icon />
                        )}
                      </InputAdornment>
                    ),
                  },
                },
              }}
              onOpen={() =>
                setFieldsTouched({
                  ...fieldsTouched,
                  timeTo: true,
                })
              }
            />
          </Stack>
        </Tooltip>
      </Stack>

      <Tooltip title={getError('weekDays') && validationResult['weekDays']}>
        <Stack
          direction="row"
          spacing={2}
          sx={{ width: 1, alignItems: 'baseline' }}
        >
          <Box sx={{ width: 1 / 2 }}>
            {t('leadConstraints.timeWindowEditor.days')}
          </Box>
          <Select
            data-cy="weekDays"
            sx={{ width: 1 / 2 }}
            multiple
            value={weekDays}
            onChange={handleWeekDaySelectChange}
            error={showError('weekDays')}
            onBlur={() =>
              setFieldsTouched({
                ...fieldsTouched,
                weekDays: true,
              })
            }
          >
            {weekDayNumbers.map((day) => (
              <MenuItem key={day} value={day} data-cy="weekDaysItem">
                {t(`leadConstraints.timeWindowEditor.dayNums.${day}`)}
              </MenuItem>
            ))}
          </Select>
        </Stack>
      </Tooltip>
      <Stack
        direction={'column'}
        spacing={2}
        sx={{
          width: 1,
          alignItems: 'baseline',
        }}
      >
        <Box>{t('leadConstraints.timeWindowEditor.daysInMonth')}</Box>
        <Stack
          direction={'row'}
          spacing={2}
          sx={{
            width: 1,
            alignItems: 'baseline',
          }}
        >
          <Tooltip title={getError('daysInMonthFrom')}>
            <TextField
              sx={{ flex: 1 }}
              fullWidth={true}
              label={t(`leadConstraints.timeWindowEditor.from`)}
              type="number"
              inputProps={{
                min: '1',
                max: '31',
                step: '1',
                'data-cy': 'daysInMonthFrom',
              }}
              value={daysFrom ?? ''}
              onChange={handleDayFromChange}
              error={showError('daysInMonthFrom')}
              onBlur={() =>
                setFieldsTouched({
                  ...fieldsTouched,
                  daysInMonthFrom: true,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showError('daysInMonthFrom') && (
                      <AnnouncementSharpIcon color="error" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
          <Tooltip title={getError('daysInMonthTo')}>
            <TextField
              sx={{ flex: 1 }}
              label={t(`leadConstraints.timeWindowEditor.to`)}
              type="number"
              inputProps={{
                min: '1',
                max: '31',
                step: '1',
                'data-cy': 'daysInMonthTo',
              }}
              value={daysTo ?? ''}
              onChange={handleDayToChange}
              error={showError('daysInMonthTo')}
              onBlur={() =>
                setFieldsTouched({
                  ...fieldsTouched,
                  daysInMonthTo: true,
                })
              }
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {showError('daysInMonthTo') && (
                      <AnnouncementSharpIcon color="error" />
                    )}
                  </InputAdornment>
                ),
              }}
            />
          </Tooltip>
        </Stack>
      </Stack>
    </Stack>
  );
};
