import { BudgetDto } from '../../_generatedApi';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';

export interface BudgetState {
  budgets: BudgetDto[];
}

const initialState: BudgetState = {
  budgets: [],
};

export const budgetSlice = createSlice({
  name: 'budget',
  initialState,
  reducers: {
    setBudgets: (state, action: PayloadAction<BudgetDto[]>) => {
      state.budgets = action.payload;
    },
    removeBudget: (state, action: PayloadAction<string>) => {
      state.budgets = state.budgets.filter(
        (budget) => budget.id !== action.payload,
      );
    },
  },
});

export const { setBudgets, removeBudget } = budgetSlice.actions;

export default budgetSlice.reducer;
