/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CheckDto } from './CheckDto';
import type { ConditionGroupDto } from './ConditionGroupDto';

export type UpdateLeadConstraintDto = {
  displayName: string;
  preflightChecks?: Array<CheckDto>;
  registerChecks?: Array<CheckDto>;
  mainConditionGroup?: ConditionGroupDto;
  groupRelation: UpdateLeadConstraintDto.groupRelation;
  conditionGroups: Array<ConditionGroupDto>;
};

export namespace UpdateLeadConstraintDto {
  export enum groupRelation {
    AND = 'AND',
    OR = 'OR',
  }
}
