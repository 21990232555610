import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

type SelectRendererProps = {
  type: string;
  selected: number;
};

export const SelectRenderer = ({ selected, type }: SelectRendererProps) => {
  const { t } = useTranslation();
  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      <b>{t(`leadConstraints.${type}.selectOptions.${selected}`)}</b>
    </Box>
  );
};
