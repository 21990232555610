import { FormControl, FormControlLabel, MenuItem, Stack } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import { Select, Switch } from 'formik-mui';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CommunicationChannelDto, KeyValueDto } from '../../_generatedApi';
import { CommunicationChannelValues } from '../../pages/CommunicationChannels';
import { TextField } from '../form/FormikTextField';
import { Token } from './Token';

interface ICommunicationChannelModal {
  channelValues: CommunicationChannelValues | undefined;
  canActivateChannel: boolean;
  affiliates: KeyValueDto[];
  leadConstraints: KeyValueDto[];
}
export const CommunicationChannelsForm: FC<ICommunicationChannelModal> = ({
  affiliates,
  leadConstraints,
  channelValues,
  canActivateChannel,
}) => {
  const { t } = useTranslation();
  const channelsForm = useFormikContext();

  const isNewChannel = !channelValues?.id;

  const onChangeCode = (e: React.ChangeEvent<HTMLInputElement>) => {
    void channelsForm.setFieldValue('code', e.target.value.toUpperCase());
  };

  return (
    <>
      <Stack direction={'row'} spacing={2}>
        <Field
          inputProps={{
            'data-cy': `communicationChannelName`,
          }}
          component={TextField}
          name="displayName"
          label={t('communicationChannels.displayName')}
          required={true}
          sx={{ width: 2 / 3 }}
        />
        <Field
          inputProps={{
            'data-cy': `communicationChannelCode`,
          }}
          component={TextField}
          name="code"
          label={t('communicationChannels.code')}
          required={true}
          disabled={!isNewChannel}
          onChange={onChangeCode}
          sx={{ width: 1 / 3 }}
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <FormControl sx={{ width: 2 / 3 }}>
          <Field
            component={Select}
            name="affiliatePartnerId"
            label={t('communicationChannels.affiliatePartner.name')}
            required={true}
            disabled={!isNewChannel}
            data-cy="selectAffiliatePartner"
          >
            {affiliates.map((affiliate) => {
              return (
                <MenuItem
                  key={affiliate.id}
                  value={affiliate.id}
                  data-cy="affiliatePartnerItem"
                >
                  {affiliate.name}
                </MenuItem>
              );
            })}
          </Field>
        </FormControl>
        <FormControl sx={{ width: 1 / 3 }}>
          <Field
            component={Select}
            name="type"
            label={t('communicationChannels.type')}
            required={true}
            disabled={!isNewChannel}
            data-cy="selectCommunicationType"
          >
            <MenuItem
              value={CommunicationChannelDto.type.CPL}
              data-cy="communicationTypeItem"
            >
              {CommunicationChannelDto.type.CPL}
            </MenuItem>
            <MenuItem
              value={CommunicationChannelDto.type.CPS}
              data-cy="communicationTypeItem"
            >
              {CommunicationChannelDto.type.CPS}
            </MenuItem>
          </Field>
        </FormControl>
      </Stack>

      <Stack direction={'row'} spacing={2}>
        <FormControl sx={{ width: 2 / 3 }}>
          <Field
            component={Select}
            name="leadConstraintId"
            label={t('communicationChannels.leadConstraint.name')}
            required={true}
            data-cy="selectLeadConstraint"
          >
            {leadConstraints.map((lc) => (
              <MenuItem key={lc.id} value={lc.id} data-cy="leadConstraintItem">
                {lc.name}
              </MenuItem>
            ))}
          </Field>
        </FormControl>
        <FormControlLabel
          control={
            <Field
              inputProps={{
                'data-cy': `communicationChannelActiveCheckbox`,
              }}
              component={Switch}
              type="checkbox"
              name="active"
              disabled={!canActivateChannel}
            />
          }
          label={t('communicationChannels.active')}
          title={
            canActivateChannel
              ? ''
              : (t('communicationChannels.deactivatedPartner') as string)
          }
          labelPlacement="end"
          sx={{ width: 1 / 3 }}
        />
      </Stack>
      <Stack spacing={2}>
        <Field
          inputProps={{
            'data-cy': `communicationChannelNote`,
          }}
          component={TextField}
          label={t('communicationChannels.note')}
          name="note"
          rows={5}
          multiline
        />
      </Stack>
      <Stack direction={'row'} spacing={2}>
        <Token name="token1" dataCy="token1" isNewChannel={isNewChannel} />
        <Token name="token2" dataCy="token2" isNewChannel={isNewChannel} />
      </Stack>
    </>
  );
};
