import { Alert, Box, Stack, Typography } from '@mui/material';
import { Field, useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import { CheckDto, LeadConstraintDetailDto } from '../../_generatedApi';
import { LeadConstraintsCheckColumn } from './LeadConstraintsCheckColumn';
import { TextField } from '../form/FormikTextField';
import { LeadConstraintsConditionsPlainDisplay } from './LeadConstraintsConditionsPlainDisplay';
import { GroupRelationWithLine } from './GroupRelationWithLine';

export interface LeadConstraintsFormProps {
  translationPrefix: string;
}

export const LeadConstraintsForm: FC<LeadConstraintsFormProps> = (props) => {
  const { t } = useTranslation();
  const { translationPrefix } = props;
  const { values, setFieldValue } = useFormikContext<LeadConstraintDetailDto>();

  const setPreflightChecks = (checks: CheckDto.type[]) => {
    const preflightChecks = checks.map((ch, index) => {
      return { type: ch, order: index };
    });
    void setFieldValue('preflightChecks', preflightChecks);
  };

  const setRegisterChecks = (checks: CheckDto.type[]) => {
    void setFieldValue(
      'registerChecks',
      checks.map((ch, index) => {
        return { type: ch, order: index };
      }),
    );
  };
  const getConditionGroups = () => {
    const groups = values.conditionGroups.map((cg, index) => {
      return (
        <Stack spacing={2} key={index} width="100%">
          <LeadConstraintsConditionsPlainDisplay
            isMain={false}
            conditionGroupIndex={index}
          />
        </Stack>
      );
    });

    if (values.conditionGroups.length === 0) {
      return null;
    }

    return (
      <Stack direction="row" spacing={2} width="100%">
        <GroupRelationWithLine relation={values.groupRelation} />

        <Stack direction="column" spacing={2} paddingTop={4} width="100%">
          {groups}
        </Stack>
      </Stack>
    );
  };

  const noConditions =
    values.conditionGroups.length === 0 &&
    values.mainConditionGroup?.conditions.length === 0;

  return (
    <>
      <Field
        component={TextField}
        name="displayName"
        label={t(`${translationPrefix}.displayName`)}
        required={true}
        inputProps={{
          'data-cy': `leadConstraintDialogName`,
        }}
      />
      <Typography variant="h6" component="h1" sx={{ paddingBottom: '5px' }}>
        {t(`${translationPrefix}.checks`)}
      </Typography>
      <Box>
        <Stack direction={'row'} spacing={2} sx={{ paddingLeft: '20px' }}>
          <LeadConstraintsCheckColumn
            title={t(`${translationPrefix}.preflightChecks`)}
            translationPrefix={translationPrefix}
            checks={values.preflightChecks?.map((pc) => pc.type) ?? []}
            setChecks={setPreflightChecks}
            dataCy="preflightChecksSelect"
          />
          <LeadConstraintsCheckColumn
            title={t(`${translationPrefix}.registerChecks`)}
            translationPrefix={translationPrefix}
            checks={values.registerChecks?.map((rc) => rc.type) ?? []}
            setChecks={setRegisterChecks}
            dataCy="signupChecksSelect"
          />
        </Stack>
      </Box>
      <Typography variant="h6" component="h1">
        {t(`${translationPrefix}.conditions`)}
      </Typography>
      {noConditions ? (
        <Alert severity="info">{t(`${translationPrefix}.noConditions`)}</Alert>
      ) : (
        <Stack direction="row" spacing={2}>
          <GroupRelationWithLine
            relation={LeadConstraintDetailDto.groupRelation.AND}
          />
          <Stack direction="column" spacing={2} width="100%">
            <LeadConstraintsConditionsPlainDisplay isMain={true} />
            {getConditionGroups()}
          </Stack>
        </Stack>
      )}
    </>
  );
};
