/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiCollectionDataResponseOfBudgetDto } from '../models/ApiCollectionDataResponseOfBudgetDto';
import type { ApiDataResponseOfBudgetDetailDto } from '../models/ApiDataResponseOfBudgetDetailDto';
import type { ApiResponseDto } from '../models/ApiResponseDto';
import type { CopyBudgetDto } from '../models/CopyBudgetDto';
import type { CreateBudgetDto } from '../models/CreateBudgetDto';
import type { UpdateBudgetDto } from '../models/UpdateBudgetDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class BudgetsService {
  /**
   * @returns ApiCollectionDataResponseOfBudgetDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findAll(): CancelablePromise<ApiCollectionDataResponseOfBudgetDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/budgets',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.<br>
   * + **Invalid Interval** - Property 'validTo' is lower than 'validFrom'.<br>
   * + **Intersecting intervals** - New valid interval is intersecting with other budgets.
   * @returns ApiDataResponseOfBudgetDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static create({
    requestBody,
  }: {
    requestBody: CreateBudgetDto;
  }): CancelablePromise<ApiDataResponseOfBudgetDetailDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/budgets',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfBudgetDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static findOne({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiDataResponseOfBudgetDetailDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/budgets/{id}',
      path: {
        id: id,
      },
      errors: {
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.<br>
   * + **Invalid Interval** - Property 'validTo' is lower than 'validFrom'.<br>
   * + **Intersecting intervals** - New valid interval is intersecting with other budgets.
   * @returns ApiDataResponseOfBudgetDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static update({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: UpdateBudgetDto;
  }): CancelablePromise<ApiDataResponseOfBudgetDetailDto> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/api/v1/budgets/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiResponseDto Action was successful.
   * @throws ApiError
   */
  public static remove({
    id,
  }: {
    id: string;
  }): CancelablePromise<ApiResponseDto> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/api/v1/budgets/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.<br>
   * + **Invalid Interval** - Property 'validTo' is lower than 'validFrom'.<br>
   * + **Intersecting intervals** - New valid interval is intersecting with other budgets.
   * @returns ApiDataResponseOfBudgetDetailDto Data successfully retrieved.
   * @throws ApiError
   */
  public static copy({
    id,
    requestBody,
  }: {
    id: string;
    requestBody: CopyBudgetDto;
  }): CancelablePromise<ApiDataResponseOfBudgetDetailDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/budgets/{id}/copy',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        401: `User was not authenticated.`,
        404: `Resource was not found.`,
        500: `Something went wrong.`,
      },
    });
  }
}
