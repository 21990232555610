import {
  ColDef,
  ColGroupDef,
} from '@ag-grid-community/core/dist/cjs/es5/entities/colDef';

const isString = (x: unknown): x is string => typeof x === 'string';
const isColDefWithField = (
  colDef: ColDef | ColGroupDef | null | undefined,
): colDef is ColDef => true;

export { isString, isColDefWithField };
