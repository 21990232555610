/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { PostbackParamDto } from './PostbackParamDto';

export type PostbackDto = {
  notify: boolean;
  url: string;
  action: PostbackDto.action;
  postbackParams: Array<PostbackParamDto>;
  monitoredStates: Array<
    'LEAD_ACQUIRED' | 'LOAN_REJECTED' | 'FULL_APPLICATION' | 'LOAN_ISSUED'
  >;
};

export namespace PostbackDto {
  export enum action {
    POST = 'POST',
    GET = 'GET',
  }
}
