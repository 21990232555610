import { FormikProvider, useFormik } from 'formik';
import React, { FC, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import {
  AffiliatePartnersService,
  CommunicationChannelDto,
  CommunicationChannelsService,
  CreateCommunicationChannelDto,
  KeyValueDto,
  UpdateCommunicationChannelDto,
} from '../../_generatedApi';
import { useCommunicationsChannel } from '../../hooks/useCommunicationsChannel';
import { setChannels } from '../../store/communication-channels/channelSlice';
import parseBusinessApiError from '../../utils/parse-business-api-error';
import { getDefaultToken } from '../../utils/token';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import { CommunicationChannelsForm } from './CommunicationChannelsForm';
import type = CommunicationChannelDto.type;
import AlertDialog from '../AlertDialog';
import { setSnackbarOpen } from '../../store/common/snackbarSlice';

interface ICommunicationChannelModal {
  id?: string;
  open: boolean;
  setOpenModal: (open: boolean) => void;
  affiliates: KeyValueDto[];
  leadConstraints: KeyValueDto[];
}

const CommunicationChannelsModal: FC<ICommunicationChannelModal> = ({
  setOpenModal,
  open,
  affiliates,
  leadConstraints,
  id,
}) => {
  const channelValues = useCommunicationsChannel(id);
  const [canActivateChannel, setCanActivateChannel] = useState(false);
  const [tokenChangeDialog, setTokenChangeDialog] = useState(false);
  const [token1] = useState(id ? '' : getDefaultToken());
  const [token2] = useState(id ? '' : getDefaultToken());

  const dispatch = useDispatch();
  const { t } = useTranslation();
  const isNewChannel = !id;

  const onSubmit = (values: CreateCommunicationChannelDto) => {
    // TODO: refactor this
    if (isNewChannel) {
      if (values?.displayName) {
        const communicationChannel: CreateCommunicationChannelDto = {
          displayName: values.displayName,
          code: values.code as string,
          affiliatePartnerId: values.affiliatePartnerId,
          leadConstraintId: values.leadConstraintId,
          type: values.type as CommunicationChannelDto.type,
          active: values.active as boolean,
          note: values.note,
          token1: values.token1 as string,
          token2: values.token2 as string,
          postbackEnabled: false,
        };

        void CommunicationChannelsService.create({
          requestBody: communicationChannel,
        })
          .then(() => {
            void CommunicationChannelsService.findAll().then((result) => {
              dispatch(setChannels(result.data));
              dispatch(
                setSnackbarOpen({
                  message: 'communicationChannels.response.create.Ok',
                  severity: 'success',
                }),
              );
              void handleClose();
            });
          })
          .catch((error) => {
            dispatch(
              setSnackbarOpen({
                message: `communicationChannels.response.create.${parseBusinessApiError(
                  error,
                )}`,
                severity: 'error',
              }),
            );
          });
      }
    } else {
      if (
        values.token1 !== channelValues.data?.token1 ||
        values.token2 !== channelValues.data?.token2
      ) {
        setTokenChangeDialog(true);
      } else {
        void saveBudget(values);
      }
    }
  };
  const saveBudget = async (values: CreateCommunicationChannelDto) => {
    const { id } = values as unknown as CommunicationChannelDto;

    const communicationChannel: UpdateCommunicationChannelDto = {
      displayName: values.displayName as string,
      leadConstraintId: values.leadConstraintId,
      active: values.active as boolean,
      note: values.note,
      token1: values.token1 as string,
      token2: values.token2 as string,
    };

    void CommunicationChannelsService.update({
      id,
      requestBody: communicationChannel,
    })
      .then(() => {
        void CommunicationChannelsService.findAll().then((result) => {
          dispatch(setChannels(result.data));
          dispatch(
            setSnackbarOpen({
              message: 'communicationChannels.response.update.Ok',
              severity: 'success',
            }),
          );
          void handleClose();
        });
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `communicationChannels.response.update.${parseBusinessApiError(
              error,
            )}`,
            severity: 'error',
          }),
        );
      });
  };

  const validationSchema = object({
    displayName: string()
      .max(50, t('communicationChannels.modal.nameTooLong') as string)
      .required(t('communicationChannels.modal.nameRequired') as string),
    code: string()
      .max(15, t('communicationChannels.modal.codeTooLong') as string)
      .required(t('communicationChannels.modal.codeRequired') as string)
      .matches(
        /^[A-Z0-9_-]*$/,
        t('communicationChannels.modal.codeCharacters') as string,
      ),
    note: string()
      .max(120, t('communicationChannels.modal.noteTooLong') as string)
      .nullable(),
  });

  const initialValues: CreateCommunicationChannelDto = {
    displayName: '',
    active: false,
    note: '',
    type: type.CPL,
    code: '',
    token1,
    token2,
    affiliatePartnerId: channelValues?.data?.affiliatePartner?.id
      ? channelValues.data.affiliatePartner.id
      : affiliates[0]?.id,
    leadConstraintId: channelValues?.data?.leadConstraint?.id
      ? channelValues.data.leadConstraint.id
      : leadConstraints[0]?.id,
    postbackEnabled: false,
  };

  const channelsForm = useFormik({
    initialValues: { ...initialValues, ...channelValues?.data },
    onSubmit,
    validationSchema,
    enableReinitialize: true,
  });

  const acceptTokenChange = () => {
    void saveBudget(channelsForm.values);
    channelsForm.setSubmitting(false);
    setTokenChangeDialog(false);
  };

  const getAffiliatePartnerById = useCallback(async () => {
    const { affiliatePartnerId } = channelsForm.values;

    if (!affiliatePartnerId) {
      return false;
    }

    const response = await AffiliatePartnersService.findOne({
      id: affiliatePartnerId,
    });

    return response.data.active;
  }, [channelsForm.values.affiliatePartnerId]);

  useEffect(() => {
    void getAffiliatePartnerById().then((active) => {
      setCanActivateChannel(active);
    });
  }, [channelsForm.values.affiliatePartnerId]);

  const handleClose = async () => {
    setOpenModal(false);
  };

  const handleCancelTokenDialog = () => {
    setTokenChangeDialog(false);
    channelsForm.setSubmitting(false);
  };

  if (channelValues.loading && !isNewChannel) {
    return null;
  }

  return (
    <FormikProvider value={channelsForm}>
      <AlertDialog
        state={tokenChangeDialog}
        stateCallback={handleCancelTokenDialog}
        buttonAction={acceptTokenChange}
        title={t('communicationChannels.modal.editCommunicationChannels')}
        message={t('communicationChannels.modal.tokenChanged')}
        buttonText={t('accept')}
      />
      <CommonModal
        dataCy={'createEditDialog'}
        open={open}
        onClose={handleClose}
        title={
          isNewChannel
            ? t('communicationChannels.modal.newCommunicationChannels')
            : t('communicationChannels.modal.editCommunicationChannels')
        }
        content={
          <CommunicationChannelsForm
            affiliates={affiliates}
            leadConstraints={leadConstraints}
            canActivateChannel={canActivateChannel}
            channelValues={channelValues?.data}
          />
        }
        isNew={isNewChannel}
        footer={
          <ModalFooter
            isNew={isNewChannel}
            onSubmit={channelsForm.submitForm}
            onClose={handleClose}
          />
        }
        modalWidth={'700px'}
      />
    </FormikProvider>
  );
};

export default CommunicationChannelsModal;
