import { Stack, Tooltip, Typography } from '@mui/material';
import { Field, useField } from 'formik';
import IconButton from '@mui/material/IconButton';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import SyncIcon from '@mui/icons-material/Sync';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { getDefaultToken } from '../../utils/token';
import Box from '@mui/material/Box';
import { setSnackbarOpen } from '../../store/common/snackbarSlice';
import { useDispatch } from 'react-redux';

type TokenType = 'token1' | 'token2';

interface IToken {
  name: TokenType;
  isNewChannel: boolean;
  dataCy?: string;
}

export const Token = ({ name, isNewChannel, dataCy }: IToken) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [{ value }, , helpers] = useField(name);
  const [censureToken, setCensureToken] = useState(!isNewChannel);
  const censuredToken = (tokenToCensure: string): string => {
    return tokenToCensure.slice(0, -16) + '****************';
  };

  const onCopy = (value: string) => {
    void navigator.clipboard.writeText(value);
    dispatch(
      setSnackbarOpen({
        message: 'communicationChannels.modal.tokenCopied',
        severity: 'info',
      }),
    );
  };

  const generateToken = () => {
    const newToken = getDefaultToken();
    void helpers.setValue(newToken);
    void helpers.setTouched(true);
    setCensureToken(false);
    dispatch(
      setSnackbarOpen({
        message: 'communicationChannels.modal.tokenRefreshed',
        severity: 'info',
      }),
    );
  };

  return (
    <Stack
      spacing={1}
      sx={{ width: 1 / 2, backgroundColor: 'background.paper', p: 1 }}
      data-cy={dataCy}
    >
      <Typography>{t(`communicationChannels.modal.${name}`)}</Typography>
      <Stack direction={'row'} justifyContent="space-between" sx={{}}>
        <Box>
          <Field type="hidden" name={name} required={true} />
          <Typography variant="caption" sx={{ width: 2 / 3 }}>
            {censureToken ? censuredToken(value) : value}
          </Typography>
        </Box>
        <Box>
          <Tooltip title={t('communicationChannels.modal.tokenCopyTooltip')}>
            <IconButton
              size="small"
              edge="start"
              color="primary"
              onClick={() => onCopy(value)}
              data-cy="copyButton"
            >
              <ContentCopyIcon />
            </IconButton>
          </Tooltip>

          <Tooltip title={t('communicationChannels.modal.tokenRefreshTooltip')}>
            <IconButton
              size="small"
              edge="start"
              color="primary"
              onClick={() => generateToken()}
              data-cy="generateButton"
            >
              <SyncIcon />
            </IconButton>
          </Tooltip>
        </Box>
      </Stack>
    </Stack>
  );
};
