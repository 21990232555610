export enum Languages {
  CS = 'cs',
  EN = 'en',
}

export const USER_LANGUAGE = 'lang';

export const getLanguage = (): string => {
  const tokenFromStorage = localStorage.getItem(USER_LANGUAGE);
  return tokenFromStorage ? tokenFromStorage : Languages.CS;
};
