import { ICellRendererParams } from '@ag-grid-community/core';
import Checkbox from '@mui/material/Checkbox';
import React from 'react';

const GridCheckbox = (props: ICellRendererParams) => {
  const checkedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const colId = props.column?.getColId();
    if (colId) {
      props.node.setDataValue(colId, checked);
    }
  };

  return (
    <Checkbox
      size="small"
      onChange={checkedHandler}
      checked={props.value}
      disabled
    />
  );
};

export default GridCheckbox;
