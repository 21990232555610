import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import React from 'react';
const PageNotFound = () => {
  return (
    <Box>
      <Typography variant="h1" sx={{ textAlign: 'center' }}>
        Error 404
      </Typography>
      <Typography variant="h2" sx={{ textAlign: 'center' }}>
        Page not found
      </Typography>
    </Box>
  );
};

export default PageNotFound;
