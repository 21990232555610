/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ApiDataResponseOfLoginResponseDto } from '../models/ApiDataResponseOfLoginResponseDto';
import type { ApiDataResponseOfUserDto } from '../models/ApiDataResponseOfUserDto';
import type { LoginDto } from '../models/LoginDto';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class AuthService {
  /**
   * ### Error names for HTTP Status 400 Bad Request:<br>
   * + **Bad Request** - Validation failed. See 'messages' for further details.<br>
   * + **Invalid Credentials** - Email or password is invalid.
   * @returns ApiDataResponseOfLoginResponseDto Data successfully retrieved.
   * @throws ApiError
   */
  public static login({
    requestBody,
  }: {
    requestBody: LoginDto;
  }): CancelablePromise<ApiDataResponseOfLoginResponseDto> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/api/v1/auth/login',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Validation failed.`,
        500: `Something went wrong.`,
      },
    });
  }

  /**
   * @returns ApiDataResponseOfUserDto Data successfully retrieved.
   * @throws ApiError
   */
  public static getMe(): CancelablePromise<ApiDataResponseOfUserDto> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/api/v1/auth/me',
      errors: {
        401: `User was not authenticated.`,
        500: `Something went wrong.`,
      },
    });
  }
}
