import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConditionDto, LeadConstraintDetailDto } from '../../_generatedApi';
import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { conditionList, getEditor } from './functions';
import { IModalConditionsValues } from './LeadConstraintsConditionsTable';
import { useFormikContext } from 'formik';

interface ILeadConstraintConditionsModal {
  open: boolean;
  leadConstraintId?: string;
  setOpenModal: (open: boolean) => void;
  isNewCondition: boolean;
  conditionGroupIndex?: number;
  value?: IModalConditionsValues;
}
const TRANSLATION_PREFIX = 'leadConstraints';

export const LeadConstrainsConditionsModal = ({
  open,
  setOpenModal,
  isNewCondition,
  conditionGroupIndex,
  value,
}: ILeadConstraintConditionsModal): React.JSX.Element => {
  const { t } = useTranslation();
  const [selectedType, setSelectedType] = useState(value?.params?.type);
  const [conditions, setConditions] = useState(value?.params.conditionValues);
  const form = useFormikContext<LeadConstraintDetailDto>();

  const [validationResult, setValidationResult] = useState<
    Record<string, string>
  >({});
  const [fieldsTouched, setFieldsTouched] = useState<Record<string, boolean>>(
    {},
  );

  // Short style with ! doesn't work because !0 is true
  const isMainGroup = typeof conditionGroupIndex === 'undefined';
  const handleClose = () => {
    setOpenModal(false);
  };

  const handleChange = (event: SelectChangeEvent) => {
    setConditions(undefined);
    setSelectedType(event.target.value as ConditionDto.type);
  };

  const onSubmitHandler = async () => {
    if (Object.keys(validationResult).length > 0) {
      setFieldsTouched(
        Object.keys(fieldsTouched).reduce(
          (fields, key) => {
            fields[key] = true;
            return fields;
          },
          {} as Record<string, boolean>,
        ),
      );
      return;
    }

    if (!validationResult) {
      return;
    }

    const fieldName = isMainGroup
      ? 'mainConditionGroup.conditions'
      : `conditionGroups[${conditionGroupIndex}].conditions`;

    if (isNewCondition) {
      await form.setFieldValue(fieldName, [
        ...(isMainGroup
          ? form.values.mainConditionGroup?.conditions || []
          : form.values.conditionGroups[conditionGroupIndex].conditions),
        {
          conditionValues: conditions as ConditionDto,
          type: selectedType as ConditionDto.type,
        },
      ]);
    } else {
      if (value?.rowIndex || value?.rowIndex === 0) {
        await form.setFieldValue(`${fieldName}.[${value.rowIndex}]`, {
          conditionValues: conditions as ConditionDto,
          type: selectedType as ConditionDto.type,
        });
      }
    }
    handleClose();
  };
  const onChangeHandler = (value: ConditionDto['conditionValues']) =>
    setConditions(value);

  const component = (
    <>
      <Select
        onChange={handleChange}
        defaultValue={selectedType}
        disabled={!isNewCondition}
        data-cy="selectParameterType"
      >
        {conditionList.map((condition) => (
          <MenuItem
            value={condition}
            key={condition}
            data-cy="selectParameterTypeItem"
          >
            {t(`${TRANSLATION_PREFIX}.conditionTypes.${condition}`)}
          </MenuItem>
        ))}
      </Select>

      {selectedType &&
        getEditor({
          type: selectedType,
          params: conditions,
          onChangeHandler,
          validationResult,
          setValidationResult,
          fieldsTouched,
          setFieldsTouched,
        })}
    </>
  );

  const title = isNewCondition
    ? t(`${TRANSLATION_PREFIX}.createLeadConstraintParameter`)
    : t(`${TRANSLATION_PREFIX}.updateLeadConstraintParameter`);

  return (
    <CommonModal
      open={open}
      onClose={handleClose}
      title={title}
      content={component}
      isNew={isNewCondition}
      footer={
        <ModalFooter
          disableSubmit={!selectedType}
          isNew={isNewCondition}
          onSubmit={onSubmitHandler}
          onClose={handleClose}
        />
      }
      modalWidth={'700px'}
      dataCy="createEditDialog"
    />
  );
};
