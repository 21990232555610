import { configureStore } from '@reduxjs/toolkit';
import affiliateReducer from './affiliate/affiliateSlice';
import budgetReducer from './budget/budgetSlice';
import channelReducer from './communication-channels/channelSlice';
import leadConstraintReducer from './lead-constraints/leadConstraintSlice';
import userReducer from './user/userSlice';
import snackbarReducer from './common/snackbarSlice';

export const store = configureStore({
  reducer: {
    user: userReducer,
    affiliate: affiliateReducer,
    channel: channelReducer,
    budget: budgetReducer,
    leadConstraint: leadConstraintReducer,
    snackbar: snackbarReducer,
  },
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
