import { FormControlLabel, Stack, Tooltip } from '@mui/material';
import { Field } from 'formik';
import { Switch } from 'formik-mui';
import React, { SyntheticEvent } from 'react';
import { useTranslation } from 'react-i18next';
import { TextField } from '../form/FormikTextField';
import Box from '@mui/material/Box';

interface IAffiliatePartnersFormProps {
  onActiveChange: (
    event: SyntheticEvent<Element, Event>,
    checked: boolean,
  ) => void;
}

export const AffiliatePartnersForm = (props: IAffiliatePartnersFormProps) => {
  const { t } = useTranslation();
  const onActiveChange = props.onActiveChange;

  return (
    <Stack spacing={2}>
      <Stack spacing={2} direction="row">
        <Field
          sx={{ flexGrow: 2 }}
          inputProps={{
            'data-cy': `affiliatePartnerDialogName`,
          }}
          component={TextField}
          name="displayName"
          label={t('affiliatePartners.displayName')}
          required={true}
        />
        <Box>
          <Tooltip title={t('affiliatePartners.activeInfoText')}>
            <FormControlLabel
              control={
                <Field
                  inputProps={{
                    'data-cy': `affiliatePartnerActiveCheckbox`,
                  }}
                  component={Switch}
                  type="checkbox"
                  name="active"
                />
              }
              onChange={onActiveChange}
              label={t('affiliatePartners.active')}
              labelPlacement="end"
              sx={{ pb: '16px', height: 1 }}
            />
          </Tooltip>
        </Box>
      </Stack>
      <Box>
        <Field
          inputProps={{
            'data-cy': `affiliatePartnerDialogNote`,
          }}
          sx={{ width: '100%' }}
          component={TextField}
          label={t('affiliatePartners.note')}
          name="note"
          rows={5}
          multiline
        />
      </Box>
    </Stack>
  );
};
