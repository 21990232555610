import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import {
  CellValueChangedEvent,
  ICellRendererParams,
  Module,
  RowClassParams,
  RowEditingStoppedEvent,
  RowStyle,
  ValueFormatterParams,
} from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-alpine.css';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import FilterAltOffIcon from '@mui/icons-material/FilterAltOff';

import {
  Box,
  Button,
  MenuItem,
  Pagination,
  Select,
  SelectChangeEvent,
  Stack,
  Tooltip,
} from '@mui/material';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { RootState } from '../../store/store';
import { booleanFilter, dateFilter } from './custom-filters';

import './grid.css';
import GridZeroValueWarning from './GridZeroValueWarning';
import { getParamError } from './helpers';
import {
  GridActions,
  GridActive,
  GridCheckbox,
  GridSwitch,
  GridColumnType,
  IGridFilter,
  IGridTable,
} from './index';

import { AG_GRID_LOCALES } from './translation';
import { DebouncedBackdropLoader } from '../common';
import { GridColDef } from './types';

export const SWITCH_RENDERER = 'switchRenderer';
export const ACTIVE_RENDERER = 'activeRenderer';
export const ACTIONS_RENDERER = 'gridActions';
const SELECT_RENDERER = 'agSelectCellRenderer';
const DEFAULT_PAGINATE_SIZE = 25;

export const ZERO_VALUE_WARNING_RENDERER = 'zeroValueWarningRenderer';

export const GridTable = ({
  data,
  onGridReady,
  onCreate,
  onRowEditingStopped,
  onCellValueChanged,
  defaultSort,
  translationPrefix,
  colDefs,
  errors,
  actions,
  additionalActions,
  inlineEdit,
  hideResetFilter,
  gridOptionsOverride,
  enablePagination = true,
  usePinnedTopRow = false,
  suppressNoRowsOverlay = false,
  loading,
}: IGridTable) => {
  //data
  const modules: Module[] = [ClientSideRowModelModule];

  //hooks
  const language = useSelector((state: RootState) => state.user.language);
  const { t } = useTranslation();
  const gridRef = useRef<AgGridReact>(null);
  const [pageCount, setPageCount] = useState(0);
  const [pageSize, setPageSize] = useState(DEFAULT_PAGINATE_SIZE);
  const [localeText, setLocaleText] = useState({});
  const [destroyed, setDestroyed] = useState(false);

  const defaultColDef = useMemo(() => {
    return {
      sortable: true,
      resizable: true,
      filter: true,
    };
  }, []);

  useEffect(() => {
    const locales: Record<string, string> = {};
    Object.entries(AG_GRID_LOCALES).forEach(([key, value]) => {
      locales[key] = t(value);
    });

    setLocaleText(locales);
    setDestroyed(true);
    setTimeout(() => setDestroyed(false));
  }, [language]);

  //functions
  const getFilterByType = (type?: GridColumnType): IGridFilter => {
    switch (type) {
      case GridColumnType.Number: {
        return {
          filter: 'agNumberColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
          },
        };
      }
      case GridColumnType.Boolean: {
        return {
          filter: 'agNumberColumnFilter',
          filterParams: booleanFilter,
        };
      }
      case GridColumnType.Date: {
        return {
          filter: 'agDateColumnFilter',
          filterParams: dateFilter,
        };
      }
      default: {
        return {
          filter: 'agTextColumnFilter',
          filterParams: {
            buttons: ['reset', 'apply'],
          },
        };
      }
    }
  };

  const formatNumber = ({ value }: ValueFormatterParams) =>
    Math.floor(value)
      .toString()
      .replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');

  const getColumnDefs = (): GridColDef[] => {
    const columnDefinitions = Object.entries(colDefs);
    const columns: GridColDef[] = columnDefinitions.map(([field, colDef]) => {
      const { fieldType, ...rest } = colDef;
      const cellRenderer =
        colDef.cellRenderer || getRenderer(fieldType, errors);
      const headerName =
        colDef.headerName || (t(`${translationPrefix}.${field}`) as string);
      const valueFormatter = colDef.valueFormatter
        ? colDef.valueFormatter
        : fieldType === GridColumnType.Number
        ? formatNumber
        : undefined;

      return {
        field,
        headerName,
        cellRenderer,
        flex: 1,
        ...(defaultSort && defaultSort.field === field
          ? { sort: defaultSort.direction }
          : {}),
        ...getFilterByType(colDef.fieldType),
        ...rest,
        editable: colDef.editable,
        cellEditor: colDef.cellEditor,
        cellEditorParams: colDef.cellEditorParams,
        cellEditorSelector: colDef.cellEditorSelector,
        valueFormatter,
        cellStyle: ({ rowIndex, colDef: { field } }) => {
          const error = getParamError({
            errors,
            rowIndex,
            field: field as string,
          });
          if (error) {
            return {
              borderColor: 'red',
              borderWidth: '1px',
            };
          }
          return {
            borderColor: 0,
            borderWidth: '0px',
          };
        },
      };
    });

    const actionCount = actions ? Object.keys(actions).length : 1;
    const actionsColumn: GridColDef = {
      field: 'actions',
      headerName: t('grid.actions') as string,
      cellRenderer: ACTIONS_RENDERER,
      cellRendererParams: {
        actions,
        additionalActions,
        translationPrefix,
      },
      width: actionCount * 40,
      minWidth: 100,
      headerClass: 'text-center',
      filter: false,
      sortable: false,
      resizable: false,
    };

    if (!actions || Object.keys(actions).length === 0) {
      return columns;
    }

    return [...columns, actionsColumn];
  };

  const getRenderer = (
    type?: GridColumnType,
    errors?: IGridTable['errors'],
  ): string | undefined | ((value: ICellRendererParams) => JSX.Element) => {
    if (type === GridColumnType.Boolean) {
      return SWITCH_RENDERER;
    }

    if (type === GridColumnType.Select) {
      return SELECT_RENDERER;
    }

    const defaultRenderer = ({
      value,
      rowIndex,
      colDef,
      valueFormatted,
    }: ICellRendererParams): JSX.Element => {
      if (!colDef?.field) {
        return <div>{valueFormatted ?? value}</div>;
      }
      const error = getParamError({
        errors,
        rowIndex: rowIndex as number,
        field: colDef.field as string,
      });
      return (
        <Tooltip title={error} followCursor={true}>
          <Box sx={{ width: '100%', height: '100%' }}>
            {valueFormatted ?? value}
            {!!error && (
              <AnnouncementSharpIcon
                color="error"
                sx={{
                  position: 'absolute',
                  right: 0,
                  my: 'auto',
                  mx: 0.5,
                  height: '100%',
                }}
              />
            )}
          </Box>
        </Tooltip>
      );
    };

    return defaultRenderer;
  };

  //event handlers
  const onPaginationChanged = useCallback(() => {
    if (gridRef?.current?.api) {
      setPageCount(gridRef?.current?.api.paginationGetTotalPages());
    }
  }, []);

  const onPageSizeChange = (e: SelectChangeEvent) =>
    setPageSize(+e.target.value);

  const onPageChange = (event: React.ChangeEvent<unknown>, page: number) =>
    gridRef?.current?.api.paginationGoToPage(page - 1);

  const onResetFilter = () => {
    gridRef?.current?.api.setFilterModel(null);
    gridRef?.current?.api.onFilterChanged();
  };

  const rowEditingStopped = (event: RowEditingStoppedEvent<unknown>) => {
    if (!onRowEditingStopped) {
      return;
    }

    onRowEditingStopped(event);
  };

  const displayPagination = data.length > 10;

  const cellValueChanged = (
    e: CellValueChangedEvent<unknown, unknown>,
  ): void => {
    if (!onCellValueChanged) {
      return;
    }
    onCellValueChanged(e);
  };

  const popupParent = (document.querySelector('#modalBoxRoot') ??
    document.querySelector('body')) as HTMLElement;

  const getRowStyle = useCallback(
    ({ node }: RowClassParams): RowStyle =>
      node.rowPinned ? { fontWeight: 'bold', fontStyle: 'italic' } : {},
    [],
  );

  return destroyed ? null : (
    <Stack component="div" className="ag-theme-alpine" spacing={2}>
      <Stack spacing={2} direction="row" justifyContent="flex-end">
        {!hideResetFilter && (
          <Button
            variant="outlined"
            onClick={onResetFilter}
            data-cy="resetGridButton"
          >
            <FilterAltOffIcon sx={{ mr: 1 }} /> {t('grid.reset')}
          </Button>
        )}
        {!!onCreate && (
          <Button
            variant="contained"
            onClick={onCreate}
            data-cy="addButton"
            color="success"
          >
            <AddCircleIcon sx={{ mr: 1 }} /> {t('grid.newRow')}
          </Button>
        )}
      </Stack>
      <Box
        component="div"
        sx={{
          height: '100%',
          borderColor: 'secondary.light',
          borderWidth: 1,
          borderStyle: 'solid',
        }}
        data-cy="agGridTable"
      >
        {loading && <DebouncedBackdropLoader />}
        <AgGridReact
          domLayout="autoHeight"
          ref={gridRef}
          localeText={localeText}
          columnDefs={getColumnDefs()}
          rowData={data}
          modules={modules}
          defaultColDef={defaultColDef}
          tooltipShowDelay={0}
          stopEditingWhenCellsLoseFocus={true}
          components={{
            checkboxRenderer: GridCheckbox,
            gridActions: GridActions,
            switchRenderer: GridSwitch,
            activeRenderer: GridActive,
            zeroValueWarningRenderer: GridZeroValueWarning,
          }}
          pagination={enablePagination}
          paginationPageSize={pageSize}
          suppressPaginationPanel={true}
          onGridReady={onGridReady}
          onPaginationChanged={onPaginationChanged}
          suppressHorizontalScroll={false}
          editType={inlineEdit ? 'fullRow' : undefined}
          onRowEditingStopped={rowEditingStopped}
          onCellValueChanged={(e) => cellValueChanged(e)}
          singleClickEdit={!usePinnedTopRow}
          pinnedTopRowData={
            usePinnedTopRow
              ? [gridOptionsOverride?.pinnedTopRowData]
              : undefined
          }
          popupParent={popupParent}
          suppressNoRowsOverlay={usePinnedTopRow || suppressNoRowsOverlay}
          getRowStyle={getRowStyle}
          {...gridOptionsOverride}
        />
      </Box>
      {enablePagination && displayPagination && (
        <Box
          sx={{
            display: 'flex',
            mt: 1,
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <Pagination
            sx={{ margin: 'auto' }}
            count={pageCount}
            color="primary"
            onChange={onPageChange}
            shape="rounded"
          />
          <Box>
            <Select
              id="page-size-label"
              value={pageSize.toString()}
              onChange={onPageSizeChange}
              size="small"
            >
              <MenuItem value={10}>10</MenuItem>
              <MenuItem value={25}>25</MenuItem>
              <MenuItem value={50}>50</MenuItem>
              <MenuItem value={100}>100</MenuItem>
            </Select>
          </Box>
        </Box>
      )}
    </Stack>
  );
};
