import { CircularProgress } from '@mui/material';
import { FormikProvider, useFormik } from 'formik';
import React, { FC, useDeferredValue, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { object, string } from 'yup';
import {
  ApiError,
  ConditionGroupDto,
  CreateLeadConstraintDto,
  LeadConstraintDetailDto,
  LeadConstraintsService,
} from '../../_generatedApi';
import { setLeadConstraints } from '../../store/lead-constraints/leadConstraintSlice';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import { LeadConstraintsForm } from './LeadConstraintsForm';
import relation = ConditionGroupDto.relation;
import { setSnackbarOpen } from '../../store/common/snackbarSlice';

export enum LeadConstraintModalMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  COPY = 'COPY',
}

interface ILeadConstraintModal {
  mode: LeadConstraintModalMode;
  open: boolean;
  leadConstraintId?: string;
  setOpenModal: (open: boolean) => void;
}

const TRANSLATION_PREFIX = 'leadConstraints';

const LeadConstraintsModal: FC<ILeadConstraintModal> = (
  props: ILeadConstraintModal,
) => {
  const { mode } = props;

  const { open, leadConstraintId, setOpenModal } = props;
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const modeTitleMap = {
    [LeadConstraintModalMode.CREATE]: t(
      `${TRANSLATION_PREFIX}.createLeadConstraint`,
    ),
    [LeadConstraintModalMode.UPDATE]: t(
      `${TRANSLATION_PREFIX}.updateLeadConstraint`,
    ),
    [LeadConstraintModalMode.COPY]: t(
      `${TRANSLATION_PREFIX}.copyLeadConstraint`,
    ),
  };

  const [leadConstraintDetail, setLeadConstraintDetail] = useState<
    Partial<LeadConstraintDetailDto>
  >({
    displayName: '',
    conditionGroups: [],
    mainConditionGroup: { conditions: [], relation: relation.AND },
    groupRelation: LeadConstraintDetailDto.groupRelation.AND,
    preflightChecks: [],
    registerChecks: [],
  });
  const [detailLoaded, setDetailLoaded] = useState(false);
  const deferredDetailLoaded = useDeferredValue(detailLoaded);

  useEffect(() => {
    if (leadConstraintId) {
      void LeadConstraintsService.findOne({ id: leadConstraintId }).then(
        (res) => {
          setLeadConstraintDetail({
            id:
              mode !== LeadConstraintModalMode.COPY
                ? leadConstraintId
                : undefined,
            preflightChecks: res.data.preflightChecks,
            registerChecks: res.data.registerChecks,
            displayName:
              mode !== LeadConstraintModalMode.COPY
                ? res.data.displayName
                : (t(`modal.copyDisplayName`, {
                    displayName: res.data.displayName,
                  }) as string),
            groupRelation: res.data.groupRelation,
            mainConditionGroup: res.data.mainConditionGroup ?? {
              conditions: [],
              relation: relation.AND,
            },
            conditionGroups: res.data.conditionGroups,
          });
          setDetailLoaded(true);
        },
      );
    }
  }, [leadConstraintId]);

  const handleClose = async () => {
    setOpenModal(false);
  };

  const validationSchema = object({
    displayName: string()
      .max(
        50,
        t(`${TRANSLATION_PREFIX}.nameTooLong`, { maxLength: '50' }) as string,
      )
      .required(t(`${TRANSLATION_PREFIX}.nameRequired`) as string),
  });

  const isNewLeadConstraint = !leadConstraintId;
  const onSubmit = async (values: LeadConstraintDetailDto) => {
    if (
      [LeadConstraintModalMode.COPY, LeadConstraintModalMode.CREATE].includes(
        mode,
      )
    ) {
      await createNewLeadConstraint(values);
      setOpenModal(false);

      return;
    }

    await updateLeadConstraint(values);
    setOpenModal(false);
  };

  const createNewLeadConstraint = async (values: CreateLeadConstraintDto) => {
    try {
      await LeadConstraintsService.create({ requestBody: values });
      const result = await LeadConstraintsService.findAll();
      dispatch(setLeadConstraints(result.data));
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.create.Ok`,
          severity: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.create.${
            (error as ApiError).status
          }`,
          severity: 'error',
        }),
      );
    }
  };

  const updateLeadConstraint = async (values: LeadConstraintDetailDto) => {
    try {
      const { id, ...requestBody } = values;
      await LeadConstraintsService.update({ id, requestBody });
      const result = await LeadConstraintsService.findAll();
      dispatch(setLeadConstraints(result.data));
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.update.Ok`,
          severity: 'success',
        }),
      );
    } catch (error) {
      dispatch(
        setSnackbarOpen({
          message: `${TRANSLATION_PREFIX}.response.update.${
            (error as ApiError).status
          }`,
          severity: 'error',
        }),
      );
    }
  };

  const leadConstraintForm = useFormik({
    initialValues: leadConstraintDetail as LeadConstraintDetailDto,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  if (!deferredDetailLoaded && !isNewLeadConstraint)
    return <CircularProgress />;

  const modalWidth = 900;

  // I need to center this one manually as transform breaks the drag and drop library
  const modalStyle = {
    top: '5%',
    left: `calc(50% - ${modalWidth / 2}px)`,
    transform: undefined,
  };

  return (
    <FormikProvider value={leadConstraintForm}>
      <CommonModal
        dataCy={'createEditDialog'}
        style={modalStyle}
        open={open}
        onClose={handleClose}
        title={modeTitleMap[mode]}
        content={<LeadConstraintsForm translationPrefix={TRANSLATION_PREFIX} />}
        isNew={isNewLeadConstraint}
        footer={
          <ModalFooter
            modalMode={mode as string}
            isNew={isNewLeadConstraint}
            onSubmit={leadConstraintForm.submitForm}
            onClose={handleClose}
          />
        }
        modalWidth={`${modalWidth}px`}
      />
    </FormikProvider>
  );
};

export default LeadConstraintsModal;
