import { Warning } from '@mui/icons-material';
import { Box, List, ListItem } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { KeyValueDto } from '../../_generatedApi';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';

interface IDeactivationModalProps {
  open: boolean;
  channels: KeyValueDto[];
  onClose: () => void;
  onSubmit: () => void;
}

const AffiliatePartnerDeactivationModal = (props: IDeactivationModalProps) => {
  const { open, channels, onClose, onSubmit } = props;
  const { t } = useTranslation();

  const getListItems = () => {
    return channels.map((channel) => (
      <ListItem key={channel.id} sx={{ display: 'list-item' }}>
        {channel.name}
      </ListItem>
    ));
  };

  const getModalContent = () => {
    return (
      <Box>
        <Box component={'span'}>
          {t('affiliatePartners.deactivation.warning')}
        </Box>
        <List sx={{ listStyleType: 'disc', pl: 4 }}>{getListItems()}</List>
      </Box>
    );
  };

  return (
    <CommonModal
      open={open}
      onClose={onClose}
      title={t('affiliatePartners.deactivation.title')}
      titleIcon={<Warning color={'inherit'}></Warning>}
      content={getModalContent()}
      footer={<ModalFooter onSubmit={onSubmit} onClose={onClose} />}
      modalWidth={'600px'}
    />
  );
};

export default AffiliatePartnerDeactivationModal;
