import {
  CellValueChangedEvent,
  GridOptions,
  RowEditingStoppedEvent,
  SortDirection,
} from '@ag-grid-community/core';
import { ReactElement } from 'react';
import { ColDef } from '@ag-grid-community/core/dist/esm/es6/entities/colDef';

export enum GridColumnType {
  String = 'string',
  Boolean = 'boolean',
  Number = 'number',
  Date = 'date',
  Array = 'array',
  Select = 'select',
}

export interface IGridRowAction {
  onEdit?: (params: unknown, rowIndex?: number) => void;
  onPostback?: (params: unknown) => void;
  onCopy?: (params: unknown) => void;
  onDelete?: (params: unknown, rowIndex?: number) => void;
}

export interface IGridSort {
  field: string;
  direction: SortDirection;
}

export interface IGridFilter {
  filter: string | boolean;
  filterParams: unknown;
}

interface ICustomProps {
  fieldType?: GridColumnType;
  errorField?: string;
  flex?: number;
  type?: string;
}

export type GridColDef = ColDef & ICustomProps;

export type ColumnDefitions = Record<string, GridColDef>;

export interface IGridTable {
  data: unknown[];
  translationPrefix: string;
  onGridReady?: () => void;
  actions?: IGridRowAction;
  additionalActions?: (row: unknown) => ReactElement | ReactElement[];
  colDefs: ColumnDefitions;
  onCreate?: (data: unknown) => void;
  defaultSort?: IGridSort;
  hideResetFilter?: boolean;
  inlineEdit?: boolean;
  enablePagination?: boolean;
  onRowEditingStopped?: (event: RowEditingStoppedEvent<unknown>) => void;
  onCellValueChanged?: (event: CellValueChangedEvent<unknown, unknown>) => void;
  cellEditorParams?: {
    values: unknown[];
  };
  errors?: Record<string, string | Record<string, string>>;
  usePinnedTopRow?: boolean;
  gridOptionsOverride?: GridOptions;
  loading?: boolean;
  suppressNoRowsOverlay?: boolean;
}
