import {
  Box,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from './types';
import * as Yup from 'yup';
import { validateEditorInput } from './validate-editor-input';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

type SelectEditorProps = Editor & {
  options: Array<string | number>;
};

export const SelectEditor = ({
  options,
  params,
  type,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
}: SelectEditorProps) => {
  const { t } = useTranslation();
  const [selected, setSelected] = useState<string>(params?.value ?? options[0]);

  useEffect(() => {
    setFieldsTouched({
      value: false,
    });
  }, []);

  const getSchemaByOptionType = () => {
    const validationSchema = Yup.object();
    if (typeof options[0] === 'string') {
      validationSchema.shape({
        selected: Yup.string().oneOf(
          options as string[],
          t('editors.invalidSelection') as string,
        ),
      });
    }
    if (typeof options[0] === 'number') {
      validationSchema.shape({
        selected: Yup.number().oneOf(
          options as number[],
          t('editors.invalidSelection') as string,
        ),
      });
    }

    return validationSchema;
  };

  const validationSchema = getSchemaByOptionType();

  useEffect(() => {
    validateInput({
      selected,
    });
    onChangeHandler({ value: selected });
  }, [selected]);

  const validateInput = (validationObject: object): void => {
    void validateEditorInput(validationSchema, validationObject).then(
      ([, errors]) => {
        setValidationResult(errors);
      },
    );
  };

  const handleSelectionChanged = ({ target: { value } }: SelectChangeEvent) => {
    setSelected(value);
  };

  const getShowError = (): boolean => {
    return !!validationResult['selected'] && fieldsTouched['value'];
  };

  return (
    <Box>
      <Tooltip title={getShowError() && validationResult['selectedArray']}>
        <Select
          data-cy="countSelect"
          sx={{ width: 1 }}
          label={t(`leadConstraints.${type}.label`)}
          multiple={false}
          value={selected}
          error={getShowError()}
          onChange={handleSelectionChanged}
          onBlur={() => {
            setFieldsTouched({ value: true });
          }}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 2 }}>
              {getShowError() && <AnnouncementSharpIcon color="error" />}
            </InputAdornment>
          }
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} data-cy="countSelectItem">
              {t(`leadConstraints.${type}.selectOptions.${option}`)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </Box>
  );
};
