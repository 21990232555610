import { Box, Button, Link, Stack, Typography } from '@mui/material';
import { Field, FormikProvider, useFormik } from 'formik';
import React, { FormEvent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { object, string } from 'yup';
import { AuthService } from '../_generatedApi';
import Loader from '../components/Loader';
import Logo from '../flexifin_logo.svg';
import { LOGIN_ROUTE } from '../router/routes';
import { setToken } from '../store/user/userSlice';
import { TextField } from '../components/form/FormikTextField';
import { setSnackbarOpen } from '../store/common/snackbarSlice';
import { EMAIL_REGEX } from '../consts';

interface LoginValues {
  email: string;
  password: string;
}

const Login = () => {
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const onLogin = async (values: LoginValues) => {
    setLoading(true);

    AuthService.login({
      requestBody: { email: values.email, password: values.password },
    })
      .then((response) => {
        dispatch(setToken(response.data.token));
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: t(`login.response.${error.status}`),
            severity: 'error',
          }),
        );
      })
      .finally(() => setLoading(false));
  };

  const initialValues = {
    email: '',
    password: '',
  };

  const validationSchema = object({
    email: string()
      .matches(EMAIL_REGEX, t('login.emailMustBeEmail') as string)
      .required(t('login.emailRequired') as string),
    password: string().required(t('login.passwordRequired') as string),
  });

  const loginForm = useFormik({
    initialValues,
    onSubmit: onLogin,
    validationSchema,
  });

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          height: '100vh',
        }}
        component="form"
        onSubmit={(event: FormEvent<HTMLDivElement>) => {
          event.preventDefault();
          void loginForm.submitForm();
        }}
      >
        <Stack
          data-cy="loginForm"
          spacing={3}
          sx={{
            p: 3,
            width: { xs: '100%', sm: ' 400px' },
            boxSizing: 'border-box',
            backgroundColor: 'white',
            borderRadius: '4px',
            boxShadow: 5,
          }}
        >
          <Loader open={isLoading} message={t('login.loaderMessage')} />
          <FormikProvider value={loginForm}>
            <Box sx={{ py: 2, px: 4 }}>
              <img src={Logo} alt="logo" />
            </Box>
            <Stack gap="20px">
              <Typography
                sx={{ textAlign: 'center', fontSize: 30, fontWeight: 'bold' }}
              >
                Affiliate module
              </Typography>
              <Field
                inputProps={{
                  'data-cy': `email`,
                }}
                component={TextField}
                name="email"
                id="login_email"
                label={t('login.email')}
                type="email"
                autoComplete="email"
                autoFocus
                placeholder={t('login.email')}
                required={true}
              />
              <Field
                inputProps={{
                  'data-cy': `password`,
                }}
                component={TextField}
                name="password"
                id="login_password"
                label={t('login.password')}
                type="password"
                autoComplete="password"
                placeholder={t('login.password')}
                InputLabelProps={{ shrink: true }}
                required={true}
              />
              <Link
                sx={{ cursor: 'pointer', position: 'relative', top: '-10px' }}
                underline={'hover'}
                onClick={() => navigate(LOGIN_ROUTE)}
              >
                {t('login.resetPassword')}
              </Link>
              <Button
                variant="contained"
                color="error"
                data-cy="submitButton"
                type="submit"
              >
                {t('login.logIn')}
              </Button>
            </Stack>
          </FormikProvider>
        </Stack>
      </Box>
    </>
  );
};

export default Login;
