import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AffiliatePartnerDto,
  AffiliatePartnersService,
} from '../_generatedApi';
import { AffiliatePartnersModal } from '../components/affilatePartners/AffiliatePartnersModal';
import AlertDialog from '../components/AlertDialog';
import { GridTable, IGridRowAction } from '../components/gridTable';
import {
  ACTIVE_RENDERER,
  ColumnDefitions,
  GridColumnType,
  ZERO_VALUE_WARNING_RENDERER,
} from '../components/gridTable';
import {
  removeAffiliate,
  setAffiliates,
} from '../store/affiliate/affiliateSlice';
import { RootState } from '../store/store';
import { setSnackbarOpen } from '../store/common/snackbarSlice';

const TRANSLATION_PREFIX = 'affiliatePartners';

export type AffilatePartnerValues = Partial<AffiliatePartnerDto>;

const AffiliatePartners = () => {
  const affiliates = useSelector(
    (state: RootState) => state.affiliate.affiliates,
  );
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedAffiliate, setSelectedAffiliate] =
    useState<AffiliatePartnerDto>();

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState<AffilatePartnerValues>({});

  const actions: IGridRowAction = {
    onEdit: (params: unknown) => {
      setOpenEditModal(true);
      setModalValues(params as AffilatePartnerValues);
    },
    onDelete: (params: unknown) => {
      setSelectedAffiliate(params as AffiliatePartnerDto);
      setOpenDeleteDialog(true);
    },
  };

  const deleteAffiliate = (): void => {
    if (!selectedAffiliate?.id) {
      return;
    }

    void AffiliatePartnersService.remove({ id: selectedAffiliate.id })
      .then(() => {
        dispatch(removeAffiliate(selectedAffiliate.id));
        dispatch(
          setSnackbarOpen({
            message: 'affiliatePartners.response.delete.Ok',
            severity: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `affiliatePartners.response.delete.${error.status}`,
            severity: 'error',
          }),
        );
      });
    setOpenDeleteDialog(false);
  };

  const onGridReady = useCallback(() => {
    void AffiliatePartnersService.findAll()
      .then((result) => {
        dispatch(setAffiliates(result.data));
      })
      .finally(() => setLoading(false));
  }, []);

  const openNewModal = () => {
    setOpenEditModal(true);
    setModalValues({});
  };

  const columnProps: ColumnDefitions = {
    displayName: {
      flex: 0.6,
    },
    channelCount: {
      flex: 0.3,
      fieldType: GridColumnType.Number,
      type: 'rightAligned',
    },
    activeChannelCount: {
      flex: 0.3,
      fieldType: GridColumnType.Number,
      type: 'rightAligned',
      cellRenderer: ZERO_VALUE_WARNING_RENDERER,
      cellRendererParams: {
        tooltipMessage: t(`${TRANSLATION_PREFIX}.noActiveChannel`),
      },
    },
    note: {},
    active: {
      flex: 0.3,
      fieldType: GridColumnType.Boolean,
      headerClass: 'text-center',
      cellRenderer: ACTIVE_RENDERER,
    },
  };

  return (
    <>
      <AlertDialog
        state={openDeleteDialog}
        stateCallback={setOpenDeleteDialog}
        buttonAction={deleteAffiliate}
        title={t('affiliatePartners.dialogAlert.title')}
        message={
          t('affiliatePartners.dialogAlert.message', {
            affiliateName: selectedAffiliate?.displayName,
          }) as string
        }
        buttonText={t('affiliatePartners.dialogAlert.button')}
        dataCy={'deleteDialog'}
      />
      <GridTable
        data={affiliates}
        translationPrefix={TRANSLATION_PREFIX}
        onGridReady={onGridReady}
        actions={actions}
        onCreate={() => openNewModal()}
        defaultSort={{ field: 'displayName', direction: 'asc' }}
        inlineEdit={false}
        colDefs={columnProps}
        loading={loading}
      />
      <AffiliatePartnersModal
        open={openEditModal}
        setOpen={setOpenEditModal}
        values={modalValues}
        setValues={setModalValues}
      />
    </>
  );
};

export default AffiliatePartners;
