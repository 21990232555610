import type { PayloadAction } from '@reduxjs/toolkit';
import { createSlice } from '@reduxjs/toolkit';
import { CommunicationChannelDto } from '../../_generatedApi';

export interface ChannelState {
  channels: CommunicationChannelDto[];
}

const initialState: ChannelState = {
  channels: [],
};

export const channelSlice = createSlice({
  name: 'channel',
  initialState,
  reducers: {
    setChannels: (state, action: PayloadAction<CommunicationChannelDto[]>) => {
      state.channels = action.payload;
    },
    removeChannel: (state, action: PayloadAction<string>) => {
      state.channels = state.channels.filter(
        (channel) => channel.id !== action.payload,
      );
    },
  },
});

export const { setChannels, removeChannel } = channelSlice.actions;

export default channelSlice.reducer;
