import { useField, useFormikContext } from 'formik';
import {
  Box,
  Chip,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import React from 'react';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';
import CancelIcon from '@mui/icons-material/Cancel';

interface IFormikChipMultiSelect {
  label: string;
  fieldName: string;
  data: unknown[];
  translationPrefix: string;
  required?: boolean;
}

const FormikChipMultiSelect = ({
  label,
  fieldName,
  data,
  translationPrefix,
  required,
}: IFormikChipMultiSelect) => {
  const { t } = useTranslation();
  const [field, meta, helpers] = useField(fieldName);
  const { validateField } = useFormikContext();

  const labelValue = required ? `${label} *` : label;
  const showError = !!meta.error && meta.touched;

  const handleOnChange = (event: SelectChangeEvent<typeof data>) => {
    const {
      target: { value },
    } = event;

    void helpers.setValue(value);
  };

  const handleDelete = (target: string) => {
    const multiselectValues = field.value as string[];
    void helpers.setValue(multiselectValues.filter((v) => v !== target));

    void validateField(fieldName);
  };

  return (
    <Tooltip title={showError ? meta.error : ''} followCursor={true}>
      <FormControl sx={{ m: 1 }} fullWidth>
        <InputLabel error={showError} id={`select-multiple-chip.${fieldName}`}>
          {labelValue}
        </InputLabel>
        <Select
          data-cy="chips"
          onChange={handleOnChange}
          onBlur={() => helpers.setTouched(true)}
          multiple
          required={required}
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 2 }}>
              {showError && <AnnouncementSharpIcon color="error" />}
            </InputAdornment>
          }
          renderValue={(selected) => (
            <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
              {selected.map((value) => (
                <Chip
                  data-cy="chip"
                  key={value as string}
                  label={t(`${translationPrefix}.${value}`)}
                  onDelete={() => handleDelete(value as string)}
                  clickable
                  deleteIcon={
                    <CancelIcon
                      onMouseDown={(event) => event.stopPropagation()}
                      data-cy="chipDeleteCheckbox"
                    />
                  }
                />
              ))}
            </Box>
          )}
          value={field.value}
          labelId={`select-multiple-chip.${fieldName}`}
          label={labelValue}
          error={showError}
        >
          {data.map((row) => (
            <MenuItem
              key={row as never}
              value={row as never}
              data-cy="chipItem"
            >
              {t(`${translationPrefix}.${row}`)}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </Tooltip>
  );
};

export default FormikChipMultiSelect;
