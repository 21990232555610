import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { useField } from 'formik';
import dayjs from 'dayjs';
import React from 'react';
import { Box, Tooltip } from '@mui/material';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

interface IFormikMonthPicker {
  label: string;
  fieldName: string;
  inputProps?: Record<string, string>;
}

const year2023 = dayjs().set('year', 2023).startOf('year');
const year2042 = dayjs().set('year', 2042).startOf('year');

export const FormikMonthPicker = ({
  label,
  fieldName,
  inputProps,
}: IFormikMonthPicker) => {
  const [, meta, helpers] = useField(fieldName);
  const [fieldDate, , helpersDate] = useField(`${fieldName}Date`);
  const changeHandler = (newValue: dayjs.Dayjs | null) => {
    void helpers.setTouched(true);
    void helpersDate.setValue(newValue);
    void helpers.setValue(
      newValue ? newValue.format('YYYY-MM') : 'invalid date',
    );
  };

  const displayError = meta.touched && !!meta.error;
  return (
    <Tooltip title={meta.error} followCursor={true}>
      <Box sx={{ position: 'relative' }}>
        <DatePicker
          minDate={year2023}
          maxDate={year2042}
          label={label}
          views={['month', 'year']}
          openTo={'month'}
          format={'YYYY-MM'}
          defaultValue={fieldDate.value}
          onChange={changeHandler}
          onClose={() => helpers.setTouched(true)}
          slotProps={{
            textField: {
              onBlur: () => {
                void helpers.setTouched(true);
                void helpersDate.setTouched(true);
              },
              error: displayError,
              inputProps,
            },
          }}
        />
        {displayError && (
          <AnnouncementSharpIcon
            color="error"
            sx={{
              position: 'absolute',
              right: 0,
              my: 'auto',
              mx: 6,
              height: '100%',
            }}
          />
        )}
      </Box>
    </Tooltip>
  );
};
