import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { getLanguage, USER_LANGUAGE } from './languages';
import { getToken, USER_TOKEN, validateToken } from './token';

export interface UserState {
  token: string;
  language: string;
  pageTitle: string;
  pageSubTitle: string;
}

const initialState: UserState = {
  token: getToken(),
  language: getLanguage(),
  pageTitle: '',
  pageSubTitle: '',
};

export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setToken: (state, action: PayloadAction<string>) => {
      const token = validateToken(action.payload);
      state.token = token;
      localStorage.setItem(USER_TOKEN, token);
    },
    setLanguage: (state, action: PayloadAction<string>) => {
      state.language = action.payload;
      localStorage.setItem(USER_LANGUAGE, action.payload);
    },
    setPageTitle: (state, action: PayloadAction<string>) => {
      state.pageTitle = action.payload;
    },
    setPageSubTitle: (state, action: PayloadAction<string>) => {
      state.pageSubTitle = action.payload;
    },
  },
});

export const { setToken, setLanguage, setPageTitle, setPageSubTitle } =
  userSlice.actions;

export default userSlice.reducer;
