import FormControl, { FormControlProps } from '@mui/material/FormControl';
import { FormHelperTextProps } from '@mui/material/FormHelperText';
import InputLabel, { InputLabelProps } from '@mui/material/InputLabel';
import MuiSelect, { SelectProps as MuiSelectProps } from '@mui/material/Select';
import { FieldProps, getIn } from 'formik';
import * as React from 'react';
import { InputAdornment, Tooltip } from '@mui/material';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

export interface SelectProps
  extends FieldProps,
    Omit<MuiSelectProps, 'name' | 'value'> {
  formControl?: FormControlProps;
  formHelperText?: FormHelperTextProps;
  inputLabel?: InputLabelProps;
}
// This component is copy of https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/Select.tsx and changed for new design
export const fieldToSelect = ({
  disabled,
  field: { onChange: fieldOnChange, ...field },
  form: { isSubmitting, touched, errors, setFieldTouched, setFieldValue },
  onClose,
  ...props
}: Omit<
  SelectProps,
  'formControl' | 'formHelperText' | 'inputLabel'
>): MuiSelectProps & { formError?: string } => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    disabled: disabled ?? isSubmitting,
    error: showError,
    formError: showError ? fieldError : undefined,
    onChange:
      fieldOnChange ??
      (() => {
        // no-op
      }),

    endAdornment: (
      <InputAdornment position="start">
        {showError && <AnnouncementSharpIcon color="error" />}
      </InputAdornment>
    ),
    // we must use `onClose` instead of `onChange` to be able to trigger validation when users click outside of the select list.
    onClose:
      onClose ??
      (async (e: React.SyntheticEvent) => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        const dataset = (e.target as any).dataset as DOMStringMap;
        if (dataset && dataset.value) {
          // out-of-sync issue since November 2019: https://github.com/formium/formik/issues/2059#issuecomment-890613538
          // without the await, formik validates with the former value
          await setFieldValue(field.name, dataset.value, false);
        }
        void setFieldTouched(field.name, true, true);
      }),
    ...field,
    ...props,
  };
};

export const Select = ({
  formControl,
  inputLabel,
  formHelperText,
  ...selectProps
}: SelectProps) => {
  const { error, formError, disabled, ...selectFieldProps } =
    fieldToSelect(selectProps);
  const { children: formHelperTextChildren } = formHelperText || {};
  const shouldDisplayFormHelperText = error || formHelperTextChildren;

  return (
    <Tooltip
      title={shouldDisplayFormHelperText ? formError : formHelperTextChildren}
      followCursor={true}
    >
      <FormControl disabled={disabled} error={error} {...formControl}>
        <InputLabel id={selectFieldProps.labelId} {...inputLabel}>
          {selectFieldProps.label}
        </InputLabel>
        <MuiSelect {...selectFieldProps} />
      </FormControl>
    </Tooltip>
  );
};

Select.displayName = 'FormikMaterialUISelect';
