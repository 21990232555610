/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { ConditionDto } from './ConditionDto';

export type ConditionGroupDto = {
  relation: ConditionGroupDto.relation;
  conditions: Array<ConditionDto>;
};

export namespace ConditionGroupDto {
  export enum relation {
    AND = 'AND',
    OR = 'OR',
  }
}
