import { ICellRendererParams } from '@ag-grid-community/core';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import CreateIcon from '@mui/icons-material/Create';
import DeleteIcon from '@mui/icons-material/Delete';
import SyncIcon from '@mui/icons-material/Sync';
import { Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const GridActions = ({
  data,
  colDef,
  node,
  rowIndex,
}: ICellRendererParams) => {
  const params = colDef?.cellRendererParams;
  const { translationPrefix, actions, additionalActions } = params;
  const { onEdit, onPostback, onCopy, onDelete } = actions;
  const { t } = useTranslation();

  const isPinnedRow = node.isRowPinned();
  const disableDeleteButton = isPinnedRow || data.deletable === false;
  return (
    <Box sx={{ display: 'flex', justifyContent: 'space-around' }}>
      {onEdit && (
        <Tooltip title={t('grid.edit')}>
          <IconButton
            data-cy="editButton"
            size="small"
            edge="start"
            onClick={() => onEdit(data, rowIndex)}
          >
            <CreateIcon />
          </IconButton>
        </Tooltip>
      )}
      {onPostback && (
        <Tooltip title={t('grid.postback')}>
          <IconButton
            data-cy="channelPostbackButton"
            size="small"
            edge="start"
            onClick={() => onPostback(data)}
          >
            <SyncIcon />
          </IconButton>
        </Tooltip>
      )}
      {onCopy && (
        <Tooltip title={t('grid.copy')}>
          <IconButton
            data-cy="copyButton"
            size="small"
            edge="start"
            onClick={() => onCopy(data)}
          >
            <ContentCopyIcon />
          </IconButton>
        </Tooltip>
      )}
      {additionalActions && additionalActions(data)}
      {onDelete && (
        <Tooltip
          title={
            disableDeleteButton && !isPinnedRow
              ? t(`${translationPrefix}.disabledDeleteTooltip`)
              : t('grid.delete')
          }
        >
          <Box>
            <IconButton
              data-cy={`deleteButton-${data?.id}`}
              size="small"
              edge="start"
              color="error"
              onClick={() => onDelete(data, rowIndex)}
              disabled={disableDeleteButton}
            >
              <DeleteIcon />
            </IconButton>
          </Box>
        </Tooltip>
      )}
    </Box>
  );
};
