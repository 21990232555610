import { ICellRendererParams } from '@ag-grid-community/core';
import Box from '@mui/material/Box';
import Switch from '@mui/material/Switch';
import React from 'react';

const GridSwitch = (props: ICellRendererParams) => {
  const checkedHandler = (event: React.ChangeEvent<HTMLInputElement>) => {
    const checked = event.target.checked;
    const colId = props.column?.getColId();
    if (colId) {
      props.node.setDataValue(colId, checked);
    }
  };

  return (
    <Box sx={{ display: 'flex', justifyContent: 'center' }}>
      <Switch
        data-cy="switchToggle"
        sx={{ margin: '' }}
        disabled
        checked={props.value}
        onChange={checkedHandler}
      />
    </Box>
  );
};

export default GridSwitch;
