import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { LeadConstraintDto } from '../../_generatedApi';

export interface LeadConstraintState {
  leadConstraints: LeadConstraintDto[];
}

const initialState: LeadConstraintState = {
  leadConstraints: [],
};

export const leadConstraintSlice = createSlice({
  name: 'leadConstraint',
  initialState,
  reducers: {
    setLeadConstraints: (state, action: PayloadAction<LeadConstraintDto[]>) => {
      state.leadConstraints = action.payload;
    },
    removeLeadConstraint: (state, action: PayloadAction<string>) => {
      state.leadConstraints = state.leadConstraints.filter(
        (leadConstraint) => leadConstraint.id !== action.payload,
      );
    },
  },
});

export const { setLeadConstraints, removeLeadConstraint } =
  leadConstraintSlice.actions;

export default leadConstraintSlice.reducer;
