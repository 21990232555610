/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { CheckDto } from './CheckDto';
import type { ConditionGroupDto } from './ConditionGroupDto';

export type LeadConstraintDetailDto = {
  id: string;
  displayName: string;
  preflightChecks: Array<CheckDto>;
  registerChecks: Array<CheckDto>;
  mainConditionGroup?: ConditionGroupDto;
  groupRelation: LeadConstraintDetailDto.groupRelation;
  conditionGroups: Array<ConditionGroupDto>;
};

export namespace LeadConstraintDetailDto {
  export enum groupRelation {
    AND = 'AND',
    OR = 'OR',
  }
}
