/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type CheckDto = {
  type: CheckDto.type;
  order: number;
};

export namespace CheckDto {
  export enum type {
    RESIDENCE = 'RESIDENCE',
    CONTACT_ADDRESS = 'CONTACT_ADDRESS',
    INSOLVENCY = 'INSOLVENCY',
    FORECLOSURE = 'FORECLOSURE',
    INTERNAL_DB = 'INTERNAL_DB',
    BLACKLIST = 'BLACKLIST',
    HISTORY = 'HISTORY',
    CARD_STOLEN = 'CARD_STOLEN',
    LOAN_INCREASE = 'LOAN_INCREASE',
    REJECTION_OF_THE_CONTRACT = 'REJECTION_OF_THE_CONTRACT',
    TERMINATED_CONTRACT = 'TERMINATED_CONTRACT',
    RESTRICTED_FAMILY_NAME = 'RESTRICTED_FAMILY_NAME',
    CASE_TRANSFERRED = 'CASE_TRANSFERRED',
    INTERNAL_WHITELIST = 'INTERNAL_WHITELIST',
  }
}
