import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import {
  AffiliatePartnersService,
  CommunicationChannelDto,
  CommunicationChannelsService,
  KeyValueDto,
  LeadConstraintsService,
} from '../_generatedApi';
import AlertDialog from '../components/AlertDialog';
import CommunicationChannelsModal from '../components/communicationChannels/CommunicationChannelsModal';
import PostbackModal from '../components/communicationChannels/PostbackModal';
import {
  ACTIVE_RENDERER,
  ColumnDefitions,
  GridColumnType,
  GridTable,
  IGridRowAction,
} from '../components/gridTable';
import {
  removeChannel,
  setChannels,
} from '../store/communication-channels/channelSlice';
import { RootState } from '../store/store';
import { getDefaultToken } from '../utils/token';
import { setSnackbarOpen } from '../store/common/snackbarSlice';

const TRANSLATION_PREFIX = 'communicationChannels';

export type CommunicationChannelValues = Partial<CommunicationChannelDto>;

const CommunicationChannels = () => {
  const channels = useSelector((state: RootState) => state.channel.channels);
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const [loading, setLoading] = useState(true);

  const [selectedChannel, setSelectedChannel] =
    useState<CommunicationChannelDto>();
  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);

  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [modalValues, setModalValues] = useState<CommunicationChannelValues>(
    {},
  );
  const [affiliates, setAffiliates] = useState<KeyValueDto[]>([]);
  const [leadConstraints, setLeadConstraints] = useState<KeyValueDto[]>([]);

  const [openPostbackModal, setOpenPostbackModal] = useState<boolean>(false);

  useEffect(() => {
    void AffiliatePartnersService.getLookups().then((response) => {
      setAffiliates(response.data);
    });
    void LeadConstraintsService.getLookups().then((response) => {
      setLeadConstraints(response.data);
    });
  }, []);

  const actions: IGridRowAction = {
    onEdit: (params: unknown) => {
      setOpenEditModal(true);
      setModalValues(params as CommunicationChannelValues);
    },
    onPostback: (params: unknown) => {
      setOpenPostbackModal(true);
      setSelectedChannel(params as CommunicationChannelDto);
    },
    onDelete: (params: unknown) => {
      setSelectedChannel(params as CommunicationChannelDto);
      setOpenDeleteDialog(true);
    },
  };

  const deleteCommunicationChannel = (): void => {
    if (!selectedChannel?.id) {
      return;
    }

    void CommunicationChannelsService.remove({ id: selectedChannel.id })
      .then(() => {
        dispatch(removeChannel(selectedChannel.id));
        dispatch(
          setSnackbarOpen({
            message: 'communicationChannels.response.delete.Ok',
            severity: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `communicationChannels.response.delete.${error.status}`,
            severity: 'error',
          }),
        );
      });
    setOpenDeleteDialog(false);
  };

  const onGridReady = useCallback(() => {
    void CommunicationChannelsService.findAll()
      .then((response) => {
        dispatch(setChannels(response.data));
      })
      .finally(() => setLoading(false));
  }, []);

  const openNewModal = () => {
    setOpenEditModal(true);
    setModalValues({
      token1: getDefaultToken(),
      token2: getDefaultToken(),
    });
  };

  const columnProps: ColumnDefitions = {
    displayName: {},
    code: {
      flex: 0.6,
    },
    type: {
      flex: 0.5,
    },
    'affiliatePartner.name': {},
    'leadConstraint.name': {},
    active: {
      fieldType: GridColumnType.Boolean,
      cellRenderer: ACTIVE_RENDERER,
      flex: 0.5,
      headerClass: 'text-center',
    },
    postbackActive: {
      fieldType: GridColumnType.Boolean,
      cellRenderer: ACTIVE_RENDERER,
      flex: 0.5,
      headerClass: 'text-center',
    },
  };

  return (
    <>
      <AlertDialog
        state={openDeleteDialog}
        stateCallback={setOpenDeleteDialog}
        buttonAction={deleteCommunicationChannel}
        title={t('communicationChannels.dialogAlert.title')}
        message={
          t('communicationChannels.dialogAlert.message', {
            channelName: selectedChannel?.displayName,
          }) as string
        }
        buttonText={t('communicationChannels.dialogAlert.button')}
        dataCy={'deleteDialog'}
      />
      <GridTable
        data={channels}
        translationPrefix={TRANSLATION_PREFIX}
        onGridReady={onGridReady}
        onCreate={openNewModal}
        actions={actions}
        colDefs={columnProps}
        defaultSort={{ field: 'displayName', direction: 'asc' }}
        inlineEdit={false}
        loading={loading}
      />
      {openEditModal && (
        <CommunicationChannelsModal
          open={openEditModal}
          setOpenModal={setOpenEditModal}
          id={modalValues.id}
          affiliates={affiliates}
          leadConstraints={leadConstraints}
        />
      )}
      {openPostbackModal && selectedChannel && (
        <PostbackModal
          open={openPostbackModal}
          channelId={selectedChannel.id}
          setOpenModal={setOpenPostbackModal}
        />
      )}
    </>
  );
};

export default CommunicationChannels;
