import { Backdrop } from '@mui/material';
import React, { useEffect, useRef, useState } from 'react';
import { Loading } from './Loading';

export const DebouncedBackdropLoader = () => {
  const [show, setShow] = useState(false);
  const timer = useRef<ReturnType<typeof setInterval> | null>(null);

  useEffect(() => {
    timer.current = setTimeout(() => setShow(true), 300);
    return () => clearTimeout(timer.current as NodeJS.Timeout);
  }, []);

  return (
    <Backdrop open={show} sx={{ zIndex: 10 }}>
      <Loading />
    </Backdrop>
  );
};
