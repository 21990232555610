export const INPUT_DATE_FORMAT_REGEX = /^\d{4}-(0[1-9]|1[0-2])$/;

export const DRAWER_WIDTH = 290;

export const MAX_32_BIT_INTEGER_VALUE = 2147483647;

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const INPUT_SIMPLE_CODE = /^[a-zA-Z0-9_-]*$/;
