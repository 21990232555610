import { ICellRendererParams } from '@ag-grid-community/core';
import React from 'react';
import CheckIcon from '@mui/icons-material/Check';
import { Grid, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

const GridActive = (props: ICellRendererParams) => {
  const { t } = useTranslation();
  if (props.value) {
    return (
      <Tooltip title={t('grid.active')}>
        <Grid
          container
          direction="row"
          justifyContent="center"
          alignItems="center"
          height="100%"
        >
          <CheckIcon color="primary" />
        </Grid>
      </Tooltip>
    );
  }
  return null;
};

export default GridActive;
