import { ICellRendererParams } from '@ag-grid-community/core';
import { Box, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const TimeWindowRenderer = ({
  value,
}: Pick<ICellRendererParams, 'value'>) => {
  const { t } = useTranslation();

  return (
    <Stack direction="row" spacing={2}>
      <Stack direction="row" spacing={1}>
        <Box>{t('leadConstraints.timeWindowRenderer.time')}:</Box>
        <Box>
          <b>
            {value?.timeFrom} - {value?.timeTo}
          </b>
        </Box>
      </Stack>
      {value?.weekDays.length > 0 && (
        <Stack direction="row" spacing={1}>
          <Box>{t('leadConstraints.timeWindowRenderer.days')}:</Box>
          <Box>
            <b>
              {value?.weekDays
                .map((d: string) =>
                  t(`leadConstraints.timeWindowRenderer.dayNums.${d}`),
                )
                .join(',')}
            </b>
          </Box>
        </Stack>
      )}
      {value?.daysInMonthFrom && (
        <Stack direction="row" spacing={1}>
          <Box>{t('leadConstraints.timeWindowRenderer.daysInMonth')}:</Box>
          <Box>
            <b>
              {value?.daysInMonthFrom} - {value?.daysInMonthTo}
            </b>
          </Box>
        </Stack>
      )}
    </Stack>
  );
};
