import {
  IDateFilterParams,
  IFilterOptionDef,
  INumberFilterParams,
} from '@ag-grid-community/core';

export const booleanFilter: INumberFilterParams = {
  buttons: ['reset', 'apply'],
  filterOptions: [
    {
      displayKey: 'none',
      displayName: 'None',
      predicate: (_, cellValue) => +cellValue !== undefined,
    },
    {
      displayKey: 'true',
      displayName: 'True',
      predicate: (_, cellValue) => +cellValue === 1,
      numberOfInputs: 0,
    },
    {
      displayKey: 'false',
      displayName: 'False',
      predicate: (_, cellValue) => +cellValue === 0,
      numberOfInputs: 0,
    },
  ] as IFilterOptionDef[],
  suppressAndOrCondition: true,
};

export const dateFilter: IDateFilterParams = {
  buttons: ['reset', 'apply'],
  comparator: (filterLocalDateAtMidnight: Date, cellValue: string) => {
    const dateAsString = cellValue;
    if (dateAsString == null) return -1;
    const dateParts = dateAsString.split('-');
    const cellDate = new Date(
      Number(dateParts[0]),
      Number(dateParts[1]) - 1,
      Number(dateParts[2]),
    );

    if (filterLocalDateAtMidnight.getTime() === cellDate.getTime()) {
      return 0;
    }
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    }
    if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  },
};
