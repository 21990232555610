import { ICellRendererParams } from '@ag-grid-community/core';
import { Warning } from '@mui/icons-material';
import { Box, Icon, Tooltip } from '@mui/material';
import React from 'react';

const GridZeroValueWarning = (props: ICellRendererParams) => {
  const value = props.value;
  const tooltip = props.colDef?.cellRendererParams.tooltipMessage;

  const getIcon = () => {
    if (value === 0) {
      return (
        <Tooltip title={tooltip}>
          <Warning color="warning" />
        </Tooltip>
      );
    }
    return <Icon />;
  };

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-end',
        alignItems: 'center',

        gap: '10px',
      }}
    >
      {value}
      {getIcon()}
    </Box>
  );
};

export default GridZeroValueWarning;
