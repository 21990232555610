import { Grid, Paper } from '@mui/material';
import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import Divider from '@mui/material/Divider';
import Box from '@mui/material/Box';
import { Stack, Typography, Link } from '@flexifincz/ui';

interface ITile {
  title: string;
  link: string;
  hasActiveFlag: boolean;
  getRecordsFunction: () => Promise<unknown>;
  icon: React.JSX.Element;
  dataCy?: string;
}
export const Tile = ({
  title,
  link,
  getRecordsFunction,
  hasActiveFlag,
  icon,
  dataCy,
}: ITile) => {
  const [count, setCount] = useState(0);
  const [active, setActive] = useState(0);
  const { t } = useTranslation();

  const initData = () => {
    void getRecordsFunction().then((result: any) => {
      setCount(result.data?.length);
      if (hasActiveFlag) {
        setActive(result.data?.filter((dat: any) => dat?.active).length);
      }
    });
  };

  useEffect(() => {
    initData();
  }, []);

  return (
    <Grid item xs={12} md={6} lg={6}>
      <Paper
        sx={{
          p: 4,
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: 'white',
          height: 240,
          position: 'relative',
        }}
      >
        <Stack spacing={2}>
          <Stack direction="row" gap={1} alignItems="center">
            {icon}
            <Typography variant="h5" component="span" data-cy={`${dataCy}Name`}>
              {t(title)}
            </Typography>
          </Stack>

          <Divider />
          <Typography component="p" variant="h6" data-cy={`${dataCy}Total`}>
            {t('dashboard.total', { count })}
          </Typography>
          {hasActiveFlag && (
            <Typography
              component="p"
              variant="h6"
              color="success.main"
              data-cy={`${dataCy}Active`}
            >
              {t('dashboard.active', { active })}
            </Typography>
          )}
          <Box sx={{ position: 'absolute', bottom: 0, right: 0, p: 2 }}>
            <Link href={link} data-cy={`${dataCy}Link`}>
              {t('dashboard.showMore')}
            </Link>
          </Box>
        </Stack>
      </Paper>
    </Grid>
  );
};
