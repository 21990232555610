/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { BudgetInterval } from './BudgetInterval';

export type BudgetOperationDto = {
  budgetId: string;
  budgetName: string;
  action: BudgetOperationDto.action;
  existingBudget?: BudgetInterval;
};

export namespace BudgetOperationDto {
  export enum action {
    EDIT = 'EDIT',
    DELETE = 'DELETE',
  }
}
