import { DragHandle } from '@mui/icons-material';
import DeleteIcon from '@mui/icons-material/Delete';
import { ListItem, ListItemIcon } from '@mui/material';
import IconButton from '@mui/material/IconButton';
import ListItemText from '@mui/material/ListItemText';
import React, { FC } from 'react';
import { Draggable } from 'react-beautiful-dnd';

export interface ILeadConstraintsCheckProps {
  title: string;
  index: number;
  onCheckDelete: (index: number) => void;
}

export const LeadConstraintsCheck: FC<ILeadConstraintsCheckProps> = ({
  title,
  index,
  onCheckDelete,
}) => {
  return (
    <Draggable
      draggableId={`${title + index}`}
      index={index}
      key={`${title + index}`}
    >
      {(provided) => (
        <ListItem
          data-cy="draggableItem"
          divider
          sx={{
            backgroundColor: 'background.paper',
            mb: 1,
            border: 1,
            borderColor: 'secondary.light',
          }}
          {...provided.draggableProps}
          {...provided.dragHandleProps}
          ref={provided.innerRef}
          secondaryAction={
            <IconButton
              data-cy="deleteButton"
              edge="end"
              aria-label="delete"
              onClick={() => onCheckDelete(index)}
            >
              <DeleteIcon color="error" />
            </IconButton>
          }
        >
          <ListItemIcon>
            <DragHandle />
          </ListItemIcon>
          <ListItemText primary={title} />
        </ListItem>
      )}
    </Draggable>
  );
};
