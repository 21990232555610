/* generated using openapi-typescript-codegen -- do no edit */
/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type PostbackParamDto = {
  name: string;
  staticValue?: string;
  type: PostbackParamDto.type;
  dynamicValue?: PostbackParamDto.dynamicValue;
};

export namespace PostbackParamDto {
  export enum type {
    STATIC_VALUE = 'STATIC_VALUE',
    DYNAMIC_VALUE = 'DYNAMIC_VALUE',
  }

  export enum dynamicValue {
    REF_NO = 'refNo',
    SOURCE = 'source',
    CURRENT_STATE = 'currentState',
  }
}
