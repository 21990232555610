import React, { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { LeadConstraintDto, LeadConstraintsService } from '../_generatedApi';
import AlertDialog from '../components/AlertDialog';
import { IGridRowAction } from '../components/gridTable';
import LeadConstraintsModal, {
  LeadConstraintModalMode,
} from '../components/leadConstraints/LeadConstraintsModal';
import {
  removeLeadConstraint,
  setLeadConstraints,
} from '../store/lead-constraints/leadConstraintSlice';
import { generatePath } from 'react-router-dom';
import { LEAD_CONSTRAINTS_ROUTE_CONDITIONS } from '../router/routes';
import { AddTaskRounded } from '@mui/icons-material';
import { setSnackbarOpen } from '../store/common/snackbarSlice';
import { Button, Datatable, datatableColumnsHelper } from '@flexifincz/ui';
import type { DatatableColumns } from '@flexifincz/ui';

const TRANSLATION_PREFIX = 'leadConstraints';

const LeadConstraints = () => {
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();

  const [openDeleteDialog, setOpenDeleteDialog] = useState(false);
  const [selectedLeadConstraint, setSelectedLeadConstraint] =
    useState<LeadConstraintDto>();

  const [modalMode, setModalMode] = useState<LeadConstraintModalMode>(
    LeadConstraintModalMode.CREATE,
  );
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);
  const [leadConstraintId, setLeadConstraintId] = useState<string | undefined>(
    undefined,
  );
  const openNewModal = () => {
    setModalMode(LeadConstraintModalMode.CREATE);
    setOpenEditModal(true);
    setLeadConstraintId(undefined);
  };

  const actions: IGridRowAction = {
    onEdit: (params: unknown) => {
      setModalMode(LeadConstraintModalMode.UPDATE);
      setOpenEditModal(true);
      setLeadConstraintId((params as LeadConstraintDto).id);
    },
    onCopy: (params: unknown) => {
      setModalMode(LeadConstraintModalMode.COPY);
      setOpenEditModal(true);
      setLeadConstraintId((params as LeadConstraintDto).id);
    },
    onDelete: (params: unknown) => {
      setSelectedLeadConstraint(params as LeadConstraintDto);
      setOpenDeleteDialog(true);
    },
  };

  const deleteLeadConstraint = (): void => {
    if (!selectedLeadConstraint?.id) {
      return;
    }

    void LeadConstraintsService.remove({ id: selectedLeadConstraint.id })
      .then(() => {
        dispatch(removeLeadConstraint(selectedLeadConstraint.id));
        dispatch(
          setSnackbarOpen({
            message: 'leadConstraints.response.delete.Ok',
            severity: 'success',
          }),
        );
      })
      .catch((error) => {
        dispatch(
          setSnackbarOpen({
            message: `leadConstraints.response.delete.${error.status}`,
            severity: 'error',
          }),
        );
      });
    setOpenDeleteDialog(false);
  };

  const columns = useMemo<DatatableColumns<LeadConstraintDto>>(
    () =>
      datatableColumnsHelper([
        {
          accessorKey: 'displayName',
          header: t(`${TRANSLATION_PREFIX}.displayName`),
        },
        {
          accessorKey: 'conditionCount',
          header: t(`${TRANSLATION_PREFIX}.conditionCount`),
          variety: 'number',
        },
        {
          accessorKey: 'preflightCheckCount',
          header: t(`${TRANSLATION_PREFIX}.preflightCheckCount`),
          variety: 'number',
        },
        {
          accessorKey: 'registerCheckCount',
          header: t(`${TRANSLATION_PREFIX}.registerCheckCount`),
          variety: 'number',
        },
        {
          accessorKey: 'channelCount',
          header: t(`${TRANSLATION_PREFIX}.channelCount`),
          variety: 'number',
        },
        {
          accessorKey: 'activeChannelCount',
          header: t(`${TRANSLATION_PREFIX}.activeChannelCount`),
          variety: 'number',
        },
      ]),
    [t],
  );

  return (
    <>
      <AlertDialog
        state={openDeleteDialog}
        stateCallback={setOpenDeleteDialog}
        buttonAction={deleteLeadConstraint}
        title={t(`${TRANSLATION_PREFIX}.dialogAlert.title`)}
        message={
          t(`${TRANSLATION_PREFIX}.dialogAlert.message`, {
            leadConstraintName: selectedLeadConstraint?.displayName,
          }) as string
        }
        buttonText={t(`${TRANSLATION_PREFIX}.dialogAlert.button`)}
        dataCy={'deleteDialog'}
      />
      <Datatable
        columns={columns}
        id="lead-constraints"
        defaultSort={[{ id: 'displayName', desc: false }]}
        displayColumnDefOptions={{ 'mrt-row-actions': { minSize: 150 } }}
        dataProvider={async () => {
          const response = await LeadConstraintsService.findAll();

          dispatch(setLeadConstraints(response.data));

          return {
            data: response.data,
          };
        }}
        language={i18n.language}
        toolbarActions={() => (
          <Button
            variety="create"
            data-cy="addButton"
            onClick={() => openNewModal()}
            text={t('grid.newRow')}
          />
        )}
        rowActions={({ row }) => (
          <>
            <Button
              variety="edit"
              data-cy="editButton"
              text={t('grid.edit')}
              circle
              onClick={() => actions.onEdit && actions.onEdit(row.original)}
            />
            <Button
              variety="copy"
              data-cy="copyButton"
              text={t('grid.copy')}
              circle
              onClick={() => actions.onCopy && actions.onCopy(row.original)}
            />
            <Button
              color="secondary"
              text={t('leadConstraints.conditions')}
              circle
              data-cy="leadConstraintConditionsDetailButton"
              startIcon={<AddTaskRounded />}
              to={generatePath(LEAD_CONSTRAINTS_ROUTE_CONDITIONS, {
                id: row.original.id,
              })}
            />
            <Button
              variety="delete"
              data-cy={`deleteButton-${row.original.id}`}
              text={t('grid.delete')}
              disabled={!row.original.deletable}
              circle
              onClick={() => actions.onDelete && actions.onDelete(row.original)}
            />
          </>
        )}
      />
      {openEditModal && (
        <LeadConstraintsModal
          mode={modalMode}
          open={openEditModal}
          setOpenModal={setOpenEditModal}
          leadConstraintId={leadConstraintId}
        />
      )}
    </>
  );
};

export default LeadConstraints;
