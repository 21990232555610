import { Container, ThemeProvider } from '@mui/material';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import dayjs from 'dayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import React, { useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Routes, useLocation, useNavigate } from 'react-router-dom';
import './App.css';
import Navbar from './components/Navbar';
import Sidebar from './components/Sidebar';
import config from './config/config';
import PageNotFound from './pages/PageNotFound';
import AnonymousRoute from './router/AnonymousRoute';
import PrivateRoute from './router/PrivateRoute';
import {
  anonymousRoutes,
  IRoute,
  LOGIN_ROUTE,
  privateRoutes,
} from './router/routes';
import { RootState } from './store/store';
import { OpenAPI } from './_generatedApi';
import { setToken } from './store/user/userSlice';
import axios from 'axios';
import { useTranslation } from 'react-i18next';
import { setSnackbarOpen } from './store/common/snackbarSlice';
import { useLocalStorage } from 'usehooks-ts';
import { generateTheme } from '@flexifincz/ui/theme';
import { csCZ as muiCsCz, enUS as muiEnUs } from '@mui/material/locale';
import {
  csCZ as muiDatePickerCsCz,
  enUS as muiDatePickerEnUs,
} from '@mui/x-date-pickers/locales';
import { QueryClientProvider, QueryClient } from '@tanstack/react-query';

import '@fontsource/roboto/300.css';
import '@fontsource/roboto/400.css';
import '@fontsource/roboto/500.css';
import '@fontsource/roboto/700.css';

import 'dayjs/locale/cs';
import 'dayjs/locale/en';

export interface INavSideBarProps {
  open: boolean;
  onToggleDrawer: () => void;
}

const App = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const { t, i18n } = useTranslation();
  const queryClient = new QueryClient();

  const token = useSelector((state: RootState) => state.user.token);
  const [open, setOpen] = useLocalStorage('sidebarOpen', false);
  const logoutInterceptorLoaded = useRef(false);

  if (!config.apiUrl) {
    throw new Error('env property REACT_APP_API_URL is missing.');
  }
  OpenAPI.BASE = config.apiUrl;
  OpenAPI.TOKEN = token;
  const onToggleDrawer = () => {
    setOpen(!open);
  };

  if (!logoutInterceptorLoaded.current) {
    axios.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {
        if (error.response && error.response.status === 401) {
          dispatch(setToken(''));
          dispatch(
            setSnackbarOpen({
              severity: 'info',
              message: t('tokenExpired'),
            }),
          );
        }

        if (error.code === 'ERR_NETWORK') {
          dispatch(setToken(''));
          dispatch(
            setSnackbarOpen({
              severity: 'error',
              message: t('serverUnreachable'),
            }),
          );
        }

        return Promise.reject(error);
      },
    );
    logoutInterceptorLoaded.current = true;
  }

  useEffect(() => {
    if (!token && location.pathname !== '/login') {
      navigate('/login');
    }
  }, [location.pathname, token]);

  useEffect(() => {
    dayjs.locale(i18n.language);
  }, [i18n.language]);

  const themes: { [key: string]: [object, object] } = {
    cs: [muiCsCz, muiDatePickerCsCz],
    en: [muiEnUs, muiDatePickerEnUs],
  };

  const theme = generateTheme(...(themes[i18n.language] || []));

  return (
    <ThemeProvider theme={theme}>
      <LocalizationProvider
        dateAdapter={AdapterDayjs}
        adapterLocale={i18n.language}
      >
        <QueryClientProvider client={queryClient}>
          <Box
            sx={
              location.pathname === LOGIN_ROUTE
                ? {
                    background: 'linear-gradient(#D5DFF8, #DCDCDC)',
                    minHeight: '100vh',
                  }
                : { display: 'flex' }
            }
          >
            {token && (
              <>
                <Sidebar open={open} onToggleDrawer={onToggleDrawer} />
                <Navbar open={open} onToggleDrawer={onToggleDrawer} />
              </>
            )}
            <Box
              component="main"
              sx={{
                backgroundColor: 'background.paper',
                flexGrow: 1,
                height: '100vh',
                overflow: 'auto',
              }}
            >
              <Container
                sx={location.pathname !== LOGIN_ROUTE ? { mt: 5 } : {}}
                maxWidth={false}
              >
                {token && <Toolbar variant="dense" />}
                <Routes>
                  <Route path="*" element={<PageNotFound />} />
                  {anonymousRoutes.map((route: IRoute) => {
                    return (
                      <Route
                        key={route.path}
                        element={
                          <AnonymousRoute token={token}>
                            {route.element}
                          </AnonymousRoute>
                        }
                        path={route.path}
                      />
                    );
                  })}
                  {privateRoutes.map((route: IRoute) => {
                    return (
                      <Route
                        key={route.path}
                        element={
                          <PrivateRoute
                            token={token}
                            routeName={route.routeName}
                            route={route.path}
                          >
                            {route.element}
                          </PrivateRoute>
                        }
                        path={route.path}
                      />
                    );
                  })}
                </Routes>
              </Container>
            </Box>
          </Box>
        </QueryClientProvider>
      </LocalizationProvider>
    </ThemeProvider>
  );
};

export default App;
