import { ConditionDto } from '../../_generatedApi';
import {
  FromToRenderer,
  TimeWindowRenderer,
  SelectRenderer,
  MultiselectRenderer,
  ForTimeRenderer,
} from './renderers';

import {
  ForTimeEditor,
  FromToEditor,
  MultiselectEditor,
  SelectEditor,
  TimeWindowEditor,
} from './editors';
import { Editor } from './editors/types';

//TODO is it possible to have these as enums or something and have them generated by openapi?
const dependentChildrenOptions = ['0', '1', '2', '3', '4', '5', '5+'];
const employedTimeOptions = [0, 1, 2, 3, 4, 5, 6, 7, 8];
const maritalStatusOptions = [
  'SINGLE',
  'MARRIED',
  'PARTNERSHIP',
  'DIVORCED',
  'WIDOWED',
  'SEPARATED',
];
const incomeTypeOptions = [
  'EMPLOYED',
  'PART_TIME_EMPLOYMENT',
  'SELF_EMPLOYED',
  'PENSION',
  'MATERNITY_LEAVE',
  'BENEFITS',
  'SAVINGS',
  'STUDENT',
  'UNEMPLOYED',
  'OTHER',
];

export const conditionList = [
  'AGE',
  'TIME_WINDOW',
  'REQUESTED_AMOUNT',
  'PERIOD',
  'MARITAL_STATUS',
  'DEPENDENT_CHILDREN',
  'INCOME_TYPE',
  'EMPLOYED_TIME',
  'ESTABLISHED_CASE',
  'APPLICATION_IN_PROGRESS',
];

export const renderValue = (
  type: ConditionDto.type,
  values: Record<string, unknown>,
) => {
  switch (type) {
    case ConditionDto.type.AGE:
    case ConditionDto.type.REQUESTED_AMOUNT:
    case ConditionDto.type.PERIOD:
      return <FromToRenderer value={values} />;

    case ConditionDto.type.TIME_WINDOW:
      return <TimeWindowRenderer value={values} />;

    case ConditionDto.type.DEPENDENT_CHILDREN:
      return (
        <SelectRenderer
          selected={parseInt(values.value as string, 10)}
          type={type}
        />
      );

    case ConditionDto.type.EMPLOYED_TIME:
      return (
        <SelectRenderer
          selected={parseInt(values.value as string, 10)}
          type={type}
        />
      );

    case ConditionDto.type.INCOME_TYPE:
      return <MultiselectRenderer value={values} type={type} />;

    case ConditionDto.type.MARITAL_STATUS:
      return <MultiselectRenderer value={values} type={type} />;

    case ConditionDto.type.ESTABLISHED_CASE:
      return <ForTimeRenderer value={values} type={type} />;

    case ConditionDto.type.APPLICATION_IN_PROGRESS:
      return <ForTimeRenderer value={values} type={type} />;

    default:
      return undefined;
  }
};

export const getRenderer = (
  type: ConditionDto.type,
  values: Record<string, unknown>,
) => {
  switch (type) {
    case ConditionDto.type.AGE:
    case ConditionDto.type.REQUESTED_AMOUNT:
    case ConditionDto.type.PERIOD:
      return {
        component: FromToRenderer,
        params: values,
        type,
      };
    case ConditionDto.type.TIME_WINDOW:
      return {
        component: TimeWindowRenderer,
        params: values,
        type,
      };
    case ConditionDto.type.DEPENDENT_CHILDREN:
      return {
        component: SelectRenderer,
        params: {
          selected: values?.value,
          type,
        },
      };
    case ConditionDto.type.EMPLOYED_TIME:
      return {
        component: SelectRenderer,
        params: {
          selected: values?.value,
          type,
        },
      };
    case ConditionDto.type.INCOME_TYPE:
      return {
        component: MultiselectRenderer,
        params: {
          values,
          type,
        },
      };
    case ConditionDto.type.MARITAL_STATUS:
      return {
        component: MultiselectRenderer,
        params: {
          values,
          type,
        },
      };
    case ConditionDto.type.ESTABLISHED_CASE:
      return {
        component: ForTimeRenderer,
        params: {
          values,
          type,
        },
      };
    case ConditionDto.type.APPLICATION_IN_PROGRESS:
      return {
        component: ForTimeRenderer,
        params: {
          values,
          type,
        },
      };
    default:
      return undefined;
  }
};

export const getEditor = ({
  type,
  params,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
}: Editor) => {
  switch (type) {
    case ConditionDto.type.AGE:
    case ConditionDto.type.PERIOD:
      return (
        <FromToEditor
          type={type}
          params={params}
          onlyIntegerValue={true}
          onlyPositiveValue={true}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );
    case ConditionDto.type.REQUESTED_AMOUNT:
      return (
        <FromToEditor
          type={type}
          params={params}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );
    case ConditionDto.type.TIME_WINDOW:
      return (
        <TimeWindowEditor
          type={type}
          params={params}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );
    case ConditionDto.type.DEPENDENT_CHILDREN:
      return (
        <SelectEditor
          type={type}
          params={params}
          options={dependentChildrenOptions}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );

    case ConditionDto.type.EMPLOYED_TIME:
      return (
        <SelectEditor
          type={type}
          params={params}
          options={employedTimeOptions}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );
    case ConditionDto.type.INCOME_TYPE:
      return (
        <MultiselectEditor
          type={type}
          params={params}
          options={incomeTypeOptions}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );

    case ConditionDto.type.MARITAL_STATUS:
      return (
        <MultiselectEditor
          type={type}
          params={params}
          options={maritalStatusOptions}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );

    case ConditionDto.type.APPLICATION_IN_PROGRESS:
    case ConditionDto.type.ESTABLISHED_CASE:
      return (
        <ForTimeEditor
          type={type}
          params={params}
          onlyIntegerValue={true}
          onlyPositiveValue={true}
          onChangeHandler={onChangeHandler}
          validationResult={validationResult}
          setValidationResult={setValidationResult}
          fieldsTouched={fieldsTouched}
          setFieldsTouched={setFieldsTouched}
        />
      );

    default:
      return <></>;
  }
};
