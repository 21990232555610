import * as React from 'react';
import MuiTextField, {
  TextFieldProps as MuiTextFieldProps,
} from '@mui/material/TextField';
import { FieldProps, getIn } from 'formik';
import { InputAdornment, Tooltip } from '@mui/material';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

// This component is copy of https://github.com/stackworx/formik-mui/blob/main/packages/formik-mui/src/TextField.tsx and changed for new design
export interface TextFieldProps
  extends FieldProps,
    Omit<MuiTextFieldProps, 'name' | 'value' | 'error'> {}

type MuiTextFieldPropsExtended = MuiTextFieldProps & {
  fieldError: string;
};

export const fieldToTextField = ({
  disabled,
  field: { ...field },
  form: { isSubmitting, touched, errors },
  ...props
}: TextFieldProps): MuiTextFieldPropsExtended => {
  const fieldError = getIn(errors, field.name);
  const showError = getIn(touched, field.name) && !!fieldError;

  return {
    fieldError,
    error: showError,
    disabled: disabled ?? isSubmitting,
    InputProps: {
      endAdornment: (
        <InputAdornment position="end">
          {showError && <AnnouncementSharpIcon color="error" />}
        </InputAdornment>
      ),
    },
    ...field,
    ...props,
  };
};

export const TextField = ({ children, ...props }: TextFieldProps) => {
  const { fieldError, ...newProps } = fieldToTextField(props);
  return (
    <Tooltip title={newProps.error ? fieldError : newProps.helperText}>
      <MuiTextField {...newProps}>{children}</MuiTextField>
    </Tooltip>
  );
};

TextField.displayName = 'FormikMaterialUITextField';
