import { ICellRendererParams } from '@ag-grid-community/core';
import { Box } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';

export const FromToRenderer = ({
  value,
}: Pick<ICellRendererParams, 'value'>) => {
  const { t } = useTranslation();

  return (
    <Box
      sx={{
        display: 'flex',
        justifyContent: 'flex-start',
        alignItems: 'center',
        gap: '10px',
      }}
    >
      {t(`leadConstraints.fromToEditor.from`)}: <b>{value?.from}</b>
      {t('leadConstraints.fromToEditor.to')}: <b>{value?.to}</b>
    </Box>
  );
};
