export const AG_GRID_LOCALES = {
  // Set Filter
  selectAll: 'grid.setFilter.selectAll',
  selectAllSearchResults: 'grid.setFilter.selectAllSearchResults',
  searchOoo: 'grid.setFilter.searchOoo',
  blanks: 'grid.setFilter.blanks',
  noMatches: 'grid.setFilter.noMatches',

  // Number Filter & Text Filter
  filterOoo: 'grid.ntFilter.filterOoo',
  equals: 'grid.ntFilter.equals',
  notEqual: 'grid.ntFilter.notEqual',
  blank: 'grid.ntFilter.blank',
  notBlank: 'grid.ntFilter.notBlank',
  empty: 'grid.ntFilter.empty',

  // Number Filter
  lessThan: 'grid.numberFilter.lessThan',
  greaterThan: 'grid.numberFilter.greaterThan',
  lessThanOrEqual: 'grid.numberFilter.lessThanOrEqual',
  greaterThanOrEqual: 'grid.numberFilter.greaterThanOrEqual',
  inRange: 'grid.numberFilter.inRange',
  inRangeStart: 'grid.numberFilter.inRangeStart',
  inRangeEnd: 'grid.numberFilter.inRangeEnd',

  // Text Filter
  contains: 'grid.textFilter.contains',
  notContains: 'grid.textFilter.notContains',
  startsWith: 'grid.textFilter.startsWith',
  endsWith: 'grid.textFilter.endsWith',

  // Date Filter
  dateFormatOoo: 'yyyy-mm-dd',

  // Filter Conditions
  andCondition: 'AND',
  orCondition: 'OR',

  // Filter Buttons
  applyFilter: 'grid.filterButtons.applyFilter',
  resetFilter: 'grid.filterButtons.resetFilter',
  clearFilter: 'grid.filterButtons.clearFilter',
  cancelFilter: 'grid.filterButtons.cancelFilter',

  // Filter Titles
  textFilter: 'grid.filterTitles.textFilter',
  numberFilter: 'grid.filterTitles.numberFilter',
  dateFilter: 'grid.filterTitles.dateFilter',
  setFilter: 'grid.filterTitles.setFilter',

  // Group Column Filter
  groupFilterSelect: 'grid.groupFilter.groupFilterSelect',

  // Side Bar
  columns: 'grid.sideBar.columns',
  filters: 'grid.sideBar.filters',

  // Header of the Default Group Column
  group: 'grid.group.group',

  // Other
  loadingOoo: 'grid.other.loadingOoo',
  loadingError: 'grid.other.loadingError',
  noRowsToShow: 'grid.other.noRowsToShow',
  enabled: 'grid.other.enabled',

  thousandSeparator: ',',
  decimalSeparator: '.',
};
