import React, { PropsWithChildren, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Navigate } from 'react-router-dom';
import { LOGIN_ROUTE } from './routes';
import { setPageTitle, setPageSubTitle } from '../store/user/userSlice';
interface IPrivateRouteProps {
  token: string;
  routeName: string;
  route: string;
}
const PrivateRoute = (props: PropsWithChildren<IPrivateRouteProps>) => {
  const { token, routeName, route, children } = props;
  const dispatch = useDispatch();

  useEffect(() => {
    if (token) {
      dispatch(setPageTitle(routeName));
      dispatch(setPageSubTitle(''));
    }
  }, [token, routeName, dispatch]);

  if (!token) {
    return <Navigate to={LOGIN_ROUTE} state={{ backUrl: route }} />;
  }

  return <>{children}</>;
};

export default PrivateRoute;
