import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { AffiliatePartnerDto } from '../../_generatedApi';

export interface AffiliateState {
  affiliates: AffiliatePartnerDto[];
}

const initialState: AffiliateState = {
  affiliates: [],
};

export const affiliateSlice = createSlice({
  name: 'affiliate',
  initialState,
  reducers: {
    setAffiliates: (state, action: PayloadAction<AffiliatePartnerDto[]>) => {
      state.affiliates = action.payload;
    },
    removeAffiliate: (state, action: PayloadAction<string>) => {
      state.affiliates = state.affiliates.filter(
        (affiliate) => affiliate.id !== action.payload,
      );
    },
  },
});

export const { setAffiliates, removeAffiliate } = affiliateSlice.actions;

export default affiliateSlice.reducer;
