import { Box, Chip, Stack } from '@mui/material';
import {
  ConditionGroupDto,
  LeadConstraintDetailDto,
} from '../../_generatedApi';
import React from 'react';
import { useField } from 'formik';

type Relation =
  | LeadConstraintDetailDto.groupRelation
  | ConditionGroupDto.relation;

const getColor = (relation: Relation) =>
  relation === 'AND' ? 'success' : 'warning';

const invertRelation = (relation: Relation) =>
  relation === 'AND'
    ? ConditionGroupDto.relation.OR
    : ConditionGroupDto.relation.AND;

export const GroupRelationWithLine = ({
  relation,
  name,
  showLine = true,
}: {
  relation: Relation;
  name?: string;
  showLine?: boolean;
}): React.JSX.Element => {
  const [, , { setValue }] = useField(name as string);
  return (
    <Stack direction="column" spacing={2} sx={{ position: 'relative' }}>
      <Box textAlign="center">
        <Chip
          sx={{
            width: '60px',
            textOverflow: 'clip',
          }}
          label={relation}
          color={getColor(relation)}
        />
      </Box>
      {showLine && (
        <Box
          sx={{
            borderRightStyle: 'dotted',
            borderRightColor: `${getColor(relation)}.main`,
            borderRightWidth: '1px',

            height: '100%',
            width: '50%',
          }}
        />
      )}
      {name && (
        <Box
          sx={{
            position: 'absolute',
            top: -16,
            left: 0,
            mt: 0,
            '&:hover': {
              '> .otherChip': {
                visibility: 'visible',
              },
            },
          }}
        >
          <Chip
            className={'otherChip'}
            sx={{
              width: '60px',
              textOverflow: 'clip',
              visibility: 'hidden',
              ml: '55px',
            }}
            onClick={() => setValue(invertRelation(relation))}
            label={invertRelation(relation)}
            color={getColor(invertRelation(relation))}
          />
        </Box>
      )}
    </Stack>
  );
};
