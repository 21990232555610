import { isString } from '../../utils/guards';
import { IGridTable } from './types';

export const getParamError = ({
  rowIndex,
  field,
  errors,
}: {
  rowIndex: number;
  field: string;
  errors: IGridTable['errors'];
}) => {
  if (!!errors && !!errors?.[rowIndex]) {
    const error = errors[rowIndex];
    if (isString(error)) {
      return error;
    }
    return error[field];
  }
  return null;
};
