import { Box, Grid, Stack } from '@mui/material';
import { useFormikContext } from 'formik';
import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
  ConditionGroupDto,
  LeadConstraintDetailDto,
} from '../../_generatedApi';
import relation = ConditionGroupDto.relation;
import { getRenderer, renderValue } from './functions';
import { GroupRelationWithLine } from './GroupRelationWithLine';

export interface ILeadConstraintsConditionProps {
  isMain: boolean;
  conditionGroupIndex?: number;
}

export const LeadConstraintsConditionsPlainDisplay: FC<
  ILeadConstraintsConditionProps
> = ({ isMain, conditionGroupIndex }) => {
  const { values } = useFormikContext<LeadConstraintDetailDto>();
  const { t } = useTranslation();

  const getConditionGroup = (): ConditionGroupDto => {
    if (isMain) {
      return (
        values.mainConditionGroup ?? { conditions: [], relation: relation.AND }
      );
    }

    if (conditionGroupIndex === undefined) {
      return { conditions: [], relation: relation.AND };
    }

    return values.conditionGroups[conditionGroupIndex];
  };

  const { conditions, relation: groupRelation } = getConditionGroup();
  return (
    <Stack direction={'row'} spacing={2}>
      {!isMain && <GroupRelationWithLine relation={groupRelation} />}
      <Stack spacing={2} sx={{ pt: 4, width: '100%' }}>
        {conditions.map((row) => {
          const Renderer = getRenderer(row.type, row.conditionValues);
          if (!Renderer) {
            return null;
          }
          return (
            <Grid key={row.type} container>
              <Grid item xs={4}>
                <Box sx={{ p: 0 }}>
                  {t(`leadConstraints.conditionTypes.${row.type}`)}
                </Box>
              </Grid>
              <Grid item xs={8}>
                <Box sx={{ p: 0 }}>
                  {renderValue(row.type, row.conditionValues)}
                </Box>
              </Grid>
            </Grid>
          );
        })}
      </Stack>
    </Stack>
  );
};
