import { Backdrop, CircularProgress } from '@mui/material';
import Typography from '@mui/material/Typography';
import React from 'react';

interface ILoaderProps {
  message: string;
  open: boolean;
}

const Loader = (props: ILoaderProps) => {
  const { open, message } = props;

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={open}
      >
        <CircularProgress color="inherit" />
        <Typography sx={{ marginLeft: 2 }}>{message}</Typography>
      </Backdrop>
    </>
  );
};

export default Loader;
