import {
  FormControl,
  List,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from '@mui/material';
import React, { FC, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';
import { useTranslation } from 'react-i18next';
import { CheckDto } from '../../_generatedApi';
import { StrictModeDroppable } from '../droppable/StrictModeDroppable';
import { LeadConstraintsCheck } from './LeadConstraintsCheck';

export interface ILeadConstraintsCheckColumnProps {
  translationPrefix: string;
  title: string;
  checks: CheckDto.type[];
  setChecks: (checks: CheckDto.type[]) => void;
  dataCy?: string;
}

export const LeadConstraintsCheckColumn: FC<
  ILeadConstraintsCheckColumnProps
> = (props) => {
  const { t } = useTranslation();
  const { translationPrefix, title, checks, setChecks, dataCy } = props;
  const getAvailableChecks = (selectedChecks: CheckDto.type[]) => {
    const allCheckTypes = Object.values(CheckDto.type);
    return allCheckTypes.filter((c) => !selectedChecks.includes(c));
  };

  const [availableChecks, setAvailableChecks] = useState<CheckDto.type[]>(
    getAvailableChecks(checks),
  );

  const handleSelectChange = (params: SelectChangeEvent) => {
    const newChecks = Array.from(checks);
    newChecks.splice(checks.length, 0, params.target.value as CheckDto.type);
    setChecks(newChecks);
    setAvailableChecks(getAvailableChecks(newChecks));
  };

  const onDragEnd = (result: DropResult) => {
    const { destination, source } = result;
    if (!destination) {
      return;
    }

    if (destination.index === source.index) {
      return;
    }

    const draggedCheck = checks[source.index];
    if (!draggedCheck) {
      return;
    }

    const newChecks = Array.from(checks);
    newChecks.splice(source.index, 1);
    newChecks.splice(destination.index, 0, draggedCheck);
    setChecks(newChecks);
  };

  const onCheckDelete = (index: number) => {
    const newChecks = Array.from(checks);
    newChecks.splice(index, 1);
    setChecks(newChecks);
    setAvailableChecks(getAvailableChecks(newChecks));
  };

  const getOrderedChecksList = () => {
    return (
      <StrictModeDroppable droppableId={'checks'}>
        {(provided) => (
          <List
            dense={true}
            ref={provided.innerRef}
            {...provided.droppableProps}
            sx={{ cursor: 'grab' }}
          >
            {checks.map((ch, index) => (
              <LeadConstraintsCheck
                onCheckDelete={onCheckDelete}
                title={ch}
                index={index}
                key={ch}
              />
            ))}
            {provided.placeholder}
          </List>
        )}
      </StrictModeDroppable>
    );
  };

  return (
    <Stack spacing={1} sx={{ width: 1 / 2 }}>
      <Typography>{title}</Typography>
      <FormControl>
        <Select
          value={''}
          label={t(`${translationPrefix}.type`)}
          onChange={handleSelectChange}
          data-cy={dataCy}
        >
          {availableChecks.map((c, idx) => (
            <MenuItem key={idx} value={c} data-cy={`${dataCy}Item`}>
              {c}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <div data-cy={`${dataCy}DragWrapper`}>
        <DragDropContext onDragEnd={onDragEnd}>
          {getOrderedChecksList()}
        </DragDropContext>
      </div>
    </Stack>
  );
};
