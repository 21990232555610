import { Button, Stack } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { getModeColor } from './Modal';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import SaveIcon from '@mui/icons-material/Save';

interface IModalFooterProps {
  onSubmit: (values: unknown) => void;
  customButtons?: JSX.Element;
  onClose: () => void;
  isNew?: boolean;
  disableSubmit?: boolean;
  modalMode?: string;
}

const ModalFooter = ({
  onSubmit,
  onClose,
  customButtons,
  isNew,
  disableSubmit = false,
  modalMode = 'CREATE',
}: IModalFooterProps) => {
  const { t } = useTranslation();

  return (
    <Stack direction={'row'} spacing={2} justifyContent="flex-end">
      {customButtons ? customButtons : undefined}
      <Button
        variant="outlined"
        color="secondary"
        onClick={onClose}
        data-cy="cancelButton"
      >
        {t('modal.cancelModal')}
      </Button>
      <Button
        disabled={disableSubmit}
        variant="contained"
        color={getModeColor(isNew)}
        onClick={onSubmit}
        data-cy="saveButton"
      >
        {isNew ? (
          <>
            <AddCircleIcon sx={{ mr: 1 }} />
            {t('modal.confirmModalNew')}
          </>
        ) : (
          <>
            <SaveIcon sx={{ mr: 1 }} />
            {modalMode !== 'COPY'
              ? t('modal.confirmModal')
              : t('modal.copyModal')}
          </>
        )}
      </Button>
    </Stack>
  );
};

export default ModalFooter;
