/* eslint-disable react/prop-types */
import {
  Box,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  SelectChangeEvent,
  Tooltip,
  Typography,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor } from './types';
import CancelIcon from '@mui/icons-material/Cancel';
import * as Yup from 'yup';
import { validateEditorInput } from './validate-editor-input';
import AnnouncementSharpIcon from '@mui/icons-material/AnnouncementSharp';

type SelectEditorProps = Editor & {
  options: Array<number | string>;
};

export const MultiselectEditor = ({
  options,
  params,
  type,
  onChangeHandler,
  validationResult,
  setValidationResult,
  fieldsTouched,
  setFieldsTouched,
}: SelectEditorProps) => {
  const { t } = useTranslation();
  const [selectedArray, setSelectedArray] = useState<string[]>(
    params?.values ?? [],
  );

  useEffect(() => {
    setFieldsTouched({
      selectedArray: false,
    });
  }, []);

  const validationSchema = Yup.object({
    selectedArray: Yup.array()
      .min(1, t('editors.atleastOneSelection') as string)
      .of(
        Yup.string().oneOf(
          options as string[],
          t('editors.invalidSelection') as string,
        ),
      )
      .required(t('editors.valueRequired') as string),
  });

  useEffect(() => {
    validateInput({
      selectedArray,
    });
    onChangeHandler({ values: selectedArray });
  }, [selectedArray]);

  const validateInput = (validationObject: object): void => {
    void validateEditorInput(validationSchema, validationObject).then(
      ([, errors]) => {
        setValidationResult(errors);
      },
    );
  };

  const handleSelectionChanged = ({
    target: { value },
  }: SelectChangeEvent<string[]>) => {
    const newValue = typeof value === 'string' ? value.split(',') : value;
    setSelectedArray(newValue);
  };

  const handleDelete = (target: string) => {
    const newValue = selectedArray.filter((v) => v !== target);
    setSelectedArray(newValue);
  };

  const getShowError = (fieldName: string): boolean => {
    return !!validationResult[fieldName] && fieldsTouched[fieldName];
  };

  return (
    <Box>
      <Tooltip
        title={
          getShowError('selectedArray') && validationResult['selectedArray']
        }
      >
        <Select
          data-cy="chips"
          sx={{ width: 1 }}
          label={t(`leadConstraints.${type}.label`)}
          multiple
          displayEmpty
          error={getShowError('selectedArray')}
          value={selectedArray}
          onChange={handleSelectionChanged}
          onBlur={() =>
            setFieldsTouched({
              ...fieldsTouched,
              selectedArray: true,
            })
          }
          endAdornment={
            <InputAdornment position="end" sx={{ mr: 2 }}>
              {getShowError('selectedArray') && (
                <AnnouncementSharpIcon color="error" />
              )}
            </InputAdornment>
          }
          renderValue={(selected) =>
            selected.length ? (
              <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                {selected.map((value) => (
                  <Chip
                    data-cy="chip"
                    key={value}
                    label={t(`leadConstraints.${type}.selectOptions.${value}`)}
                    onDelete={() => handleDelete(value)}
                    clickable
                    deleteIcon={
                      <CancelIcon
                        onMouseDown={(event) => event.stopPropagation()}
                        data-cy="chipDeleteCheckbox"
                      />
                    }
                  />
                ))}
              </Box>
            ) : (
              <Typography variant="body1">
                {t(`leadConstraints.${type}.label`)}
              </Typography>
            )
          }
        >
          {options.map((option) => (
            <MenuItem key={option} value={option} data-cy="chipItem">
              {t(`leadConstraints.${type}.selectOptions.${option}`)}
            </MenuItem>
          ))}
        </Select>
      </Tooltip>
    </Box>
  );
};
