import {
  CircularProgress,
  FormControlLabel,
  MenuItem,
  Stack,
} from '@mui/material';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Field, FormikProvider, useFormik } from 'formik';
import { Switch } from 'formik-mui';
import { TFunction } from 'i18next';
import { useDeferredValue, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { array, bool, number, object, string } from 'yup';
import {
  ApiCollectionDataResponseOfBudgetOperationDto,
  ApiError,
  BudgetDetailDto,
  BudgetOperationDto,
  BudgetsService,
  BudgetsValidationsService,
  CommunicationChannelsService,
  CopyBudgetDto,
  CreateBudgetDto,
  KeyValueDto,
  NotificationDto,
  UpdateBudgetDto,
} from '../../_generatedApi';
import { setBudgets } from '../../store/budget/budgetSlice';
import {
  ColumnDefitions,
  GridColumnType,
  GridTable,
  IGridRowAction,
  IGridSort,
} from '../gridTable';
import { FormikMonthPicker } from '../form/FormikMonthPicker';
import dayjs from 'dayjs';
import parseBusinessApiError from '../../utils/parse-business-api-error';
import daysJsToYearMonthString from '../../utils/date';
import CommonModal from '../Modal/Modal';
import ModalFooter from '../Modal/ModalFooter';
import BudgetOperations from './BudgetOperations';
import AlertDialog from '../AlertDialog';
import { TextField } from '../form/FormikTextField';
import { Select } from '../form/FormikSelect';
import { setSnackbarOpen } from '../../store/common/snackbarSlice';
import {
  INPUT_DATE_FORMAT_REGEX,
  MAX_32_BIT_INTEGER_VALUE,
  EMAIL_REGEX,
} from '../../consts';
import {
  NotificationModal,
  NotificationModalModes,
  NotificationsWithId,
} from './NotificationModal';
import { v4 } from 'uuid';

const notificationColumns: ColumnDefitions = {
  percentage: {
    fieldType: GridColumnType.Number,
  },
  users: {
    fieldType: GridColumnType.Array,
  },
};

export enum BudgetModalMode {
  CREATE = 'CREATE',
  UPDATE = 'UPDATE',
  COPY = 'COPY',
}

const getAllowedFieldsByMode = (
  mode: BudgetModalMode,
): Record<string, boolean> => {
  const fields = [
    'displayName',
    'validFrom',
    'validTo',
    'channel',
    'costPerLead',
    'limit',
    'overdraftEnabled',
    'channelId',
  ];

  const editAllowed: Record<string, boolean> = {};
  fields.forEach((field) => {
    editAllowed[field] = false;
  });

  if ([BudgetModalMode.COPY, BudgetModalMode.UPDATE].includes(mode)) {
    editAllowed['channelId'] = true;
  }

  return editAllowed;
};

const getValidationSchemaByMode = (mode: BudgetModalMode, t: TFunction) => {
  return object({
    displayName: string()
      .max(50, t('budgets.nameTooLong') as string)
      .required(t('budgets.nameRequired') as string),
    validFrom: string()
      .matches(
        INPUT_DATE_FORMAT_REGEX,
        t('budgets.invalidDateFormat') as string,
      )
      .required(t('budgets.dateFromRequired') as string),
    costPerLead: number()
      .min(1, t('budgets.onlyPositiveNumber') as string)
      .max(MAX_32_BIT_INTEGER_VALUE, t('budgets.maxNumber') as string)
      .integer(t('budgets.onlyIntegerAllowed') as string)
      .required(t('budgets.costPerLeadRequired') as string),
    limit: number()
      .min(1, t('budgets.onlyPositiveNumber') as string)
      .max(MAX_32_BIT_INTEGER_VALUE, t('budgets.maxNumber') as string)
      .integer(t('budgets.onlyIntegerAllowed') as string)
      .required(t('budgets.limitRequired') as string),
    overdraftEnabled: bool().required(
      t('budgets.overdraftEnabledRequired') as string,
    ),
    notifications: array().of(
      object().shape({
        users: array()
          .min(1, t('notifications.atleastOneEmail') as string)
          .of(
            string().matches(
              EMAIL_REGEX,
              t('notifications.invalidEmailFormat') as string,
            ),
          ),
      }),
    ),
    ...(mode !== BudgetModalMode.UPDATE && {
      channelId: string().required(t('budgets.channelRequired') as string),
    }),
  });
};

type TransformedFormData = {
  channelId?: string;
  validFromDate?: dayjs.Dayjs;
  validToDate?: dayjs.Dayjs;
};

type BudgetFormData = Partial<BudgetDetailDto> & TransformedFormData;

interface IBudgetModal {
  open: boolean;
  budgetId?: string;
  setOpenModal: (open: boolean) => void;
  mode: BudgetModalMode;
}

type NotificationModaData = {
  open: boolean;
  notification?: NotificationsWithId;
  mode: NotificationModalModes;
};

const notificationModalInitValues = {
  open: false,
  rowIndex: undefined,
  notification: undefined,
  mode: NotificationModalModes.CREATE,
};

export type CreateBudgetDtoWithNotificationId = Omit<
  CreateBudgetDto,
  'notifications'
> & { notifications: Array<NotificationsWithId> };

const BudgetModal = ({ open, setOpenModal, budgetId, mode }: IBudgetModal) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const modeTitleMap = {
    [BudgetModalMode.CREATE]: t('budgets.newBudget'),
    [BudgetModalMode.UPDATE]: t('budgets.editBudget'),
    [BudgetModalMode.COPY]: t('budgets.copyBudget'),
  };

  const [budgetDetail, setBudgetDetail] = useState<BudgetFormData>({
    displayName: '',
    limit: 0,
    notifications: [],
    overdraftEnabled: false,
    validFrom: undefined,
    validTo: undefined,
    costPerLead: 0,
    channelId: '',
  });
  const [detailLoaded, setDetailLoaded] = useState(false);
  const [channelLookup, setChannelLookup] = useState<KeyValueDto[]>([]);
  const [allowedFields, setAllowedFields] = useState(
    getAllowedFieldsByMode(BudgetModalMode.CREATE),
  );
  const [budgetOperationDialog, setBudgetOperationDialog] = useState(false);
  const [budgetsOperations, setBudgetsOperations] = useState<
    BudgetOperationDto[]
  >([]);
  const [notificationModal, setNotificationModal] =
    useState<NotificationModaData>(notificationModalInitValues);

  const deferredDetailLoaded = useDeferredValue(detailLoaded);
  const isNewBudget = !budgetId;

  useEffect(() => {
    if (budgetId) {
      void BudgetsService.findOne({ id: budgetId }).then((result) => {
        setBudgetDetail({
          ...result.data,
          notifications: result.data.notifications?.map((notification) => ({
            ...notification,
            id: v4(),
          })) as Array<NotificationsWithId>,
          displayName:
            mode !== BudgetModalMode.COPY
              ? result.data.displayName
              : (t(`modal.copyDisplayName`, {
                  displayName: result.data.displayName,
                }) as string),
          validFromDate: dayjs(result.data.validFrom),
          validToDate: result.data.validTo
            ? dayjs(result.data.validTo)
            : undefined,
          channelId: result.data.channel.id,
        });
        setDetailLoaded(true);
      });
    }

    void CommunicationChannelsService.getLookups({}).then((result) => {
      setChannelLookup(result.data);
    });
    setAllowedFields(getAllowedFieldsByMode(mode));
  }, [budgetId, mode]);

  const onSubmit = async (
    values: CreateBudgetDtoWithNotificationId &
      TransformedFormData & { id?: string },
  ) => {
    const { id: budgetId, channelId, validFromDate, validToDate } = values;

    let response: ApiCollectionDataResponseOfBudgetOperationDto;
    try {
      response = await BudgetsValidationsService.getBudgetOperations({
        requestBody: {
          budgetId: mode !== BudgetModalMode.COPY ? budgetId : undefined,
          channelId,
          validFrom: daysJsToYearMonthString(validFromDate) || '',
          validTo: daysJsToYearMonthString(validToDate),
        },
      });
    } catch (e) {
      dispatch(
        setSnackbarOpen({
          message: `budgets.validations.${parseBusinessApiError(
            e as ApiError,
          )}`,
          severity: 'error',
        }),
      );
      return;
    }

    if (!response || !response.data.length) {
      await saveBudget(values);
    } else {
      setBudgetsOperations(response.data);
      setBudgetOperationDialog(true);
    }
  };

  const mapNotificationsWithoutId = (
    notifications: Array<NotificationsWithId>,
  ): Array<NotificationDto> =>
    notifications.map(({ percentage, users }) => ({
      percentage,
      users,
    }));

  const saveBudget = async (
    values: CreateBudgetDtoWithNotificationId & TransformedFormData,
  ) => {
    if (isNewBudget) {
      const budget: CreateBudgetDto = {
        costPerLead: Number(values.costPerLead),
        validFrom: daysJsToYearMonthString(values.validFromDate) || '',
        validTo: daysJsToYearMonthString(values.validToDate),
        notifications: mapNotificationsWithoutId(values.notifications),
        overdraftEnabled: values.overdraftEnabled,
        limit: Number(values.limit),
        displayName: values.displayName,
        channelId: values.channelId,
      };
      try {
        await BudgetsService.create({
          requestBody: budget,
        });
        const response = await BudgetsService.findAll();
        dispatch(setBudgets(response.data));
        dispatch(
          setSnackbarOpen({
            message: 'budgets.response.create.Ok',
            severity: 'success',
          }),
        );
        void handleClose();
      } catch (e) {
        dispatch(
          setSnackbarOpen({
            message: `budgets.response.create.${(e as ApiError).status}`,
            severity: 'error',
          }),
        );
      }
    } else if (!isNewBudget && mode === BudgetModalMode.UPDATE) {
      const budget: UpdateBudgetDto = {
        costPerLead: Number(values.costPerLead),
        validFrom: daysJsToYearMonthString(values.validFromDate) || '',
        validTo: daysJsToYearMonthString(values.validToDate),
        notifications: mapNotificationsWithoutId(values.notifications),
        overdraftEnabled: values.overdraftEnabled,
        limit: Number(values.limit),
        displayName: values.displayName,
      };
      try {
        await BudgetsService.update({
          id: budgetId as string,
          requestBody: budget,
        });
        const result = await BudgetsService.findAll();
        dispatch(setBudgets(result.data));
        dispatch(
          setSnackbarOpen({
            message: 'budgets.response.update.Ok',
            severity: 'success',
          }),
        );
        void handleClose();
      } catch (e) {
        dispatch(
          setSnackbarOpen({
            message: `budgets.response.update.${(e as ApiError).status}`,
            severity: 'error',
          }),
        );
      }
    } else if (!isNewBudget && mode === BudgetModalMode.COPY) {
      const budget: CopyBudgetDto = {
        costPerLead: Number(values.costPerLead as number),
        validFrom: daysJsToYearMonthString(values.validFromDate) || '',
        validTo: daysJsToYearMonthString(values.validToDate),
        notifications: mapNotificationsWithoutId(values.notifications),
        overdraftEnabled: values.overdraftEnabled,
        limit: Number(values.limit),
        displayName: values.displayName,
      };
      try {
        await BudgetsService.copy({
          id: budgetId as string,
          requestBody: budget,
        });
        const result = await BudgetsService.findAll();
        dispatch(setBudgets(result.data));
        dispatch(
          setSnackbarOpen({
            message: 'budgets.response.copy.Ok',
            severity: 'success',
          }),
        );
        void handleClose();
      } catch (e) {
        dispatch(
          setSnackbarOpen({
            message: `budgets.response.copy.${(e as ApiError).status}`,
            severity: 'error',
          }),
        );
      }
    }
  };

  const validationSchema = getValidationSchemaByMode(mode, t);

  const budgetForm = useFormik({
    initialValues: budgetDetail as CreateBudgetDtoWithNotificationId,
    validationSchema,
    enableReinitialize: true,
    onSubmit,
  });

  const closeNotificationModal = () => {
    setNotificationModal({ ...notificationModal, open: false });
  };
  const openNotificationModal = (id?: string) => {
    const isNewNotification = id === undefined;
    if (isNewNotification) {
      setNotificationModal({ ...notificationModalInitValues, open: true });
    } else {
      const notification = budgetForm.values.notifications.find(
        (notification) => notification.id === id,
      );

      setNotificationModal({
        ...notificationModal,
        open: true,
        mode: NotificationModalModes.UPDATE,
        notification,
      });
    }
  };

  const handleClose = async () => {
    await budgetForm.handleReset({});
    setOpenModal(false);
  };

  const onCreateNotification = () => {
    openNotificationModal();
  };

  const acceptBudgetOperations = () => {
    setBudgetOperationDialog(false);
    void saveBudget(budgetForm.values).then(() => setOpenModal(false));
  };

  const notificationsActions: IGridRowAction = {
    onDelete: (params: unknown) => {
      if (!budgetForm.values.notifications) {
        return;
      }

      const notifications = budgetForm.values.notifications.filter(
        (notification) =>
          notification.id !== (params as NotificationsWithId)?.id,
      );
      void budgetForm.setFieldValue('notifications', notifications);
    },
    onEdit: (params: unknown) => {
      if (!budgetForm.values.notifications) {
        return;
      }

      openNotificationModal((params as NotificationsWithId)?.id);
    },
  };
  const defaultSort: IGridSort = { field: 'percentage', direction: 'asc' };
  if (!deferredDetailLoaded && !isNewBudget) return <CircularProgress />;

  return (
    <>
      <CommonModal
        dataCy={'createEditDialog'}
        open={open}
        onClose={handleClose}
        title={modeTitleMap[mode]}
        isNew={isNewBudget}
        content={
          <>
            <FormikProvider value={budgetForm}>
              <NotificationModal
                open={notificationModal.open}
                notification={notificationModal.notification}
                closeModal={closeNotificationModal}
                mode={notificationModal.mode}
              />
              <AlertDialog
                state={budgetOperationDialog}
                stateCallback={setBudgetOperationDialog}
                buttonAction={acceptBudgetOperations}
                title={t('budgets.operationChanges')}
                message={
                  <BudgetOperations budgetOperations={budgetsOperations} />
                }
                buttonText={t('accept')}
                dataCy="changesDialog"
              />
              <Stack spacing={2}>
                <Field
                  component={TextField}
                  name="displayName"
                  label={t('budgets.displayName')}
                  inputProps={{
                    'data-cy': `budgetDialogName`,
                  }}
                  required={true}
                />

                <Stack spacing={2} direction="row">
                  <Box sx={{ flex: '30%' }}>
                    <FormikMonthPicker
                      label={`${t('budgets.validFrom')} *`}
                      fieldName="validFrom"
                      inputProps={{
                        'data-cy': `budgetValidFrom`,
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: '30%' }}>
                    <FormikMonthPicker
                      label={t('budgets.validTo')}
                      fieldName="validTo"
                      inputProps={{
                        'data-cy': `budgetValidTo`,
                      }}
                    />
                  </Box>
                  <Box
                    sx={{ flex: '40%', '>div': { width: '100%' } }}
                    data-cy="budgetChannel"
                  >
                    <Field
                      component={Select}
                      name="channelId"
                      label={t('budgets.channel.name')}
                      required={true}
                      disabled={allowedFields['channelId']}
                    >
                      {channelLookup.map((channel) => (
                        <MenuItem
                          key={channel.id}
                          id={channel.id}
                          value={channel.id}
                          data-cy="budgetChannelItem"
                        >
                          {channel.name}
                        </MenuItem>
                      ))}
                    </Field>
                  </Box>
                </Stack>
                <Stack spacing={2} direction="row" sx={{ flexGrow: 1 }}>
                  <Box sx={{ flex: '33.33%' }}>
                    <Field
                      component={TextField}
                      type="number"
                      name="costPerLead"
                      label={t('budgets.costPerLead')}
                      required={true}
                      disabled={allowedFields['costPerLead']}
                      inputProps={{
                        'data-cy': `budgetCostPerLead`,
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: '33.33%' }}>
                    <Field
                      component={TextField}
                      type="number"
                      name="limit"
                      label={t('budgets.limit')}
                      required={true}
                      inputProps={{
                        'data-cy': `budgetLimit`,
                      }}
                    />
                  </Box>
                  <Box sx={{ flex: '33.33%' }}>
                    <FormControlLabel
                      control={
                        <Field
                          component={Switch}
                          type="checkbox"
                          name="overdraftEnabled"
                          inputProps={{
                            'data-cy': `budgetOverdraftEnabled`,
                          }}
                        />
                      }
                      label={t('budgets.overdraftEnabled')}
                    />
                  </Box>
                </Stack>
                <Stack spacing={2}>
                  <Typography variant="h6" component="h1">
                    {t('budgets.notifications')}
                  </Typography>

                  <div style={{ minHeight: '260px' }}>
                    <GridTable
                      errors={
                        budgetForm.errors.notifications as unknown as Record<
                          string,
                          string
                        >
                      }
                      defaultSort={defaultSort}
                      colDefs={notificationColumns}
                      data={budgetForm.values.notifications}
                      translationPrefix={'notifications'}
                      actions={notificationsActions}
                      onCreate={onCreateNotification}
                      hideResetFilter={true}
                      enablePagination={false}
                      suppressNoRowsOverlay={true}
                    />
                  </div>
                </Stack>
              </Stack>
            </FormikProvider>
          </>
        }
        footer={
          <ModalFooter
            modalMode={mode as string}
            isNew={isNewBudget}
            onSubmit={budgetForm.submitForm}
            onClose={handleClose}
          />
        }
        modalWidth={'700px'}
      />
    </>
  );
};

export default BudgetModal;
