import { Box, Modal, Stack, Typography } from '@mui/material';

interface IModalProps {
  open: boolean;
  onClose: () => void;
  title: string;
  titleIcon?: JSX.Element;
  content: JSX.Element;
  footer: JSX.Element;
  modalWidth: string;
  dataCy?: string;
  style?: Record<string, unknown>;
  isNew?: boolean;
}

export type ActionColor =
  | 'info'
  | 'inherit'
  | 'secondary'
  | 'primary'
  | 'success'
  | 'error'
  | 'warning'
  | undefined;

export const getModeColor = (isNew?: boolean): ActionColor => {
  if (isNew) {
    return 'success';
  }
  return 'info';
};

const CommonModal = ({
  open,
  onClose,
  title,
  titleIcon,
  content,
  footer,
  modalWidth,
  dataCy,
  style,
  isNew = false,
}: IModalProps) => {
  const boxStyle = {
    position: 'absolute' as const,
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: { xs: '90%', md: modalWidth },
    backgroundColor: 'background.default',
    maxHeight: '90vh',
    display: 'flex',
    flexDirection: 'column',
    ...style,
  };

  return (
    <Modal
      open={open}
      onClose={onClose}
      data-cy={dataCy}
      data-type={isNew ? 'create' : 'edit'}
    >
      <Box sx={boxStyle} id={'modalBoxRoot'}>
        <Box
          sx={{
            backgroundColor: `${getModeColor(isNew)}.main`,
            color: 'white',
            fontWeight: 400,
            fontSize: '1.5rem',
            p: 4,
            pb: 3,
            pt: 3,
          }}
        >
          <Box
            component={'span'}
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'space-between',
            }}
          >
            <Typography variant="h5" component="h2">
              {title}
            </Typography>
            {titleIcon}
          </Box>
        </Box>
        <Stack
          spacing={2}
          sx={{
            p: 4,
            maxHeight: '100vh',
            overflowY: 'auto',
          }}
        >
          {content}
        </Stack>
        <Stack sx={{ p: 4 }} spacing={4}>
          {footer}
        </Stack>
      </Box>
    </Modal>
  );
};

export default CommonModal;
